<template>
    <LoadingBar v-if="bookingDetails.id == null && $apollo.queries.bookingOrderQx.loading"></LoadingBar>
    <div v-else>
        <v-card fill-height flat width="100%" v-if="bookingDetails.id != null" color="background">
            <!-- top bar -->
            <v-toolbar flat color="transparent">
                <v-btn icon @click="$emit('close')">
                    <v-icon>arrow_back_ios_new</v-icon>
                </v-btn>
                <v-btn v-if="shouldShowFullScreenButton" icon @click="changeFullScreen">
                    <v-icon>{{ isFullScreen ? "fullscreen_exit" : "crop_free" }}</v-icon>
                </v-btn>
                <div>
                    <span class="body-2">
                        <v-chip class="ma-2" :color="colorSchema(orderStatus)" label small>
                            <v-icon left :color="colorIcon(orderStatus)" size="8">
                                circle
                            </v-icon>
                            <span class="text-capitalize">
                                {{ orderStatus }}
                            </span>
                        </v-chip>
                    </span>
                </div>
                <v-btn
                    v-if="orderStatus === 'pending' && me.isTenantUser"
                    :disabled="!submitBookingIsValid"
                    rounded
                    class="ml-1"
                    color="primary"
                    @click="updateBookingStatus('confirmed')"
                    :loading="isLoadingBtn"
                >
                    Confirm booking
                </v-btn>
                <v-btn
                    v-if="orderStatus === 'draft'"
                    :disabled="!submitBookingIsValid"
                    rounded
                    color="primary"
                    @click="updateBookingStatus('pending')"
                    :loading="isLoadingBtn"
                >
                    Submit booking
                </v-btn>

                <!-- <div class="ml-3">
                    <div class="font-weight-medium d-flex align-center">
                        <v-icon small left color="primary">circle</v-icon>
                        <v-chip
                            :color="colorStatus(bookingDetails.orderStatus)"
                            class="white--text font-weight-medium ml-2"
                            @click="onBookingStatusChange"
                        >
                            {{ bookingDetails.orderStatus }}
                        </v-chip>
                    </div>
                </div> -->
                <v-spacer></v-spacer>
                <div>
                    <CreateAndEditBooking
                        :editMode="editedMode"
                        :templateMode="false"
                        :bookingOrder.sync="bookingDetails"
                        v-on:update-booking="onBookingUpdated"
                    ></CreateAndEditBooking>
                </div>
                <!-- edit button -->
                <v-menu offset-y transition="slide-x-reverse-transition" width="250px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>more_vert</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-if="orderStatus === 'draft'">
                            <v-btn plain @click="updateBookingStatus('cancelled')">
                                <v-icon left> delete_forever </v-icon>
                                <span>{{ $t("bookings.cancel_booking") }}</span>
                            </v-btn>
                        </v-list-item>
                        <v-list-item v-if="showDeclineBtn">
                            <v-btn plain @click="updateBookingStatus('declined')">
                                <v-icon left> thumb_down_alt </v-icon>
                                <span>{{ $t("bookings.decline_booking") }}</span>
                            </v-btn>
                        </v-list-item>
                        <v-list-item>
                            <v-btn plain @click="shareBooking">
                                <v-icon left> share </v-icon>
                                <span>{{ $t("bookings.share_booking") }}</span>
                            </v-btn>
                        </v-list-item>
                        <v-list-item>
                            <CreateAndEditBooking
                                ref="createTemplate"
                                :mirrorMode="true"
                                :templateMode="true"
                                :bookingOrder.sync="bookingDetails"
                                
                            ></CreateAndEditBooking>
                           <!--  <v-btn plain>
                                <v-icon class="mr-2">description</v-icon>
                                <span>{{ $t("templates.create_new_template") }}</span>
                            </v-btn> -->
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar>
            <v-spacer></v-spacer>
            <!-- trip header -->

            <div v-if="bookingDetails.id != null" class="mb-15">
                <v-card-title class="pt-0 px-5">
                    <!---- GROUP GENERAL INFO DISPLAY (NAME, USERS...) ---->
                    <v-row class="d-flex align-center pt-2 mx-2">
                        <!---- NAME ---->
                        <v-col cols="6" sm="3" class="py-0 px-0 mb-1">
                            <span class="text-caption">Customer</span><br />
                            <span class="font-weight-medium">
                                {{
                                    bookingDetails && bookingDetails.customer
                                        ? bookingDetails.customer
                                        : "-"
                                }}
                            </span>
                        </v-col>
                        <v-col cols="6" sm="3" class="py-0 px-0 mb-1">
                            <span class="text-caption">Receiver</span><br />
                            <span class="font-weight-medium">
                                {{
                                    bookingDetails && bookingDetails.receiver
                                        ? bookingDetails.receiver
                                        : "-"
                                }}
                            </span>
                        </v-col>
                        <v-col cols="6" sm="3" class="py-0 px-0 mb-1">
                            <span class="text-caption">Exporter</span><br />
                            <span class="font-weight-medium">
                                {{
                                    bookingDetails && bookingDetails.exporter
                                        ? bookingDetails.exporter
                                        : "-"
                                }}
                            </span>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                </v-card-title>
                <!-- tab view -->
                <BookingTabView
                    v-if="viewType === 'tab'"
                    :booking="bookingDetails"
                    :selectedFilters="selectedFilters"
                    v-on:onBookingUpdated="onBookingUpdated"
                ></BookingTabView>

                <!-- list view -->
                <BookingListView
                    v-else-if="viewType === 'list'"
                    :booking="bookingDetails"
                    :selectedFilters="selectedFilters"
                ></BookingListView>
            </div>
        </v-card>
        <!-- else show a v-card displaying that didnt find what looking for -->
        <v-card fill-height flat width="100%" v-else color="background">
            <v-toolbar flat color="transparent">
                <v-btn icon @click="$emit('close')">
                    <v-icon>arrow_back_ios_new</v-icon>
                </v-btn>
            </v-toolbar>
            <NotFoundDetailDrawer type="trip" style="padding-top: 100px;"> </NotFoundDetailDrawer>
        </v-card>
    </div>
</template>

<script>
import LoadingBar from "@/components/base/LoadingBar.vue";
import NotFoundDetailDrawer from "../base/NotFoundDetailDrawer.vue";
// import FilterMenu from "@/components/base/FilterMenu.vue";
import BookingTabView from "@/components/bookings/BookingTabView.vue";
import BookingListView from "@/components/bookings/BookingListView.vue";
import helper from "../../utils/helper";
import CreateAndEditBooking from "@/components/bookings/create/CreateAndEditBooking.vue";
import gql from "graphql-tag";

import { TripPermissions } from "@/core/enum";


export default {
    props: {
        booking: {
            type: Object
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        // FilterMenu,
        LoadingBar,
        NotFoundDetailDrawer,
        BookingTabView,
        BookingListView,
        CreateAndEditBooking
    },
    apollo: {
        bookingOrderQx: {
            query: gql`
                query BookingOrderQx($bookingOrderQxId: ID!, $orderBy: String) {
                    bookingOrderQx(id: $bookingOrderQxId) {
                        id
                        contractNumber
                        customerOrderNumber
                        importPermitNumber
                        commodity
                        containerType
                        customer
                        exporter
                        receiver
                        containerInstructions
                        finalDestination
                        freightPayLocation
                        incoTerm
                        logisticContact
                        approxGrossWeight
                        numberOfProbes
                        numberOfShipments
                        orderBy
                        orderDate
                        orderStatus
                        pod
                        pol
                        ppecbTempCode
                        shippedOnBoard
                        shippingLine
                        shippingLineComment
                        shippingLineStatus
                        stackClose
                        stackOpen
                        stackStatus
                        steri
                        steriProtocol
                        transportType
                        vents
                        vesselName
                        voyageNo
                        weighBridge
                        eta
                        etd
                        commodityId
                        containerTypeId
                        customerId
                        customerReceiverId
                        exporterId
                        finalDestinationId
                        podId
                        polId
                        ppecbTempCodeId
                        receiverId
                        shippingLineId
                        steriId
                        steriProtocolId
                        ventsId
                        vesselNameId
                        agentCode
                        agentCodeId
                        logisticContactId
                        weighBridgeId
                        vesselBookingId
                        vesselBookingPolId
                        vesselBookingPodId
                        shippingNo
                        sailingWeek
                        tradeType
                        imo
                        tenant {
                            id
                            name
                        }
                        tenantPartner {
                            id
                            name
                        }
                        bookingTransportOrderQxSet(orderBy: $orderBy) {
                            edges {
                                node {
                                    id
                                    loadPoint1Id
                                    loadPoint1
                                    loadPoint2Id
                                    loadPoint2
                                    loadDate
                                    containerNumber
                                    ctoNumber
                                    sealNumber
                                    trackerNumber
                                    plugInDepot
                                    transportComment
                                    depotDateIn
                                    depotDateOut
                                    transportType
                                    transportInstruction
                                    transportAllocationUser
                                    orderStatus
                                    solasMethodId
                                    solasMethod
                                    tempRecorderTypeId
                                    tempRecorderType
                                    gensetIsRequired
                                    transporterId
                                    transporter
                                    transportStatusId
                                    transportStatus
                                    estimatedGrossWeight
                                    dualLoad
                                    numberOfPalletsPerShipment
                                    bookingReference
                                    emptyLoadPoint
                                    emptyLoadPointId
                                    exporterReferenceNumber
                                }
                            }
                        }
                        bookingOrderChangeRequestQxSet(orderBy: $orderBy) {
                            edges {
                                node {
                                    id
                                    content
                                    reason
                                    status
                                    title
                                    modifiedBy {
                                        id
                                        firstName
                                        lastName
                                    }
                                    modifiedAt
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    bookingOrderQxId: this.booking?.id,
                    orderBy: "-modifiedAt"
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.bookingOrderQx,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip: false
            // result({ data, loading, networkStatus }) {
            //     console.log("result", data, loading, networkStatus);
            // }
        }
    },
    data() {
        return {
            editedMode: true,
            viewType: "tab",
            defaultFilterChoices: [
                //define filter tabs
                {
                    //for filter menu
                    code: "summary",
                    name: "Summary",
                    enabled: true
                }
            ],
            viewTypeChoices: [
                { code: "tab", name: "Tabs" },
                { code: "list", name: "List" }
            ],
            openDialog: false,
            openOderDialog: false,
            orderStatusLocal: "",
            isLoadingBtn: false,
            bookingLocal: null
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
    },
    computed: {
        me() {
            return helper.me();
        },
        routeTab() {
            return this.$route.query.tab;
        },
        selectedFilters() {
            return this.filterChoices.filter(filter => filter?.enabled);
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        canEdit() {
            return this.hasPermission(TripPermissions.EDIT_TRIP);
        },
        bookingDetails() {
            let details = {
                ...this.booking,
                ...this.bookingOrderQx
                // ..._transportDetails
            };
            let _bookingQx = JSON.parse(JSON.stringify(details));
            return _bookingQx || {};
        },
        organisationPermissions() {
            // TODO: add organisation permissions
            return [];
        },
        filterChoices: {
            get() {
                let _filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "bookingDetailFilterChoices");
                return _filterChoices;
            },
            set(value) {
                helper.updateMyPreferences({
                    bookingDetailFilterChoices: value
                });
            }
        },
        submitBookingIsValid() {
            return this.bookingOrderIsValid() && this.transportOrderIsValid();
        },
        showDeclineBtn() {
            let show = true;
            if (!this.me.isTenantUser) return (show = false);
            if (this.me.isTenantUser && this.orderStatusLocal === "cancelled") show = false;
            else if (this.me.isTenantUser && this.orderStatusLocal === "declined") show = false;
            return show;
        },
        showSteriProtocol() {
            return this.bookingOrderQx?.steriId?.includes("steri");
        },
        orderStatus:{
            get(){
                return this.orderStatusLocal;
            },
            set(value){
                this.orderStatusLocal = value;
            }
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.viewType = helper.getViewType("tripDetailViewType");
    },
    mounted() {
        // watch viewType
        this.$watch(
            "viewType",
            function(value) {
                helper.updateMyPreferences({
                    bookingDetailViewType: value
                });
            },
            { deep: true }
        );

        this.orderStatusLocal = this.booking.orderStatus;
    },
    methods: {
        bookingOrderIsValid() {
            let isValid = false;

            if (
                // this.bookingOrderQx?.customerOrderNumber &&
                this.bookingOrderQx?.tenant.id &&
                this.bookingOrderQx?.tenantPartner.id &&
                this.bookingOrderQx?.customerId &&
                this.bookingOrderQx?.receiverId &&
                this.bookingOrderQx?.agentCodeId &&
                this.bookingOrderQx?.contractNumber &&
                this.bookingOrderQx?.shippingLineId &&
                this.bookingOrderQx?.vesselNameId &&
                this.bookingOrderQx?.polId &&
                this.bookingOrderQx?.podId &&
                this.bookingOrderQx?.transportType &&
                this.bookingOrderQx?.ppecbTempCodeId &&
                this.bookingOrderQx?.steriId &&
                //// this.bookingOrderQx?.steriProtocolId > 0 &&
                this.bookingOrderQx?.containerTypeId &&
                this.bookingOrderQx?.commodityId &&
                //this.bookingOrderQx?.importPermitNumber &&
                this.bookingOrderQx?.numberOfShipments > 0 &&
                // this.bookingOrderQx?.numberOfProbes > 0 &&
                this.bookingOrderQx?.weighBridgeId &&
                this.bookingOrderQx?.ventsId
            ) {
                isValid = true;
            }
            if (
                this.showSteriProtocol &&
                this.bookingOrderQx?.steriProtocolId > 0 &&
                this.bookingOrderQx?.numberOfProbes > 0
            ) {
                isValid = true;
            }

            // if (
            //     this.bookingOrderQx?.orderStatus === "pending" &&
            //     (!this.bookingOrderQx?.bookingReference ||
            //         /*!this.bookingOrderQx?.shippedOnBoard ||*/
            //         !this.bookingOrderQx?.logisticContactId
            //     )
            // ) {
            //     isValid = false;
            // }
            // //check the number of shipments are correct
            // if(this.bookingOrderQx?.numberOfShipments !== this.bookingOrderQx?.bookingTransportOrderQxSet?.edges.length){
            //     isValid = false;
            // }

            return isValid;
        },
        transportOrderIsValid() {
            let isValid = [];
            let checkNumberOfShipments = false;

            this.bookingOrderQx?.bookingTransportOrderQxSet?.edges.forEach(item => {
                let check = false;
                if (
                    item?.node?.loadDate &&
                    item?.node?.transportType &&
                    item?.node?.loadPoint1Id &&
                    item?.node?.solasMethodId &&
                    item?.node?.tempRecorderTypeId &&
                    item?.node?.numberOfPalletsPerShipment > 0
                ) {
                    check = true;
                    if (item?.node?.dualLoad && !item?.node?.loadPoint2Id) {
                        check = false;
                    }
                }

                // if(this.bookingOrderQx?.orderStatus === 'pending'){
                //     if(!item?.node?.ctoNumber || !item?.node?.containerNumber || !item?.node?.sealNumber
                //     && !item?.node?.trackerNumber || !item?.node?.transportAllocationUser || !item?.node?.transportStatus){
                //         check = false;
                //     }
                // }

                /*enable this on phase 2*/
                // if(this.bookingOrderQx?.orderStatus === 'pending'){
                //     if(!item?.node?.transporterId || !item?.node?.transportStatus){
                //         check = false;
                //     }
                // }
                

                isValid.push(check);
            });

            if (
                this.bookingOrderQx?.numberOfShipments === this.bookingOrderQx?.bookingTransportOrderQxSet?.edges.length
            ) {
                checkNumberOfShipments = true;
            }
            isValid.push(checkNumberOfShipments);

            return isValid.length > 0 && isValid.every(v => v === true);
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        },
        onBookingStatusChange() {
            // TODO add booking status
        },
        colorSchema(status) {
            switch (status) {
                case "pending":
                    return "#BBDEFB";
                case "approved":
                    return "#C8E6C9";
                case "denied":
                    return "#FFCDD2";
                default:
                    return "#FFECB3";
            }
        },
        colorIcon(status) {
            switch (status) {
                case "pending":
                    return "#1E88E5";
                case "approved":
                    return "#43A047";
                case "denied":
                    return "#E53935";
                default:
                    return "#FFB300";
            }
        },
        onBookingUpdated() {
            this.$apollo.queries.bookingOrderQx.refresh();
        },
        getTime(date) {
            return this.formatDateTime(date)?.slice(11, 16);
        },
        getDate(date) {
            return date?.slice(0, 10);
        },
        updateBookingStatus(status) {
            this.isLoadingBtn = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateBookingOrderStatusQx($input: UpdateBookingOrderStatusQXInput!) {
                            updateBookingOrderStatusQx(input: $input) {
                                bookingOrderQx {
                                    id
                                    orderStatus
                                }
                                success
                                message
                            }
                        }
                    `,
                    variables: {
                        input: {
                            bookingOrderQxId: this.booking?.id,
                            orderStatus: status
                        }
                    }
                })
                .then(response => {
                    // this.orderStatusLocal = response.data.updateBookingOrderStatusQx.bookingOrderQx.orderStatus;
                    this.orderStatus = response.data.updateBookingOrderStatusQx.bookingOrderQx.orderStatus;
                    let success = response.data.updateBookingOrderStatusQx.success,
                        message = response.data.updateBookingOrderStatusQx.message;
                    if(success){
                        this.onBookingUpdated();
                        const payload = {
                            color: "primary",
                            message: "Order confirmed."
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    }else{
                        const payload = {
                            color: "red",
                            message: message
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    }
                })
                .catch(error => {
                    console.log("error", error);
                    if (error.message.includes("Unable to post data to QX API. Please contact support")) {
                        const payload = {
                            color: "red",
                            message: "Unable to post data to QX API. Please contact support."
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    }
                })
                .finally(() => {
                    this.isLoadingBtn = false;
                });
        },
        saveAsTemplate() {
            this.$emit("saveAsTemplate", this.bookingDetails);
        },
        shareBooking() {
            let url = window.location.href;
            let dummy = document.createElement("input"),
                text = url;
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            const payload = {
                color: "blue",
                message: this.$t("general.link")
            };
            this.$store.dispatch("snackbar/showMessage", payload);
        }
    }
};
</script>
