<template>
    <v-container fluid>
        <v-btn
            v-scroll="onScroll"
            v-show="goTopShow"
            fab
            dark
            fixed
            bottom
            right
            small
            style="z-index: 1"
            color="primary"
            @click="toTop"
        >
            <v-icon>keyboard_arrow_up</v-icon>
        </v-btn> 


        <!-- title  -->
        <div>
            <v-row class="ma-0 px-3 d-flex align-center">
                <v-col>
                    <h2
                        class="
                            ml-3
                            display-3
                            font-weight-medium
                            d-flex
                            align-top
                        "
                    >
                        QX Bookings
                    </h2>
                </v-col>
                <v-col cols="auto" class="px-0"> </v-col>
            </v-row>
        </div>

        <!-- filter -->
        <BookingFilter 
            class="mx-3"
            :filterCode="filterCode"
            v-on:filter-changed="onFilterChanged"
            v-on:booking-created="onBookingCreated"
        />

        <!-- table -->
        <BookingTable 
            ref="bookingTable"
            class="mx-3 mt-3 mb-3"
            :bookingFilter="bookingFilter"
            v-on:click:row="onBookingSelected"
        />


        <!-- booking detail -->
        <v-navigation-drawer
            v-model="bookingDetailDrawer"
            :width="bookingDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            disable-route-watcher
            temporary
            
        >
            <div>
                <BookingDetailDrawer
                    v-if="selectedBooking"
                    :key="selectedBooking ? selectedBooking.id : idFromRouter"
                    :booking.sync="selectedBooking"
                    :bookingId="idFromRouter"
                    :isFullScreen="isBookingDetailDrawerFullScreen"
                    v-on:changeFullScreen="onTripDetailDrawerFullScreenChange"
                    v-on:close="bookingDetailDrawer = false"
                    :openOder.sync="openOrder"
                ></BookingDetailDrawer>
            </div>
        </v-navigation-drawer>

        
    </v-container>
</template>
<script>
import BookingTable from "@/components/bookings/BookingTable.vue";
import BookingFilter from "@/components/bookings/BookingFilter.vue";
import BookingDetailDrawer from "@/components/bookings/BookingDetailDrawer.vue";
import { eventBus } from "@/main.js";

export default {
    components: {
        BookingTable,
        BookingFilter,
        BookingDetailDrawer
    },
    data() {
        return {
            filterCode: "",
            bookingDetailDrawer: false,
            goTopShow: false,
            isLoading: false,
            menu: false,
            idFromRouter: null,
            isRouting: false,
            bookingFilter: {
                first: 10
            },
            selectedBooking: null,
            bookingDetailFullScreen: false,
            openOrder:false
        }
    },
    computed: {
        bookingDetailDrawerWidth() {
            if (this.bookingDetailFullScreen) {
                return "100%";
            }
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return "100%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isBookingDetailDrawerFullScreen() {
            return this.bookingDetailDrawerWidth === "100%";
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        }
        
    },
    watch: {
        bookingDetailDrawer(val) {
            if (!val) {
                this.resetRoute();
            }
        }
    },
    methods: {
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        onBookingCreated(booking) {
            this.selectedBooking = {...booking};
            this.$router.push(
                { 
                    query: { 
                        id: booking.id,
                        code: true
                    }
                }
            ).catch(() => {});
            this.bookingDetailDrawer = true;
            this.$refs.bookingTable.$apollo.queries.bookingOrderQxs.refetch();
        },
        onFilterChanged(filter) {
            this.bookingFilter = filter;
        },
        onBookingSelected(booking) {
            this.selectedBooking = {...booking.node};
            this.$router.push(
                { query: { 
                    id: booking.node.id
                }}
            ).catch(() => {});
            this.bookingDetailDrawer = true;
        },
        resetRoute() {
            this.selectedBooking = null;
            this.bookingDetailFullScreen = false;
            this.idFromRouter = null;
            this.$router.push({ query: null });
        },
        onTripDetailDrawerFullScreenChange(val) {
            this.bookingDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        }
    }
}
</script>
<style scoped>
    ::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
</style>