import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('h4',{staticClass:"mb-2"},[_c('span',[_vm._v(_vm._s(_vm.$t("alert_rules.where")))])]),_c(VExpandTransition,{staticClass:"py-0"},[(!_vm.hideAdvancedInfo)?_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c(VCard,{staticClass:"my-2 py-5 d-flex align-center justify-center",attrs:{"color":_vm.location.Departure ? 'primary' : 'white'},on:{"click":function($event){return _vm.updateSelectedLocation(
                            _vm.location.Departure,
                            'departure'
                        )}}},[_c('span',{class:_vm.location.Departure
                                ? 'white--text'
                                : 'black-text'},[_vm._v(_vm._s(_vm.$t("alert_rules.departure")))])])],1),(_vm.isSensorTrigger)?_c(VCol,{attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c(VCard,{staticClass:"my-2 py-5 d-flex align-center justify-center",attrs:{"color":_vm.location.InTransit ? 'primary' : 'white'},on:{"click":function($event){return _vm.updateSelectedLocation(
                            _vm.location.InTransit,
                            'inTransit'
                        )}}},[_c('span',{class:_vm.location.InTransit
                                ? 'white--text'
                                : 'black-text'},[_vm._v(" "+_vm._s(_vm.$t("alert_rules.transit"))+" ")])])],1):_vm._e(),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c(VCard,{staticClass:"my-2 py-5 d-flex align-center justify-center",attrs:{"color":_vm.location.Arrival ? 'primary' : 'white'},on:{"click":function($event){return _vm.updateSelectedLocation(_vm.location.Arrival, 'arrival')}}},[_c('span',{class:_vm.location.Arrival ? 'white--text' : 'black-text'},[_vm._v(" "+_vm._s(_vm.$t("alert_rules.arrival"))+" ")])])],1)],1):_vm._e()],1),_c(VRow,{staticClass:"py-0 my-0"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VSwitch,{staticClass:"text-no-wrap",attrs:{"label":_vm.$t('general.advanced')},model:{value:(_vm.hideAdvancedInfo),callback:function ($$v) {_vm.hideAdvancedInfo=$$v},expression:"hideAdvancedInfo"}})],1)],1),(_vm.hideAdvancedInfo)?_c(VExpandTransition,[_c(VRow,{staticClass:"mb-3 mt-1",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("alert_rules.alert_when")))])]),_vm._l((_vm.isSensorTrigger
                    ? _vm.locationSensorConditionChoices
                    : _vm.locationConditionChoices),function(item,i){return _c(VCol,{key:i,staticClass:"mt-1",attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c(VCard,{staticClass:"my-2 py-5 d-flex align-center justify-center",attrs:{"color":item.value === _vm.location.Condition ? 'primary' : ''},on:{"click":function($event){return _vm.updateCondition(item.value)}}},[_c('span',{class:item.value === _vm.location.Condition
                                ? 'white--text'
                                : 'black-text'},[_vm._v(" "+_vm._s(item.text)+" ")])])],1)}),_c(VCol,{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("home.locations")))])]),_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"mt-1",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c(VCard,{staticClass:"my-2 py-5 d-flex align-center justify-center",attrs:{"color":_vm.location.Origin ? 'primary' : 'white'},on:{"click":function($event){return _vm.updateSelectedLocation(
                                    _vm.location.Origin,
                                    'origin'
                                )}}},[_c('span',{class:_vm.location.Origin
                                        ? 'white--text'
                                        : 'black-text'},[_vm._v(_vm._s(_vm.$t("trips.origin")))])])],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c(VCard,{staticClass:"my-2 py-5 d-flex align-center justify-center",attrs:{"color":_vm.location.Destination ? 'primary' : 'white'},on:{"click":function($event){return _vm.updateSelectedLocation(
                                    _vm.location.Destination,
                                    'destination'
                                )}}},[_c('span',{class:_vm.location.Destination
                                        ? 'white--text'
                                        : 'black-text'},[_vm._v(" "+_vm._s(_vm.$t("trips.destination"))+" ")])])],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c(VCard,{staticClass:"my-2 py-5 d-flex align-center justify-center",attrs:{"color":_vm.location.Public ? 'primary' : 'white'},on:{"click":function($event){return _vm.updateSelectedLocation(
                                    _vm.location.Public,
                                    'public'
                                )}}},[_c('span',{class:_vm.location.Public
                                        ? 'white--text'
                                        : 'black-text'},[_vm._v(" "+_vm._s(_vm.$t("alert_rules.public"))+" ")])])],1)],1)],1),_c(VCol,{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("alert_rules.partners_locations")))])]),_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"mt-1",attrs:{"dense":""}},_vm._l((_vm.partnerTypeChoices),function(partnerTypeChoice){return _c(VCol,{key:partnerTypeChoice.value,attrs:{"cols":"2","sm":"2","md":"2","lg":"2"}},[_c(VCard,{staticClass:"py-5 d-flex align-center justify-center",attrs:{"color":_vm.findPartner(partnerTypeChoice.value)
                                    ? 'primary'
                                    : ''},on:{"click":function($event){return _vm.updateSelectedPartner(
                                    partnerTypeChoice.value
                                )}}},[_c('span',{class:_vm.findPartner(partnerTypeChoice.value)
                                        ? 'white--text'
                                        : 'black-text'},[_vm._v(" "+_vm._s(partnerTypeChoice.text)+" ")])])],1)}),1)],1),_c(VCol,{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("alert_rules.custom_locations")))])]),(_vm.hideAdvancedInfo)?_c(VCol,{attrs:{"cols":"9"}},[_c(VRow,{staticClass:"mt-1",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"loading":_vm.$apollo.queries.tenantLocations.loading,"items":_vm.tenantLocations ? _vm.tenantLocations.edges : [],"no-data-text":_vm.$t('loading.no_available'),"item-text":"node.name","item-value":"node.rawId","outlined":"","deletable-chips":"","multiple":"","chips":"","height":"30","hide-details":"","hide-selected":""},on:{"change":_vm.updateLocation},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VRow,{staticClass:"ma-0 body-2",attrs:{"dense":""}},[_c(VCol,{staticClass:"text-truncate pl-0 ml-0",attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(data.item.node.name)+" ")]),_c(VCol,{attrs:{"cols":"2"}},[_c('span',{staticClass:"grey--text mr-1"},[_c('i',[_vm._v(_vm._s(_vm.selectLabelByType( data.item.node.locationType.toLowerCase() )))])])])],1)]}},{key:"append-outer",fn:function(){return [_c(VBtn,{staticClass:"mt-0",attrs:{"icon":"","small":"","rounded":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.openAddLocation = true}}},[_c(VIcon,[_vm._v("add")])],1)]},proxy:true}],null,false,2674130569),model:{value:(_vm.location.Custom),callback:function ($$v) {_vm.$set(_vm.location, "Custom", $$v)},expression:"location.Custom"}})],1)],1)],1):_vm._e(),_c('AddLocation',{attrs:{"openAddLocationDialog":_vm.openAddLocation},on:{"closeMap":function($event){_vm.openAddLocation = false},"updateLocationsTable":function($event){return _vm.$apollo.queries.tenantLocations.refetch()}}})],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }