<template>
    <v-app id="app">
        <!-- Notification -->
        <Snackbar></Snackbar>
        <div v-if="showPreview">
            <v-navigation-drawer
                ref="previewDrawerMenu"
                v-if="isLoggedIn && me"
                v-model="drawer"
                :mini-variant.sync="mini"
                :temporary="isSmallScreen && !mini"
                :permanent="isSmallScreen"
                app
                :class="{ 'layout-vertical-nav': !isSmallScreen }"
                theme="light"
            >
                <v-list>
                    <v-list-item class="px-2">
                        <div v-if="!mini">
                            <v-img
                                v-if="validTenant && logo.type === 'image'"
                                :src="logo.url"
                                max-height="40px"
                                max-width="130px"
                                width="130px"
                                height="40px"
                                :contain="screenSize === 'lg' || screenSize === 'xl' ? false : true"
                            ></v-img>

                            <span
                                link
                                href="/#/"
                                v-else-if="logo.type === 'text' && validTenant"
                                class="pl-4 display-5 font-weight-bold"
                            >
                                {{ logo.text }}
                            </span>

                            <v-img
                                v-else
                                :src="require('./assets/new_coldcha_logo.png')"
                                max-height="50px"
                                max-width="150px"
                            ></v-img>
                        </div>
                        <div v-else>
                            <v-btn small outlined color="primary" icon @click.stop="mini = !mini">
                                <v-icon>chevron_right</v-icon>
                            </v-btn>
                        </div>

                        <!-- <v-list-item-title>John Leider</v-list-item-title> -->
                        <v-spacer />
                        <v-btn small outlined color="primary" icon @click.stop="mini = !mini">
                            <v-icon>chevron_left</v-icon>
                        </v-btn>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>
                <v-spacer></v-spacer>
                <v-list dense>
                    <div v-for="(item, i) in previewComputedMenuItem" :key="i">
                        <v-list-item
                            v-if="item.title !== $t('home.profile') && !item.children"
                            :key="item.title"
                            :to="item.to"
                            link
                            v-ripple="{ center: true }"
                        >
                            <v-list-item-action>
                                <v-icon v-if="item.icon != 'freights'">{{ item.icon }}</v-icon>
                                <v-img v-else :src="require('@/assets/container.png')" width="20"></v-img>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <!-- if new property tag show is new -->
                                    <v-badge tile color="white" v-if="item.tag" offset-x="0" offset-y="-6" dense>
                                        <template v-slot:badge>
                                            <span class="caption primary--text">
                                                {{ $t(`home.${item.tag}`) }}
                                            </span>
                                        </template>
                                        <span class="d-flex justify-center text-align-center mt-n4 ">
                                            {{ item.title }}
                                        </span>
                                    </v-badge>
                                    <span v-else>
                                        {{ item.title }}
                                    </span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-group
                            v-else-if="item.title !== 'Profile' && item.children"
                            :key="item.title"
                            :prepend-icon="item.icon"
                            no-action
                        >
                            <template v-slot:activator>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </template>

                            <v-list-item
                                v-for="child in item.children"
                                :key="child.title"
                                :to="child.to"
                                link
                                v-ripple="{ center: true }"
                            >
                                <v-list-item-action>
                                    <v-icon>{{ child.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{ child.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </div>
                    <v-list-item v-if="me.isTenantUser && canCreate" class="pt-4">
                        <div v-if="mini">
                            <v-list-item-action>
                                <v-btn fab x-small color="primary" @click="createTrip()">
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </v-list-item-action>
                            <v-list-item-content> </v-list-item-content>
                        </div>
                        <v-list-item-content v-else>
                            <v-btn color="primary" @click="createTrip()">
                                {{ $t("trips.create_trip_2") }}
                            </v-btn>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>

            <v-app-bar v-if="isLoggedIn && me" app color="background" dense scroll-threshold elevation="0">
                <!-- <v-app-bar-nav-icon v-if="isSmallScreen" @click.stop="mini = !mini">
                    <template>
                        <v-icon>menu</v-icon>
                    </template>
                </v-app-bar-nav-icon> -->
                <v-spacer></v-spacer>
                <div v-if="isLoggedInAsOtherUser || isLoggingOutAsOtherUser" class="d-flex justify-center align-center">
                    <span v-if="screenSize === 'lg' || screenSize === 'xl'" class="mr-1">
                        {{ $t("general.logged_in") }}
                    </span>
                    <span v-else-if="screenSize === 'md'" class="mr-1">
                        {{ $t("general.logged_in2") + " " }}
                    </span>
                    <v-chip
                        outlined
                        :style="screenSize === 'xs' || screenSize === 'sm' ? 'width: 100px' : ''"
                        v-if="screenSize === 'lg' || screenSize === 'xl' || screenSize === 'md' || screenSize === 'sm'"
                    >
                        <strong class="text-truncate" max-width="50"> {{ me.firstName }} {{ me.lastName }}</strong>
                        <span class="ml-2 d-none d-md-block" v-if="screenSize === 'lg' || screenSize === 'xl'">
                            ({{ me.email }})</span
                        >
                    </v-chip>
                    <v-btn dark small depressed rounded color="error" @click="logoutAsOtherUser" class="pa-1" v-else>
                        <span class="caption">
                            {{ me.firstName ? me.firstName.slice(0, 1) : "" }}.
                            {{ me.lastName ? me.lastName.slice(0, 1) : "" }}.
                        </span>
                        <v-icon small>exit_to_app</v-icon>
                    </v-btn>
                    <v-btn
                        dark
                        small
                        rounded
                        color="error"
                        class="ml-5"
                        @click="logoutAsOtherUser"
                        v-if="screenSize === 'lg' || screenSize === 'xl' || screenSize === 'md'"
                        >{{ $t("general.exit") }}</v-btn
                    >
                </div>

                <v-spacer></v-spacer>
                <!--switch to show preview-->
                <v-switch v-model="showPreview" insert label="Preview" class="pt-6 subtitle-2"></v-switch>

                <!--Release Notes -->
                <v-toolbar-title class="subtitle-2">
                    <ReleaseNotes format="icon" />
                </v-toolbar-title>

                <v-toolbar-title class="subtitle-2 ma-1">
                    <!--Timezone and Units Dialog -->
                    <TemperatureUnitsDialog :openTemperatureUnitsDialog="openTemperatureUnitsDialog" format="icon">
                    </TemperatureUnitsDialog>
                </v-toolbar-title>

                <v-toolbar-title class="subtitle-2 ma-1">
                    <!--User Inbox Notifications -->
                    <LangDropDown />
                </v-toolbar-title>

                <v-toolbar-title class="subtitle-2 ma-1">
                    <!--User Inbox Notifications -->
                    <UserInbox />
                </v-toolbar-title>

                <!--Profile avatar-->
                <v-toolbar-title class="subtitle-2 ma-1">
                    <v-menu rounded offset-y>
                        <template v-slot:activator="{ attrs, on }">
                            <!-- <v-btn
                                class="white--text ma-5"
                                v-bind="attrs"
                                v-on="on"
                                >
                                {{ text }} Radius
                            </v-btn> -->
                            <v-badge
                                bordered
                                bottom
                                :color="isLoggedInAsOtherUser ? 'red' : 'green'"
                                dot
                                offset-x="10"
                                offset-y="10"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-avatar v-if="me.profilePhoto" size="25" v-bind="attrs" v-on="on">
                                    <v-img :src="me.profilePhoto"></v-img>
                                </v-avatar>
                                <v-avatar v-else size="25" v-bind="attrs" v-on="on">
                                    <v-img :src="require('@/assets/profile_picture.png')"></v-img>
                                </v-avatar>
                            </v-badge>
                        </template>

                        <v-list dense>
                            <v-list-item-group>
                                <v-list-item
                                    v-for="item in profileMenuItem"
                                    :key="item.title"
                                    @click="handleItemClick(item.to)"
                                    v-ripple="{ center: true }"
                                >
                                    <v-list-item-content v-if="item.title === $t('home.profile')">
                                        <v-list-item-title>{{ me.firstName }} {{ me.lastName }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ me.email }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon v-if="item.title !== $t('home.profile')">
                                        <v-icon v-text="item.icon"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content v-if="item.title !== $t('home.profile')">
                                        <v-list-item-title v-text="item.title"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>

                            <!-- <v-list-item
                                v-for="item in profileMenuItem"
                                :key="item"
                                link
                            >
                            <v-list-item-title v-text="item"></v-list-item-title>
                            </v-list-item> -->
                        </v-list>
                    </v-menu>
                    <!-- <v-badge
                        bordered
                        bottom
                        :color="isLoggedInAsOtherUser ? 'red' : 'green'"
                        dot
                        offset-x="10"
                        offset-y="10"
                    >
                        <v-avatar size="25" v-if="me.profilePhoto">
                            <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>
                        </v-avatar>
                        <v-avatar size="25" v-else>
                            <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>
                        </v-avatar>
                    </v-badge> -->
                </v-toolbar-title>
            </v-app-bar>
        </div>
        <div v-else>
            <v-app-bar
                v-if="isLoggedIn && me"
                :clipped-left="$vuetify.breakpoint.lgAndUp"
                app
                color="primary"
                dark
                dense
                scroll-threshold
            >
                <v-app-bar-nav-icon @click.stop="drawer = !drawer">
                    <template>
                        <v-icon>menu</v-icon>
                    </template>
                </v-app-bar-nav-icon>
                <v-toolbar-title class="ml-0 pl-4 mr-6" v-if="isLoggedIn">
                    <span link href="/#/" v-if="logo.type === 'text' && validTenant">
                        {{ logo.text }}
                    </span>
                    <v-img
                        v-else-if="validTenant && logo.type === 'image'"
                        :src="logo.url"
                        max-height="40px"
                        max-width="130px"
                        width="130px"
                        height="40px"
                        :contain="screenSize === 'lg' || screenSize === 'xl' ? false : true"
                    ></v-img>
                    <v-img
                        v-else
                        class="mt-3"
                        :src="require('@/assets/coldcha_logo_white.png')"
                        width="120px"
                        :contain="screenSize === 'lg' || screenSize === 'xl' ? false : true"
                        height="25px"
                    ></v-img>
                </v-toolbar-title>
                <v-row
                    v-if="isLoggedInAsOtherUser || isLoggingOutAsOtherUser"
                    class="d-flex justify-center align-center"
                >
                    <span v-if="screenSize === 'lg' || screenSize === 'xl'" class="mr-1">
                        {{ $t("general.logged_in") }}
                    </span>
                    <span v-else-if="screenSize === 'md'" class="mr-1">
                        {{ $t("general.logged_in2") + " " }}
                    </span>
                    <v-chip
                        color="white"
                        outlined
                        :style="screenSize === 'xs' || screenSize === 'sm' ? 'width: 100px' : ''"
                        v-if="screenSize === 'lg' || screenSize === 'xl' || screenSize === 'md' || screenSize === 'sm'"
                    >
                        <strong class="text-truncate" max-width="50"> {{ me.firstName }} {{ me.lastName }}</strong>
                        <span class="ml-2 d-none d-md-block" v-if="screenSize === 'lg' || screenSize === 'xl'">
                            ({{ me.email }})</span
                        >
                    </v-chip>
                    <v-btn dark small depressed rounded color="error" @click="logoutAsOtherUser" class="pa-1" v-else>
                        <span class="caption">
                            {{ me.firstName ? me.firstName.slice(0, 1) : "" }}.
                            {{ me.lastName ? me.lastName.slice(0, 1) : "" }}.
                        </span>
                        <v-icon small>exit_to_app</v-icon>
                    </v-btn>
                    <v-btn
                        dark
                        small
                        rounded
                        color="error"
                        class="ml-5"
                        @click="logoutAsOtherUser"
                        v-if="screenSize === 'lg' || screenSize === 'xl' || screenSize === 'md'"
                        >{{ $t("general.exit") }}</v-btn
                    >
                </v-row>

                <v-spacer></v-spacer>

                <v-switch v-model="showPreview" insert label="Preview" color="white" class="pt-6 subtitle-2"></v-switch>

                <v-toolbar-title class="subtitle-2">
                    <ReleaseNotes color="white" />
                </v-toolbar-title>

                <v-toolbar-title class="subtitle-2">
                    <TemperatureUnitsDialog :openTemperatureUnitsDialog="openTemperatureUnitsDialog">
                    </TemperatureUnitsDialog>
                </v-toolbar-title>

                <v-toolbar-title class="subtitle-2">
                    <LangDropDown />
                </v-toolbar-title>

                <v-toolbar-title class="subtitle-2">
                    <UserInbox />
                </v-toolbar-title>
            </v-app-bar>

            <v-navigation-drawer
                v-if="isLoggedIn && me"
                v-model="drawer"
                :clipped="$vuetify.breakpoint.lgAndUp"
                app
                temporary
            >
                <v-list class="my-3" v-if="me">
                    <div v-for="(item, i) in computedMenuItem" :key="i">
                        <v-list-item
                            v-if="item.title === $t('home.profile')"
                            :key="item.title"
                            :to="item.to"
                            link
                            v-ripple="{ center: true }"
                        >
                            <v-list-item-avatar color="primary">
                                <v-icon color="white">person</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ me.firstName }}
                                    {{ me.lastName }}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ me.email }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </v-list>
                <v-spacer></v-spacer>
                <v-list dense>
                    <div v-for="(item, i) in computedMenuItem" :key="i">
                        <v-list-item
                            v-if="item.title !== $t('home.profile') && !item.children"
                            :key="item.title"
                            :to="item.to"
                            link
                            v-ripple="{ center: true }"
                        >
                            <v-list-item-action>
                                <v-icon></v-icon>
                                <v-icon v-if="item.icon != 'freights'">{{ item.icon }}</v-icon>
                                <v-img v-else :src="require('@/assets/container.png')" width="20"></v-img>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-badge tile color="white" v-if="item.tag" offset-x="0" offset-y="-6" dense>
                                        <template v-slot:badge>
                                            <span class="caption primary--text">
                                                {{ $t(`home.${item.tag}`) }}
                                            </span>
                                        </template>
                                        <span class="d-flex justify-center text-align-center mt-n4 ">
                                            {{ item.title }}
                                        </span>
                                    </v-badge>
                                    <span v-else>
                                        {{ item.title }}
                                    </span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-group
                            v-else-if="item.title !== 'Profile' && item.children"
                            :key="item.title"
                            :prepend-icon="item.icon"
                            no-action
                        >
                            <template v-slot:activator>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </template>

                            <v-list-item
                                v-for="child in item.children"
                                :key="child.title"
                                :to="child.to"
                                link
                                v-ripple="{ center: true }"
                            >
                                <v-list-item-action>
                                    <v-icon>{{ child.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{ child.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </div>
                </v-list>
                <template v-slot:append>
                    <div class="pa-2">
                        <v-btn block text outlined rounded color="error" @click="logout">{{
                            $t("general.logout")
                        }}</v-btn>
                    </div>
                    <center class="grey--text subtitle-2 mb-2">{{ APPLICATION_VERSION }} &copy; LinkThings</center>
                </template>
            </v-navigation-drawer>
        </div>

        <!-- body -->
        <v-main>
            <!-- require 2FA warning -->
            <v-alert
                v-if="me.require2fa && !me.enabled2fa"
                class="mb-0"
                color="error"
                dark
                tile
                dense
                transition="scale-transition"
            >
                <v-container class="py-0 px-6">
                    <v-icon left>warning</v-icon>
                    {{ $t("general.2fa") }}
                </v-container>
            </v-alert>

            <!-- Router view -->
            <router-view></router-view>

            <!-- FEEDBACK BUTTON -->
            <!-- <v-btn
                v-show="isLoggedIn && me.isTenantUser"
                dark
                fixed
                bottom
                left
                tile
                color="grey"
                class="feedback-button py-1 d-none d-md-flex"
                @click="feedbackFormDialog = !feedbackFormDialog"
            >
                <v-icon left>feedback</v-icon> Feedback
            </v-btn> -->

            <!-- HELP BUTTON -->
            <!-- <v-btn
                v-show="isLoggedIn && me.isTenantUser"
                dark
                fixed
                bottom
                right
                rounded
                color="warning"
                class="help-button py-1"
                @click="tutorialsDialog = !tutorialsDialog"
            >
                <v-icon left>help</v-icon> Help
            </v-btn> -->
        </v-main>

        <!-- Change my profile dialog -->
        <ChangeMyProfileForm :dialog.sync="changeProfileFormDialog" v-on:callback="fetchMe"> </ChangeMyProfileForm>

        <!-- Change my password dialog -->
        <ChangePasswordForm :dialog.sync="changePasswordFormDialog"> </ChangePasswordForm>

        <!-- Enable 2FA dialog -->
        <Enable2faForm :dialog.sync="enable2faFormaDialog" v-on:callback="fetchMe"></Enable2faForm>

        <!-- Disable 2FA dialog -->
        <Disable2faForm :dialog.sync="disable2faFormDialog" v-on:callback="fetchMe"> </Disable2faForm>

        <!-- <FeedbackForm :dialog.sync="feedbackFormDialog"></FeedbackForm> -->

        <Tutorials v-if="tutorialsDialog" :dialog.sync="tutorialsDialog"></Tutorials>

        <!-- footer -->
        <v-footer padless>
            <v-card class="flex" flat tile>
                <v-card-text class="py-2 grey--text text-center">
                    {{ new Date().getFullYear() }} — <strong>ColdCha</strong>
                </v-card-text>
            </v-card>
        </v-footer>
    </v-app>
</template>

<script>
import Config from "@/utils/config.json";
import helper from "@/utils/helper.js";
import Snackbar from "@/components/base/Snackbar.vue";
// import FeedbackForm from "@/components/base/FeedbackForm.vue";
import Tutorials from "@/components/support/Tutorials.vue";
import TemperatureUnitsDialog from "@/components/base/TemperatureUnitsDialog.vue";
import ReleaseNotes from "@/components/base/ReleaseNotes.vue";
import UserInbox from "@/components/base/UserInbox.vue";
import LangDropDown from "@/components/base/LangDropDown.vue";
import ChangeMyProfileForm from "@/components/userSettings/forms/ChangeMyProfileForm.vue";
import ChangePasswordForm from "@/components/userSettings/forms/ChangePasswordForm.vue";
import Enable2faForm from "@/components/userSettings/forms/Enable2faForm.vue";
import Disable2faForm from "@/components/userSettings/forms/Disable2faForm.vue";

import {
    TripPermissions,
    TrackerPermissions,
    CarrierPermissions,
    UserPermissions,
    TenantPermissions,
    BookingPermissions
} from "@/core/enum";
import { eventBus } from "@/main.js";
import { disableExperimentalFragmentVariables } from "graphql-tag";

export default {
    name: "App",
    components: {
        Snackbar,
        // FeedbackForm,
        Tutorials,
        TemperatureUnitsDialog,
        ReleaseNotes,
        UserInbox,
        LangDropDown,
        ChangeMyProfileForm,
        ChangePasswordForm,
        Enable2faForm,
        Disable2faForm
    },
    data() {
        return {
            changeProfileFormDialog: false,
            changePasswordFormDialog: false,
            enable2faFormaDialog: false,
            disable2faFormDialog: false,
            mini: true,
            showPreview: true,
            userTab: null,
            staffTab: null,
            drawer: true,
            feedbackFormDialog: false,
            tutorialsDialog: false,
            isLoggingOutAsOtherUser: false,
            openTemperatureUnitsDialog: false,
            isLoadingFetchMe: false,
            routeColor: null,
            logo: {
                type: null,
                url: null,
                text: null
            },
            defaultThemeJson: {
                primary: "#1B6A99",
                secondary: "#154D79",
                success: "#C5E17A",
                info: "#3D9BC8",
                accent: "#B05C4B",
                warning: "#ED8E83",
                error: "#CB5858"
            }
        };
    },

    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        me() {
            return helper.me();
        },
        canCreate() {
            if (this.me?.isSuperuser) {
                return true;
            }
            return this.hasPermission(TripPermissions.CREATE_TRIP);
        },
        APPLICATION_NAME() {
            return Config.VUE_APP_APPLICATION_NAME;
        },
        APPLICATION_VERSION() {
            return Config.VUE_APP_APPLICATION_VERSION;
        },
        isLoggedIn() {
            return this.$store.state.user.loggedIn;
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        },
        today() {
            var _today = new Date();
            _today.setHours(23, 59, 59, 59); // remove time

            return _today;
        },
        validTenant() {
            if (this.me?.isPartnerUser && this.me?.tenantParter !== null) {
                return true;
            } else if (!this.me?.isStaff && this.me?.tenant !== null) {
                return true;
            } else {
                return false;
            }
        },
        /*  themeColors(){
            return helper.themeColor();
        }, */
        routeName() {
            return this.$route.name;
        },
        profileMenuItem() {
            return [
                {
                    title: this.$t("home.profile"),
                    icon: "",
                    to: "/profile/details",
                    permission: ""
                },
                {
                    title: this.$t("general.change_profile"),
                    icon: "edit",
                    to: "changeProfile",
                    permission: ""
                },
                {
                    title: this.$t("general.change_password"),
                    icon: "lock",
                    to: "changePassword",
                    permission: ""
                },
                {
                    title: this.me.enabled2fa ? this.$t("general.disable_2fa") : this.$t("general.enable_2fa"),
                    icon: "pin",
                    to: "change2Fa",
                    permission: ""
                },
                {
                    title: this.$t("general.logout"),
                    icon: "logout",
                    to: "logout",
                    permission: ""
                }
            ];
        },
        previewComputedMenuItem() {
            let _menuItems = [];
            if (this.me.isSuperuser && this.me.isStaff) {
                _menuItems = [
                    ..._menuItems,
                    ...this.superUserMenuItems.filter(c => c.permission === "" || this.hasPermission(c.permission))
                ];
            }
            if (this.me.isStaff && !this.me.isSuperuser) {
                _menuItems = [
                    ..._menuItems,
                    ...this.staffMenuItems.filter(c => c.permission === "" || this.hasPermission(c.permission))
                ];
            }

            if (this.me.isTenantUser) {
                _menuItems = [
                    ..._menuItems,
                    ...this.previewTenantMenuItem.filter(
                        c => c.permission === "" || this.hasPermission(c.permission, c?.managePermission)
                    )
                ];
            }

            if (this.me.isPartnerUser) {
                _menuItems = [
                    ..._menuItems,
                    ...this.previewTenantPartnerMenuItems.filter(
                        c => c.permission === "" || this.hasPermission(c.permission, c?.managePermission)
                    )
                ];
            }

            _menuItems = _menuItems.filter((obj, index, self) => index === self.findIndex(t => t.to === obj.to));

            return _menuItems;
        },
        computedMenuItem() {
            let _menuItems = [];
            if (this.me.isSuperuser && this.me.isStaff) {
                _menuItems = [
                    ..._menuItems,
                    ...this.superUserMenuItems.filter(c => c.permission === "" || this.hasPermission(c.permission))
                ];
            }
            if (this.me.isStaff && !this.me.isSuperuser) {
                _menuItems = [
                    ..._menuItems,
                    ...this.staffMenuItems.filter(c => c.permission === "" || this.hasPermission(c.permission))
                ];
            }

            if (this.me.isTenantUser) {
                _menuItems = [
                    ..._menuItems,
                    ...this.tenantMenuItems.filter(
                        c => c.permission === "" || this.hasPermission(c.permission, c?.managePermission)
                    )
                ];
            }

            if (this.me.isPartnerUser) {
                _menuItems = [
                    ..._menuItems,
                    ...this.tenantPartnerMenuItems.filter(
                        c => c.permission === "" || this.hasPermission(c.permission, c?.managePermission)
                    )
                ];
            }

            _menuItems = _menuItems.filter((obj, index, self) => index === self.findIndex(t => t.to === obj.to));

            return _menuItems;
        },
        staffMenuItems() {
            return [
                {
                    title: this.$t("home.home"),
                    icon: "home",
                    to: "/",
                    permission: ""
                },
                {
                    title: this.$t("home.tenants"),
                    icon: "apartment",
                    to: "/tenants",
                    permission: TenantPermissions.VIEW_TENANT
                },
                {
                    title: this.$t("home.trackers"),
                    icon: "settings_remote",
                    to: "/trackersstock",
                    permission: TrackerPermissions.VIEW_TRACKER
                },
                {
                    title: this.$t("home.profile"),
                    icon: "account_circle",
                    to: "/profile/details",
                    permission: ""
                }
            ];
        },
        tenantPartnerMenuItems() {
            return [
                {
                    title: this.$t("home.home"),
                    icon: "home",
                    to: "/",
                    permission: ""
                },
                {
                    title: this.$t("home.shared_trips"),
                    icon: "share",
                    to: "/sharedtrips",
                    permission: ""
                },
                // {
                //     title: "QX Bookings",
                //     icon: "calendar_month",
                //     to: "/bookings",
                //     permission: BookingPermissions.VIEW_BOOKING_ORDER_QX,
                //     managePermission: BookingPermissions.VIEW_BOOKING_ORDER_QX
                // },
                {
                    title: this.$t("home.qx_bookings"),
                    icon: "calendar_month",
                    to: "/qxbookings",
                    permission: BookingPermissions.VIEW_BOOKING_ORDER_QX,
                    managePermission: BookingPermissions.VIEW_BOOKING_ORDER_QX,
                    children: [
                        {
                            title: this.$t("home.qx_bookings_orders"),
                            icon: "calendar_month",
                            to: "/qxorders"
                        },
                        {
                            title: this.$t("home.qx_bookings_templates"),
                            icon: "card_travel",
                            to: "/qxtemplates"
                        }
                    ]
                },
                {
                    title: this.$t("home.profile"),
                    icon: "account_circle",
                    to: "/profile/details",
                    permission: ""
                }
            ];
        },
        tenantMenuItems() {
            // this.screenSize;
            return [
                {
                    title: this.$t("home.home"),
                    icon: "home",
                    to: "/",
                    permission: ""
                },
                {
                    title: this.$t("home.trips"),
                    icon: "local_shipping",
                    to: "/trips",
                    permission: TripPermissions.VIEW_TRIP
                },
                {
                    title: this.$t("home.trips"),
                    icon: "local_shipping",
                    to: "/trips",
                    permission: TripPermissions.VIEW_TRIP
                },
                {
                    title: this.$t("home.freights"),
                    icon: "freights",
                    to: "/freights",
                    permission: TripPermissions.VIEW_TRIP_FREIGHT
                },
                {
                    title: this.$t("home.qx_bookings"),
                    icon: "calendar_month",
                    to: "/qxbookings",
                    permission: BookingPermissions.VIEW_BOOKING_ORDER_QX,
                    managePermission: BookingPermissions.MANAGE_BOOKING_ORDER_QX,
                    children: [
                        {
                            title: this.$t("home.qx_bookings_orders"),
                            icon: "calendar_month",
                            to: "/qxorders"
                        },
                        {
                            title: this.$t("home.qx_bookings_templates"),
                            icon: "card_travel",
                            to: "/qxtemplates"
                        }
                    ]
                },
                {
                    title: this.$t("home.trackers"),
                    icon: "settings_remote",
                    to: "/trackers",
                    permission: TrackerPermissions.VIEW_TRACKER
                },
                {
                    title: this.$t("home.partners"),
                    icon: "apartment",
                    to: "/partners",
                    permission: TenantPermissions.VIEW_TENANT_PARTNER,
                    children: [
                        {
                            title: this.$t("home.consignors"),
                            icon: "inventory",
                            to: "/consignors"
                        },
                        {
                            title: this.$t("home.consignees"),
                            icon: "card_travel",
                            to: "/consignees"
                        },
                        {
                            title: this.$t("home.forwarders"),
                            icon: "airport_shuttle",
                            to: "/forwarders"
                        },
                        {
                            title: this.$t("home.others"),
                            icon: "help_outline",
                            to: "/others"
                        }
                    ]
                },
                {
                    title: this.$t("home.carriers"),
                    icon: "commute",
                    to: "/carriers",
                    permission: TenantPermissions.VIEW_TENANT_PARTNER
                },
                {
                    title: this.$t("home.locations"),
                    icon: "share_location",
                    to: "/mylocations",
                    permission: TenantPermissions.VIEW_TENANT_LOCATION
                },
                {
                    title: this.$t("home.alert_rules"),
                    icon: "tune",
                    to: "/standardrules",
                    permission: TenantPermissions.VIEW_TENANT_ALERT_RULE
                },
                {
                    title: this.$t("home.weather"),
                    icon: "wb_sunny",
                    to: "/weather",
                    permission: TenantPermissions.VIEW_TENANT_LOCATION_WEATHER_FORECAST
                },
                {
                    title: this.$t("home.users"),
                    icon: "people",
                    to: "/usersStaff",
                    permission: UserPermissions.VIEW_USER
                },
                {
                    title: this.$t("home.profile"),
                    icon: "account_circle",
                    to: "/profile/details",
                    permission: ""
                },
                {
                    title: this.$t("home.settings"),
                    icon: "settings",
                    to: "/adminsettings",
                    permission: TenantPermissions.VIEW_TENANT_SETTINGS
                },
                {
                    title: this.$t("home.templates"),
                    icon: "description",
                    to: "/triptemplates",
                    permission: TripPermissions.VIEW_TRIP_TEMPLATE
                }
            ];
        },
        previewTenantMenuItem() {
            return [
                {
                    title: this.$t("home.home"),
                    icon: "dashboard",
                    to: "/",
                    permission: ""
                },
                {
                    title: this.$t("home.trips_preview"),
                    icon: "local_shipping",
                    to: "/previewtrips",
                    permission: TripPermissions.VIEW_TRIP,
                    tag: ""
                },
                {
                    title: this.$t("home.trackers"),
                    icon: "settings_remote",
                    to: "/previewtrackers",
                    permission: TrackerPermissions.VIEW_TRACKER,
                    tag: ""
                },
                {
                    title: this.$t("home.qx_bookings"),
                    icon: "calendar_month",
                    to: "/qxbookings",
                    permission: BookingPermissions.VIEW_BOOKING_ORDER_QX,
                    managePermission: BookingPermissions.MANAGE_BOOKING_ORDER_QX,
                    children: [
                        {
                            title: this.$t("home.qx_bookings_orders"),
                            icon: "calendar_month",
                            to: "/qxorders"
                        },
                        {
                            title: this.$t("home.qx_bookings_templates"),
                            icon: "card_travel",
                            to: "/qxtemplates"
                        }
                    ]
                },
                {
                    title: this.$t("home.collaborators"),
                    icon: "handshake",
                    to: "/previewpartners",
                    permission: TripPermissions.VIEW_TRIP,
                    tag: ""
                },
                {
                    title: this.$t("home.carriers"),
                    icon: "commute",
                    to: "/previewcarriers",
                    permission: TenantPermissions.VIEW_TENANT_PARTNER,
                    tag: ""
                },
                // {
                //     title: this.$t("home.locations"),
                //     icon: "share_location",
                //     to: "/mylocations",
                //     permission: TenantPermissions.VIEW_TENANT_LOCATION
                // },
                {
                    title: this.$t("home.locations"),
                    icon: "share_location",
                    to: "/previewlocations",
                    permission: TenantPermissions.VIEW_TENANT_LOCATION,
                    tag: ""
                },
                {
                    title: this.$t("home.alert_rules"),
                    icon: "warning",
                    to: "/standardrules",
                    permission: TenantPermissions.VIEW_TENANT_ALERT_RULE
                },
                {
                    title: this.$t("home.users"),
                    icon: "people",
                    to: "/usersStaff",
                    permission: UserPermissions.VIEW_USER
                },
                {
                    title: this.$t("home.templates"),
                    icon: "note_add",
                    to: "/triptemplates",
                    permission: TripPermissions.VIEW_TRIP_TEMPLATE
                },
                {
                    title: this.$t("home.profile"),
                    icon: "account_circle",
                    to: "/profile/details",
                    permission: ""
                },
                /*  {
                    title: this.$t("home.settings"),
                    icon: "settings",
                    to: "/adminsettings",
                    permission: TenantPermissions.VIEW_TENANT_SETTINGS
                }, */
                {
                    title: this.$t("home.settings"),
                    icon: "settings",
                    to: "/previewadminsettings",
                    permission: TenantPermissions.VIEW_TENANT_SETTINGS
                }
            ];
        },
        previewTenantPartnerMenuItems() {
            return [
                {
                    title: this.$t("home.home"),
                    icon: "home",
                    to: "/",
                    permission: ""
                },
                {
                    title: this.$t("home.shared_trips"),
                    icon: "share",
                    to: "/previewsharedtrips",
                    permission: ""
                },
                // {
                //     title: "QX Bookings",
                //     icon: "calendar_month",
                //     to: "/bookings",
                //     permission: BookingPermissions.VIEW_BOOKING_ORDER_QX,
                //     managePermission: BookingPermissions.VIEW_BOOKING_ORDER_QX
                // },
                {
                    title: this.$t("home.qx_bookings"),
                    icon: "calendar_month",
                    to: "/qxbookings",
                    permission: BookingPermissions.VIEW_BOOKING_ORDER_QX,
                    managePermission: BookingPermissions.VIEW_BOOKING_ORDER_QX,
                    children: [
                        {
                            title: this.$t("home.qx_bookings_orders"),
                            icon: "calendar_month",
                            to: "/qxorders"
                        },
                        {
                            title: this.$t("home.qx_bookings_templates"),
                            icon: "card_travel",
                            to: "/qxtemplates"
                        }
                    ]
                },
                {
                    title: this.$t("home.profile"),
                    icon: "account_circle",
                    to: "/profile/details",
                    permission: ""
                }
            ];
        },
        superUserMenuItems() {
            return [
                {
                    title: this.$t("home.home"),
                    icon: "home",
                    to: "/",
                    permission: ""
                },
                {
                    title: this.$t("home.tenants"),
                    icon: "apartment",
                    to: "/tenants",
                    permission: ""
                },
                {
                    title: this.$t("home.trackers"),
                    icon: "settings_remote",
                    to: "/trackersstock",
                    permission: ""
                },
                {
                    title: this.$t("home.staff"),
                    icon: "people",
                    to: "/staffusers",
                    permission: ""
                },
                {
                    title: this.$t("home.profile"),
                    icon: "account_circle",
                    to: "/profile/details",
                    permission: ""
                }
            ];
        }
    },
    watch: {
        isLoggedIn(val) {
            if (val) {
                // this.userTab = 0;
                // this.staffTab = 0;
                // fetch me
                this.fetchMe().then(() => {
                    this.requestThemeColors();
                    this.loadUserPreferences();
                    this.setLogo();
                    this.getRouteColor();
                });
            } else {
                //this.defaultThemeVuetify();
            }
        },
        isLoggedInAsOtherUser: {
            handler(val) {
                if (val) {
                    // Destroy storage
                    this.isLoggingOutAsOtherUser = false;

                    // Fetch me
                    this.fetchMe().then(() => {
                        this.requestThemeColors();
                        this.loadUserPreferences();
                        this.setLogo();
                        this.getRouteColor();
                    });
                }
            },
            deep: true,
            immediate: true
        },
        showPreview: {
            handler(val) {
                let _val = false;
                if (val === "true") _val = true;
                else if (val === "false") _val = false;
                else _val = val;

                //this update the drawer value
                this.drawer = _val;

                // localStorage.setItem("showPreview", val);
                this.$store.dispatch("user/updateShowPreview", { showPreview: _val });

                let views = [
                    {
                        old: "trips",
                        new: "previewtrips"
                    },
                    {
                        old: "trackers",
                        new: "previewtrackers"
                    },
                    // {
                    //     old: "Consignees",
                    //     new: "createpartners"
                    // },
                    {
                        old: "carriers",
                        new: "previewcarriers"
                    },
                    {
                        old: "Consignors",
                        new: "previewpartners"
                    },
                    {
                        old: "Consignees",
                        new: "previewpartners"
                    },
                    {
                        old: "Forwarders",
                        new: "previewpartners"
                    },
                    {
                        old: "Others",
                        new: "previewpartners"
                    },
                    {
                        old: "sharedtrips",
                        new: "previewsharedtrips"
                    },
                    {
                        old: "users",
                        new: "previewusers"
                    },
                    {
                        old: "mylocations",
                        new: "previewlocations"
                    },
                    {
                        old: "mylocations",
                        new: "createlocation"
                    },
                    {
                        old: "partnerlocations",
                        new: "previewlocations"
                    },
                    {
                        old: "publiclocations",
                        new: "previewlocations"
                    },
                    {
                        old: "settings",
                        new: "previewsettings"
                    },
                    {
                        old: "templates",
                        new: "previewtemplates"
                    },
                    {
                        old: "alerts",
                        mew: "previewalerts"
                    }
                ];

                let routeName = this.$route?.name;
                if (routeName && routeName.includes("trips?code=")) {
                    routeName = "trips";
                }

                views.some(view => {
                    // if (val && this.$route?.name?.toLowerCase().includes(view?.old?.toLowerCase())) {
                    if (_val && routeName?.toLowerCase() === view?.old?.toLowerCase()) {
                        this.$router.push({ name: view.new });
                        return true;
                        // } else if (!val && this.$route?.name?.toLowerCase().includes(view?.new?.toLowerCase())) {
                    } else if (!_val && routeName?.toLowerCase() === view?.new?.toLowerCase()) {
                        this.$router.push({ name: view.old });
                        return true;
                    }
                });
            },
            deep: true
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.TrackerPermissions = TrackerPermissions;
        this.TripPermissions = TripPermissions;
        this.CarrierPermissions = CarrierPermissions;
        this.UserPermissions = UserPermissions;
        this.TenantPermissions = TenantPermissions;
        // this.showPreview = localStorage.getItem("showPreview");
    },
    created() {
        // copy hasPermission from helper

        this.pushTo = helper.pushTo;
        this.isSuperUser = helper.isSuperUser;
        eventBus.$on("closeMenu", () => {
            let _isPreview = this.$store.getters["user/showPreview"];
            if (_isPreview) {
                this.mini = true;
            } else {
                this.drawer = false;
            }
        });
        if (this.isLoggedIn) {
            // fetch me
            this.requestThemeColors();
            this.setLogo();
            this.getRouteColor();
        }
    },
    mounted() {
        // this.$nextTick(() => {
        //     this.showPreview = localStorage?.getItem("showPreview");
        // });
        if (this.isLoggedIn) {
            // Check if the switch value is stored in localStorage
            // const switchValue = localStorage.getItem("showPreview");
            const switchValue = this.$store.getters["user/showPreview"];
            if (switchValue !== null && switchValue !== undefined) {
                // Update the switchValue if it exists in localStorage
                if (switchValue === "true") this.showPreview = true;
                else if (switchValue === "false") this.showPreview = false;
                else this.showPreview = switchValue;
            }
            // fetch me
            this.fetchMe().then(() => {
                this.requestThemeColors();
                this.loadUserPreferences();
                this.setLogo();
                this.getRouteColor();
            });
        } else {
            // this.fetchPublicSystemProfile();
        }
    },
    methods: {
        switchViews(val) {
            localStorage.setItem("showPreview", val);

            let views = [
                {
                    old: "trips",
                    new: "previewtrips"
                },
                {
                    old: "trackers",
                    new: "previewtrackers"
                },
                {
                    old: "carriers",
                    new: "previewcarriers"
                },
                {
                    old: "partners",
                    new: "previewpartners"
                },
                {
                    old: "consignors",
                    new: "previewpartners"
                },
                {
                    old: "consignees",
                    new: "previewpartners"
                },
                {
                    old: "forwarders",
                    new: "previewpartners"
                },
                {
                    old: "others",
                    new: "previewpartners"
                },
                {
                    old: "sharedtrips",
                    new: "previewsharedtrips"
                },
                {
                    old: "users",
                    new: "previewusers"
                },
                {
                    old: "mylocations",
                    new: "previewlocations"
                },
                {
                    old: "partnerlocations",
                    new: "previewlocations"
                },
                {
                    old: "publiclocations",
                    new: "previewlocations"
                },
                {
                    old: "settings",
                    new: "previewsettings"
                },
                {
                    old: "templates",
                    new: "previewtemplates"
                },
                {
                    old: "alerts",
                    mew: "previewalerts"
                }
            ];

            views.some(view => {
                if (val && this.$route.name.toLowerCase() === view.old) {
                    this.$router.push({ name: view.new });
                    return true;
                } else if (!val && this.$route.name.toLowerCase() === view.new) {
                    this.$router.push({ name: view.old });
                    return true;
                }
            });
        },
        handleItemClick(method) {
            if (typeof this[method] === "function") {
                this[method]();
            } else {
                console.error(`Method ${method} is not defined`);
            }
        },
        changeProfile() {
            this.changeProfileFormDialog = !this.changeProfileFormDialog;
        },
        changePassword() {
            this.changePasswordFormDialog = !this.changePasswordFormDialog;
        },
        change2Fa() {
            if (this.me.enabled2fa) this.disable2faFormDialog = !this.disable2faFormDialog;
            else this.enable2faFormaDialog = !this.enable2faFormaDialog;
        },
        createTrip() {
            this.$router.push({
                path: "/newtrips",
                query: {
                    create: true
                }
            });
        },
        getRouteColor() {
            this.routeColor = localStorage.getItem("routeColor");
        },
        setLogo() {
            if (localStorage.getItem("logo") != null) {
                this.logo = JSON.parse(localStorage.getItem("logo"));
            }
            let tenant = this.me?.isPartnerUser ? this.me?.tenantPartner : this.me?.tenant;
            if (tenant !== null && !this.isLoadingFetchMe) {
                if (tenant?.logoText !== "" && tenant?.logoText !== null && tenant?.logoText !== undefined) {
                    this.logo = {
                        type: "text",
                        text: tenant.logoText
                    };
                } else if (tenant?.logoUrl !== "" && tenant?.logoUrl !== null && tenant?.logoUrl !== undefined) {
                    this.logo = {
                        type: "image",
                        url: tenant.logoUrl
                    };
                } else this.logo = { type: null, url: null, text: null };

                localStorage.setItem("logo", JSON.stringify(this.logo));
            }
        },
        defaultThemeVuetify() {
            this.$vuetify.theme.themes.light.primary = "#1B6A99";
            this.$vuetify.theme.themes.light.secondary = "#154D79";
            this.$vuetify.theme.themes.light.accent = "#B05C4B";
            this.$vuetify.theme.themes.light.error = "#CB5858";
            this.$vuetify.theme.themes.light.info = "#3D9BC8";
            this.$vuetify.theme.themes.light.success = "#C5E17A";
            this.$vuetify.theme.themes.light.warning = "#ED8E83";
        },
        loadUserPreferences() {
            if (this.me.preferences == null) {
                this.openTemperatureUnitsDialog = true;
            }
        },
        inValidLogoText(text) {
            if (text === null || text === undefined) {
                return true;
            } else if (text.length === 0) {
                return true;
            } else {
                return false;
            }
        },
        requestThemeColors() {
            if (localStorage.getItem("themeColor") != null) {
                let _themeColor = JSON.parse(localStorage.getItem("themeColor"));
                Object.entries(_themeColor).forEach(([key, value]) => {
                    this.$vuetify.theme.themes.light[key] = value;
                });
            }
            let tenant = this.me?.isPartnerUser ? this.me?.tenantPartner : this.me?.tenant;
            if (tenant !== null && !this.isLoadingFetchMe) {
                if (tenant?.themeJson) {
                    let _themeColor = JSON.parse(tenant?.themeJson);
                    localStorage.setItem("themeColor", tenant?.themeJson);
                    Object.entries(_themeColor).forEach(([key, value]) => {
                        this.$vuetify.theme.themes.light[key] = value;
                    });
                }
            } else {
                //this.defaultThemeVuetify();
            }
        },
        fetchMe() {
            this.isLoadingFetchMe = true;
            return this.$store
                .dispatch("user/fetchMe")
                .then(() => {
                    try {
                        // google analytics
                        this.$gtag.set("user_properties", {
                            user_email: this.me.email,
                            user_tenant: this.me.tenant ? this.me.tenant.name : null
                        });
                    } catch (err) {
                        console.log(err);
                    }
                    this.isLoadingFetchMe = false;
                })
                .catch(error => {
                    console.log("fetchMe catched error: ", error);
                    this.isLoadingFetchMe = false;
                });
        },
        logoutAsOtherUser() {
            this.isLoggingOutAsOtherUser = true;
            this.$store
                .dispatch("user/logoutAsOtherUser")
                .then(() => {
                    // if (this.$route.name != "home") {
                    //     this.$router.push(this.$route.query.redirect || "/");
                    // } else {
                    //     this.fetchMe();
                    // }
                    let tenantId = this.me?.tenant.id;
                    this.fetchMe().then(() => {
                        this.isLoggingOutAsOtherUser = false;
                        this.defaultThemeVuetify();
                        this.$router.push({
                            name: "tenants",
                            query: { id: tenantId }
                        });
                    });
                })
                .catch(error => {
                    if (error.graphQLErrors) {
                        for (let err of error.graphQLErrors) {
                            // show snackbar
                            const payload = {
                                color: "error",
                                message: err.message
                            };
                            this.$store.dispatch("snackbar/showMessage", payload);
                        }
                    }
                })
                .finally(() => {
                    // this.isLoggingOutAsOtherUser = false;
                });
        },
        hasLogoText() {
            return this.logo?.type === "text" || this.logo?.type === "image";
        },
        logout() {
            let hasIt = this.hasLogoText();

            this.$store
                .dispatch("user/logout")
                .then(() => {
                    this.drawer = false;
                    // this.defaultThemeVuetify()
                })
                .catch(error => {
                    console.log("Logout failed", error);
                })
                .finally(() => {
                    if (hasIt) {
                        let params = { embedded: true };
                        if (this.routeColor != undefined) {
                            if (this.routeColor != null)
                                params = {
                                    embedded: true,
                                    color: this.routeColor
                                };
                        }
                        this.pushTo("login", params);
                    } else {
                        this.defaultThemeVuetify();
                        this.pushTo("login");
                    }
                    window.location.reload();
                    this.logo = { type: null, url: null, text: null };
                });
        }
    }
};
</script>

<style>
#app {
    background-color: var(--v-background-base);
}

.v-btn {
    text-transform: none !important;
}

.v-timeline::before {
    top: 45px;
    height: calc(100% - 110px);
}

.layout-vertical-nav.v-navigation-drawer {
    /* margin-inline-start:1rem; */
    /* border: none;
    background-color: transparent!important;
    box-shadow: none!important */
}

/* .layout-vertical-nav.v-navigation-drawer .v-navigation-drawer__content {
    box-shadow: 0 3px 1px -2px var(--v-shadow-key-umbra-opacity),0 2px 2px 0 var(--v-shadow-key-penumbra-opacity),0 1px 5px 0 var(--v-shadow-key-ambient-opacity);
    border-radius: .625rem;
    background-color: rgba(var(--v-theme-surface))!important;
    margin-block:1rem;margin-inline-start:1rem;
} */
</style>

<style scoped>
.feedback-button {
    top: 50%;
    left: -52px;
    transform: rotate(90deg);
    z-index: 2;
}
/* class to hidden overflow */

.help-button {
    bottom: 40px;
    right: 10px;
}
</style>
