<template>
    <v-card outlined flat class="pa-3 rounded-lg">
        <v-card flat>
            <v-card-title class="textTitle">
                <v-row>
                    <v-col>
                        <v-icon :color="tripObj.status === 'ACTIVE' ? '#4CAF50' : ''" class="mr-2">{{
                            getIconForTransportMode(tripObj.transportMode)
                        }}</v-icon>
                        <span>{{ $t("trips.trip_route") }}</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <v-chip label v-if="tripObj.trade" class="textBody pa-4">
                            {{ tripObj.trade }}
                        </v-chip>
                    </v-col>
                </v-row>
            </v-card-title>
            <LoadingBar v-if="loadingQueries"></LoadingBar>
            <div v-else>
                <LoadingBar v-if="loadingQueries && tripRoute.length === 0"></LoadingBar>
                <div v-else-if="!loadingQueries && tripRoute.length === 0">
                    <NoData></NoData>
                </div>
                <v-card v-else flat>
                    <v-card-text>
                        <v-row class="align-self-stretch">
                            <v-col
                                v-for="(currentPosition, i) in tripRoute"
                                :key="i"
                                :class="progressClass(i, tripRoute.length)"
                            >
                                <v-card outlined @click="editLocation(currentPosition)" v-if="!isSmallScreen">
                                    <v-card-text class="col-auto">
                                        <div v-html="dynamicContainer(currentPosition)"></div>
                                        <div
                                            class="d-flex flex-row align-center px-1"
                                            :style="
                                                `
                                                    background-color: ${primaryOpacity};
                                                    border-radius: 4px;
                                                    height: 22px;
                                                    width: fit-content;
                                                `
                                            "
                                        >
                                            <v-icon
                                                left
                                                size="8"
                                                :style="
                                                    `
                                                    color:${primaryColor};
                                                `
                                                "
                                            >
                                                circle
                                            </v-icon>
                                            <div class="chipText">
                                                {{ currentPosition.type }}
                                            </div>
                                        </div>
                                    </v-card-text>
                                </v-card>
                                <v-card outlined v-else @click="editLocation(currentPosition)" class="col-auto pa-0">
                                    <div
                                        class="d-flex flex-row align-center px-1"
                                        :style="
                                            `
                                                background-color: ${primaryOpacity};
                                                border-radius: 4px;
                                                height: 22px;
                                               
                                            `
                                        "
                                    >
                                        <div class="chipText">
                                            {{ currentPosition.type }}
                                        </div>
                                    </div>
                                </v-card>
                                <v-slider
                                    v-if="i < tripRoute.length - 1"
                                    class="minWidth"
                                    :value="
                                        currentPosition.overview !== null
                                            ? currentPosition.overview.progress.progress
                                            : 0
                                    "
                                    :thumb-size="22"
                                    thumb-label="always"
                                    thumb-color="primary"
                                    track-color="grey lighten-2"
                                    hide-details
                                    readonly
                                >
                                    <template v-slot:thumb-label>
                                        <v-icon color="white" small :readonly="false">
                                            {{ getIconForTransportMode(currentPosition.mode) }}
                                        </v-icon>
                                    </template>
                                </v-slider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
        </v-card>
        <v-dialog persistent v-model="editDialog" max-width="600">
            <v-card flat v-if="editItem">
                <v-card-title class="textTitle2 primary">
                    <v-spacer></v-spacer>
                    <span>{{
                        editItem.type ? editItem.type : editItem.portTransportType ? editItem.portTransportType : ""
                    }}</span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="dialogCardContainer">
                    <v-card v-if="!editedMode" flat height="250" class="mt-5" rounded>
                        <v-row>
                            <v-col v-for="(item, i) in dinamicBody(editItem)" :key="i" cols="4">
                                <div class="textHeader">
                                    {{ item.text }}
                                </div>
                                <div class="textBody">
                                    {{ item.value }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                    <PreviewEditLocation
                        v-else-if="editObjectType === 'location'"
                        v-model="editItem"
                        :isValid.sync="isValid"
                    >
                    </PreviewEditLocation>
                    <PreviewEditRoute v-else v-model="editItem"> </PreviewEditRoute>
                </v-card-text>
                <v-card-actions>
                    <v-btn icon @click="editMode()" :disabled="!canEdit" v-if="!editedMode">
                        <v-icon>edit</v-icon>
                    </v-btn>
                    <v-spacer v-if="!editedMode"></v-spacer>
                    <v-btn 
                        elevation="0"
                        class=""
                        @click="cancel()"
                        v-if="!editedMode"
                    >
                        {{ $t("general.close") }}
                    </v-btn>
                    <v-spacer v-if="editedMode"></v-spacer>
                    <v-btn 
                        text 
                        @click="cancel()"
                        v-if="editedMode"
                    >
                        {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                        elevation="0"
                        class="primary"
                        :loading="loadingBtn"
                        :disabled="isDisabledLocation()"
                        @click="save()"
                        v-if="editedMode"
                    >
                        {{ $t("general.save") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import gql from "graphql-tag";

//import SeaTransportForm from "@/components/trips/forms/SeaTransportForm.vue";
import helper from "@/utils/helper.js";
import LoadingBar from "@/components/base/LoadingBar.vue";
import { TripPermissions } from "@/core/enum";
//import NoPermission from "@/components/base/NoPermission.vue";
import PreviewEditRoute from "@/components/trips/forms/PreviewEditRoute.vue";
import PreviewEditLocation from "@/components/trips/forms/PreviewEditLocation.vue";
import Config from "@/utils/config.json";
import moment from "moment";
import NoData from "@/components/base/NoData.vue";

export default {
    components: {
        LoadingBar,
        PreviewEditRoute,
        NoData,
        PreviewEditLocation
    },
    props: {
        tripObj: {
            type: Object,
            required: true
        },
        tripIsLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        },
        tripTimeRange: {
            type: Array,
            required: false,
            default: function() {
                return [];
            }
        }
    },
    apollo: {
        // trip: {
        //     query: gql`
        //         query trip($tripId: ID!) {
        //             trip(id: $tripId) {
        //                 id
        //                 trade
        //                 logco
        //                 loadPoint
        //                 loadDate
        //                 tenantCarrier {
        //                     id
        //                     name
        //                     carrier {
        //                         id
        //                         name
        //                         website
        //                         code
        //                     }
        //                 }
        //                 tripFreightSet {
        //                     edges {
        //                         node {
        //                             id
        //                             carrierEvents
        //                         }
        //                     }
        //                 }
        //                 tripSeaTransportSet {
        //                     edges {
        //                         node {
        //                             id
        //                             sequence
        //                             portCode
        //                             portTransportType
        //                             voyage
        //                             vessel {
        //                                 id
        //                                 name
        //                             }
        //                             portName
        //                             portTimezone
        //                             vesselInputType
        //                             vesselInputValue
        //                             actualDepartureDate
        //                             actualArrivalDate
        //                             estimatedDepartureDate
        //                             estimatedArrivalDate
        //                             originalEstimatedDepartureDate
        //                             originalEstimatedArrivalDate
        //                             actualDepartureDateSource
        //                             actualArrivalDateSource
        //                             estimatedDepartureDateSource
        //                             estimatedArrivalDateSource
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     `,
        //     variables() {
        //         // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
        //         return { tripId: this.tripObj?.id };
        //     },
        //     // Additional options here
        //     fetchPolicy: "cache-and-network",
        //     nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
        //     update: data => data.trip,
        //     watchLoading(isLoading) {
        //         this.$emit("loading", isLoading);
        //     },
        //     // debounce: 800,
        //     skip() {
        //         return !this.tripObj?.id;
        //     },
        //     result({ data, loading }) {
        //         if (!loading) {
        //             if (data?.trip?.tripSeaTransportSet?.edges.length > 0) {
        //                 this.defaultSeaTransport = JSON.parse(
        //                     JSON.stringify(
        //                         data.trip.tripSeaTransportSet.edges
        //                             .map(e => e.node)
        //                             .sort((a, b) => a.sequence - b.sequence)
        //                     )
        //                 );
        //             }
        //         }
        //         // this.tenantCarrier = data?.trip?.tenantCarrier;
        //         // this.carrierEvents = data?.trip?.tripFreightSet.edges[0];
        //     }
        // },
        // trackerDeviceTimeRange: {
        //     query: gql`
        //         query trackerDeviceTimeRange($tripId: ID, $trackerId: ID) {
        //             trackerDeviceTimeRange(tripId: $tripId, trackerId: $trackerId)
        //         }
        //     `,
        //     variables() {
        //         return {
        //             tripId: this.tripObj?.id,
        //             trackerId: null
        //         };
        //     },
        //     // Additional options here
        //     fetchPolicy: "cache-and-network",
        //     nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
        //     result({ loading, data }) {
        //         if (!loading) {
        //             this.tripTimeRange = JSON.parse(data.trackerDeviceTimeRange);
        //         }
        //     },
        //     debounce: 0,
        //     skip() {
        //         return !this.tripObj?.id;
        //     }
        // }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.TripPermissions = TripPermissions;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.relativeTime = helper.relativeTime;
        this.formatDateTimeWithoutTimezone = helper.formatDateTimeWithoutTimezone;
        this.formatDateTime = helper.formatDateTime;
        this.calculateDelaysInDay = helper.calculateDelaysInDay;
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.isEmpty = helper.isEmpty;
    },
    data() {
        return {
            editDialog: false,
            isValid: false,
            vesselEventTimelines: { edges: [] },
            // tripTimeRange: null,
            sortDesc: false,
            vessel: null,
            sliderValue: 10,
            eventTimeGte: null,
            eventTimeLte: null,
            hasMissingFields: false,
            seaTransport: null,
            defaultSeaTransport: [
                {
                    sequence: 0,
                    voyage: "",
                    portCode: "",
                    portTransportType: "POL",
                    vesselInputType: "",
                    vesselInputValue: "",
                    estimatedDepartureDate: "",
                    actualDepartureDate: ""
                },
                {
                    sequence: 1,
                    portCode: "",
                    portTransportType: "POD",
                    estimatedArrivalDate: "",
                    actualArrivalDate: ""
                }
            ],
            location: null,
            editedMode: false,
            loadingBtn: false,
            vesselOverviews: [],
            type: [
                { text: this.$t("trips.vessel_name"), value: "shipname" },
                { text: this.$t("trips.call_sign"), value: "callsign" },
                { text: "IMO", value: "imo" },
                { text: "MMSI", value: "mmsi" }
            ],
            // tenantCarrier: null,
            // carrierEvents: null,
            editItem: null,
            disabled: false,
            editObjectType: null
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        tenantCarrier() {
            return this.tripObj?.tenantCarrier;
        },
        carrierEvents() {
            return this.tripObj?.tripFreightSet.edges[0];
        },
        computedSeaTransport() {
            let local = JSON.parse(
                JSON.stringify(
                    this.tripObj.tripSeaTransportSet.edges.map(e => e.node).sort((a, b) => a.sequence - b.sequence)
                )
            );
            if (this.isEmpty(local)) {
                return this.defaultSeaTransport;
            }

            return local;
        },
        loadingQueries() {
            return this.tripIsLoading; 
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 960;
        },
        primaryOpacity() {
            let hex = this.$vuetify.theme.themes.light.primary;
            let alpha = 0.2;
            hex = hex.replace(/^#/, "");

            // Parse the hexadecimal values for red, green, and blue
            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);
            alpha = Math.min(1, Math.max(0, alpha));
            return `rgba(${r}, ${g}, ${b}, ${alpha})`;
        },
        primaryColor() {
            return this.$vuetify.theme.themes.light.primary;
        },
        canEdit() {
            if (this.tripObj?.status === "COMPLETED") return false;

            if (this.isSharedTrip) return false;

            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.EDIT_TRIP_SEA_TRANSPORT,
                        this.organisationPermissions
                    )
                )
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        TripPermissions.EDIT_TRIP_SEA_TRANSPORT,
                        this.organisationPermissions
                    )
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_SEA_TRANSPORT,
                        this.tripObj.tenant.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (!this.hasPermission(TripPermissions.EDIT_TRIP_SEA_TRANSPORT) && this.me?.tenant) return false;
            }
            return true;
        },
        tripRoute() {
            let _tripRoute = [];

            let _originName = this.tripObj?.originLocation ? this.tripObj?.originLocation?.city : null;
            let _destinationName = this.tripObj?.destinationLocation ? this.tripObj?.destinationLocation?.city : null;

            let _atd = this.tripTimeRange?.start_date;
            let _ata = this.tripTimeRange?.end_date;
            let _etd = this.tripObj?.plannedDepartureDate;
            let _eta = this.tripObj?.plannedArrivalDate;

            let origin = {
                portTransportType: "Origin",
                portName: _originName,
                portCode: this.tripObj?.originLocation?.name || null,
                originalEstimatedDepartureDate: this.tripObj?.plannedDepartureDate || null,
                estimatedDepartureDate: this.tripObj?.plannedDepartureDate ? _etd : null,
                actualDepartureDate: _atd ? _atd : null
            };

            let destination = {
                portTransportType: "Destination",
                portName: _destinationName,
                portCode: this.tripObj?.destinationLocation?.name || null,
                originalEstimatedArrivalDate: this.tripObj?.plannedArrivalDate || null,
                estimatedArrivalDate: this.tripObj?.plannedArrivalDate ? _eta : null,
                actualArrivalDate: this.tripObj?.completedAt ? this.tripObj?.completedAt : _ata ? _ata : null
            };

            switch (this.tripObj?.transportMode) {
                case "SEA":
                    if (this.tripObj?.tripSeaTransportSet?.edges.length > 0) {
                        let pol_location = this.tripObj?.tripSeaTransportSet?.edges.find(
                            e => e.node.portTransportType === "POL"
                        );

                        let pod_location = this.tripObj?.tripSeaTransportSet?.edges.find(
                            e => e.node.portTransportType === "POD"
                        );
                        if (pod_location) {
                            if (pod_location?.node?.estimatedArrivalDate) {
                                destination.estimatedArrivalDate = pod_location.node.estimatedArrivalDate;
                            }
                            if (pod_location?.node?.actualArrivalDate) {
                                destination.actualArrivalDate = pod_location.node.actualArrivalDate;
                            }
                        }

                        // let seaTransports = this.tripObj.tripSeaTransportSet.edges
                        //     .map(e => e.node)
                        //     .sort((a, b) => a.sequence - b.sequence);
                        
                        let seaTransports = [...this.computedSeaTransport];
                        seaTransports.unshift(origin);
                        seaTransports.push(destination);
                        
                        _tripRoute = seaTransports.map((element, index, array) => {
                            const nextElement = array[index + 1] || {};

                            return {
                                id: index,
                                mode: "SEA",
                                type: element?.portTransportType || null,
                                name: element?.portName || null,
                                code: element?.portCode || null,
                                originalEstimatedDepartureDate:
                                    this.formatDateTime(element?.originalEstimatedDepartureDate) || null,
                                originalEstimatedArrivalDate:
                                    this.formatDateTime(element?.originalEstimatedArrivalDate) || null,
                                estimatedDepartureDate:
                                    this.formatDateTime(element?.estimatedDepartureDate) ||
                                    this.formatDateTime(element?.originalEstimatedDepartureDate) ||
                                    null,
                                actualDepartureDate: this.formatDateTime(element?.actualDepartureDate) || null,
                                estimatedArrivalDate:
                                    this.formatDateTime(element?.estimatedArrivalDate) ||
                                    this.formatDateTime(element?.originalEstimatedArrivalDate) ||
                                    null,
                                actualArrivalDate: this.formatDateTime(element?.actualArrivalDate) || null,
                                vessel: element?.vessel,
                                voyage: element?.voyage,
                                overview: this.overviewParser(element, nextElement),
                                vesselInputType: element?.vesselInputType || null,
                                vesselInputValue: element?.vesselInputValue || null
                            };
                        });
                    }
                    break;
                case "AIR":
                    if (this.tripObj?.transportMode) {
                        let _airTransport = [];
                        _airTransport.push(origin);
                        _airTransport.push(destination);

                        _tripRoute = _airTransport.map((element, index, array) => {
                            const nextElement = array[index + 1] || {};

                            return {
                                id: index,
                                mode: "AIR",
                                type: element?.portTransportType || null,
                                name: element?.portName || null,
                                code: element?.portCode || null,
                                originalEstimatedDepartureDate:
                                    this.formatDateTime(element?.originalEstimatedDepartureDate) || null,
                                originalEstimatedArrivalDate:
                                    this.formatDateTime(element?.originalEstimatedArrivalDate) || null,
                                estimatedDepartureDate:
                                    this.formatDateTime(element?.estimatedDepartureDate) ||
                                    this.formatDateTime(element?.originalEstimatedDepartureDate) ||
                                    null,
                                actualDepartureDate: this.formatDateTime(element?.actualDepartureDate),
                                estimatedArrivalDate:
                                    this.formatDateTime(element?.estimatedArrivalDate) ||
                                    this.formatDateTime(element?.originalEstimatedArrivalDate) ||
                                    null,
                                actualArrivalDate: this.formatDateTime(element?.actualArrivalDate) || null,
                                vessel: "N/A",
                                voyage: "N/A",
                                overview: this.overviewParser(element, nextElement)
                            };
                        });
                    }

                    break;
                case "ROAD":
                    if (this.tripObj?.transportMode) {
                        let _airTransport = [];
                        _airTransport.push(origin);
                        _airTransport.push(destination);

                        _tripRoute = _airTransport.map((element, index, array) => {
                            const nextElement = array[index + 1] || {};

                            return {
                                id: index,
                                mode: "ROAD",
                                type: element?.portTransportType || null,
                                name: element?.portName || null,
                                code: element?.portCode || null,
                                originalEstimatedDepartureDate: element?.originalEstimatedDepartureDate
                                    ? this.formatDateTime(element?.originalEstimatedDepartureDate)
                                    : null,
                                originalEstimatedArrivalDate: element?.originalEstimatedArrivalDate
                                    ? this.formatDateTime(element?.originalEstimatedArrivalDate)
                                    : null,

                                estimatedDepartureDate: element?.estimatedDepartureDate
                                    ? this.formatDateTime(element?.estimatedDepartureDate)
                                    : element?.originalEstimatedDepartureDate
                                    ? this.formatDateTime(element?.originalEstimatedDepartureDate)
                                    : null,

                                actualDepartureDate: element?.actualDepartureDate
                                    ? this.formatDateTime(element?.actualDepartureDate)
                                    : null,
                                estimatedArrivalDate: element?.estimatedArrivalDate
                                    ? this.formatDateTime(element?.estimatedArrivalDate)
                                    : element?.originalEstimatedArrivalDate
                                    ? this.formatDateTime(element?.originalEstimatedArrivalDate)
                                    : null,

                                actualArrivalDate: element?.actualArrivalDate
                                    ? this.formatDateTime(element?.actualArrivalDate)
                                    : null,
                                vessel: "N/A",
                                voyage: "N/A",
                                overview: this.overviewParser(element, nextElement)
                            };
                        });
                    }
                    break;
                case "RAIL":
                    if (this.tripObj?.transportMode) {
                        let _airTransport = [];
                        _airTransport.push(origin);
                        _airTransport.push(destination);

                        _tripRoute = _airTransport.map((element, index, array) => {
                            const nextElement = array[index + 1] || {};

                            return {
                                id: index,
                                mode: "RAIL",
                                type: element?.portTransportType || null,
                                name: element?.portName || null,
                                code: element?.portCode || null,
                                originalEstimatedDepartureDate: element?.originalEstimatedDepartureDate
                                    ? this.formatDateTime(element?.originalEstimatedDepartureDate)
                                    : null,
                                originalEstimatedArrivalDate: element?.originalEstimatedArrivalDate
                                    ? this.formatDateTime(element?.originalEstimatedArrivalDate)
                                    : null,

                                estimatedDepartureDate: element?.estimatedDepartureDate
                                    ? this.formatDateTime(element?.estimatedDepartureDate)
                                    : element?.originalEstimatedDepartureDate
                                    ? this.formatDateTime(element?.originalEstimatedDepartureDate)
                                    : null,

                                actualDepartureDate: element?.actualDepartureDate
                                    ? this.formatDateTime(element?.actualDepartureDate)
                                    : null,
                                estimatedArrivalDate: element?.estimatedArrivalDate
                                    ? this.formatDateTime(element?.estimatedArrivalDate)
                                    : element?.originalEstimatedArrivalDate
                                    ? this.formatDateTime(element?.originalEstimatedArrivalDate)
                                    : null,

                                actualArrivalDate: element?.actualArrivalDate
                                    ? this.formatDateTime(element?.actualArrivalDate)
                                    : null,
                                vessel: "N/A",
                                voyage: "N/A",
                                overview: this.overviewParser(element, nextElement)
                            };
                        });
                    }
                    break;
            }

            return _tripRoute;
        },
        tripIsActive() {
            return this.tripObj?.status.toLowerCase() === "active";
        }
    },
    watch: {},
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "trip_sea_transport_tab_svw"
        });
        this.setUserProperties();
    },
    methods: {
        cancel() {
            this.disabled = true;
            this.editDialog = false;
            this.editedMode = false;
            this.editItem = null;
        },
        progressClass(i, length) {
            let breakpoint = this.$vuetify.breakpoint.name;
            let currentClass = "";
            switch (breakpoint) {
                case "xs":
                    if (i === length - 1) {
                        currentClass = "d-flex flex-row align-center col-auto";
                    } else {
                        if (length > 2) {
                            if (length < 5) {
                                currentClass = "d-flex flex-row align-center pa-0";
                            } else {
                                currentClass = "d-flex flex-row  align-center pa-0";
                            }
                        } else {
                            currentClass = "d-flex flex-row align-center";
                        }
                    }
                    break;
                case "sm":
                    if (i === length - 1) {
                        currentClass = "d-flex flex-row align-center col-auto";
                    } else {
                        if (length > 2) {
                            if (length < 5) {
                                currentClass = "d-flex flex-row align-center pa-0";
                            } else {
                                currentClass = "d-flex flex-row align-center pa-0";
                            }
                        } else {
                            currentClass = "d-flex flex-row align-center";
                        }
                    }
                    break;
                case "md":
                    if (i === length - 1) {
                        currentClass = "d-flex flex-row align-center col-auto pa-1";
                    } else {
                        if (length > 2) {
                            if (length < 5) {
                                currentClass = "d-flex flex-row align-center pa-1 col-3";
                            } else {
                                currentClass = "d-flex flex-row align-center ";
                            }
                        } else {
                            currentClass = "d-flex flex-row align-center";
                        }
                    }
                    break;
                case "lg":
                    if (i === length - 1) {
                        currentClass = "d-flex flex-row align-center col-auto pa-1";
                    } else {
                        if (length > 2) {
                            if (length < 5) {
                                currentClass = "d-flex flex-row align-center pa-1";
                            } else {
                                currentClass = "d-flex flex-row align-center pa-1";
                            }
                        } else {
                            currentClass = "d-flex flex-row align-center";
                        }
                    }
                    break;
                case "xl":
                    if (i === length - 1) {
                        currentClass = "d-flex flex-row align-center col-auto";
                    } else {
                        if (length > 2) {
                            if (length < 5) {
                                currentClass = "d-flex flex-row align-center pa-1";
                            } else {
                                currentClass = "d-flex flex-row align-center ";
                            }
                        } else {
                            currentClass = "d-flex flex-row align-center";
                        }
                    }
                    break;
            }

            return currentClass;
        },
        editLocation(currentLocation) {
            const { originLocation, destinationLocation, plannedDepartureDate, plannedArrivalDate } = this.tripObj;
            
            this.editObjectType = ["Origin", "Destination"].includes(currentLocation.type)
                ? "location"
                : "seaTransport";

            this.editItem = {
                type:
                    currentLocation.type === "Origin"
                        ? "Origin"
                        : currentLocation.type === "Destination"
                        ? "Destination"
                        : null,
                origin: originLocation?.id || null,
                destination: destinationLocation?.id || null,
                plannedDepartureDate: this.formatDateTime(plannedDepartureDate),
                plannedArrivalDate: this.formatDateTime(plannedArrivalDate),
                portName: currentLocation.type === "Origin" ? originLocation?.name : destinationLocation?.name
            };

            if (!["Origin", "Destination"].includes(currentLocation.type)) {
                this.disabled = true;
                this.location = null;
                let item = this.computedSeaTransport.find(i => i.id === currentLocation.overview.id);
                this.editItem = {
                    id: item.id,
                    sequence: item.sequence,
                    portCode: item.portCode,
                    portTransportType: item.portTransportType,
                    vesselInputType: item.vesselInputType || null,
                    vesselInputValue: item.vesselInputValue || null,
                    voyage: item.voyage || null,
                    originalEstimatedDepartureDate: this.formatDateTime(item.originalEstimatedDepartureDate),
                    originalEstimatedArrivalDate: this.formatDateTime(item.originalEstimatedArrivalDate),
                    estimatedDepartureDate:
                        this.formatDateTime(item.estimatedDepartureDate) ||
                        this.formatDateTime(item.originalEstimatedDepartureDate),
                    estimatedArrivalDate:
                        this.formatDateTime(item.estimatedArrivalDate) ||
                        this.formatDateTime(item.originalEstimatedArrivalDate),
                    actualDepartureDate: this.formatDateTime(item.actualDepartureDate),
                    actualArrivalDate: this.formatDateTime(item.actualArrivalDate),
                    portName: item.portName,
                    vessel: item.vessel ? item.vessel : null
                };
            }

            this.disabled = true;
            this.editDialog = true;
        },
        textTruncate(text, length) {
            if (text.length > length) {
                return text.substring(0, length) + "...";
            } else {
                return text;
            }
        },
        isDisabledLocation() {
            if (this.location) {
                if (this.isValid) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("trip_sea_transport_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("trip_sea_transport_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        getDelay(item, type) {
            let actual = item?.actualArrivalDate;
            let offset = null;

            const defaultFormatDate = date => {
                if (!date) return null;
                const [dateA, time] = date.split(" ");
                let _newDateA = moment(dateA, helper.getDateFormat());
                let _DateA = moment(_newDateA, ["DD/MM/YYYY", "YYYY/MM/DD", "MM/DD/YYYY"]).toDate();
                let _dateFormatA = moment(_DateA).format("YYYY-MM-DD");

                return _dateFormatA + " " + time;
            };

            /* Departure */
            let originalEtd = defaultFormatDate(item.originalEstimatedDepartureDate);
            let etd = defaultFormatDate(item.estimatedDepartureDate);
            let atd = defaultFormatDate(item.actualDepartureDate);

            /* Arrival */
            let originalEta = defaultFormatDate(item.originalEstimatedArrivalDate);
            let eta = defaultFormatDate(item.estimatedArrivalDate);
            let ata = defaultFormatDate(item.actualArrivalDate);

            if (type === "eta") offset = this.calculateDelaysInDay(originalEta, eta, ata);
            else if (type == "etd") offset = this.calculateDelaysInDay(originalEtd, etd, atd);

            let event =
                offset < 0 && !actual
                    ? this.$t("date.to_go")
                    : offset < 0 && actual
                    ? this.$t("date.earlier")
                    : this.$t("date.late");

            if (!offset) {
                return {
                    offset: "",
                    event: "",
                    color: "success--text",
                    text: ""
                };
            }

            let color = offset > 0 ? "error--text" : "success--text";
            let text = offset === 0 ? "" : `${Math.abs(offset)} ${this.$t("date.days2")} ${event}`;

            return {
                offset: offset,
                event: event,
                color: color,
                text: text
            };
        },
        getIconForTransportMode(transportMode) {
            switch (transportMode) {
                case "SEA":
                    return "directions_boat";
                case "AIR":
                    return "flight";
                case "ROAD":
                    return "directions_car";
                case "RAIL":
                    return "train";
                default:
                    return "help_outline";
            }
        },
        updateHasMissingFields(value) {
            this.hasMissingFields = value;
        },
        getDate(date) {
            return this.formatDateTime(date)?.slice(0, 10);
        },
        editMode() {
            this.editedMode = true;
        },
        dynamicContainer(location) {
            let _template = "";
            let timeType = "";
            let _date = "";

            if (location) {
                const {
                    name = "",
                    type,
                    actualDepartureDate,
                    actualArrivalDate,
                    estimatedDepartureDate,
                    estimatedArrivalDate,
                    originalEstimatedDepartureDate,
                    originalEstimatedArrivalDate
                } = location;

                let locationName = name || "";

                if (type.toLowerCase() === "origin") {
                    timeType = this.$t("trips.planned_departure");
                    _date = originalEstimatedDepartureDate || estimatedArrivalDate || this.$t("general.no_date");
                } else if (type.toLowerCase() === "destination") {
                    timeType = this.$t("trips.planned_arrival");
                    _date = originalEstimatedArrivalDate || estimatedArrivalDate || this.$t("general.no_date");
                } else {
                    if (actualDepartureDate) {
                        timeType = "ATD";
                        let _atd = actualDepartureDate;
                        _date = _atd || this.$t("general.no_date");
                    } else if (actualArrivalDate) {
                        timeType = "ATA";
                        let _ata = actualArrivalDate;
                        _date = _ata || this.$t("general.no_date");
                    } else if (estimatedDepartureDate || originalEstimatedDepartureDate) {
                        timeType = "ETD";
                        let _etd = estimatedDepartureDate || originalEstimatedDepartureDate;
                        _date = _etd || this.$t("general.no_date");
                    } else if (estimatedArrivalDate || originalEstimatedArrivalDate) {
                        timeType = "ETA";
                        let _eta = estimatedArrivalDate || originalEstimatedArrivalDate;
                        _date = _eta || this.$t("general.no_date");
                    }
                }

                const locationNameTruncate = this.textTruncate(locationName, 19);
                _template = `
                    <div class="textLeft">
                        <div
                            style="
                                color: #555464;;
                                font-size: 11px;
                                font-weight: 400;
                                line-height: 12px;
                                letter-spacing: 0em;
                                text-align: left;
                            "
                        >
                            ${timeType}
                        </div>
                        <div
                            style="
                                color: #0E0D35;
                                font-size: 12px;
                                font-weight: 700;
                                line-height: 14px;
                                letter-spacing: 0em;
                                text-align: left;
                            "
                        >
                            ${_date}
                        </div>
                        <div
                            style="
                                color: #555464;;
                                font-size: 11px;
                                font-weight: 400;
                                line-height: 12px;
                                letter-spacing: 0em;
                                text-align: left;
                            "
                        >
                           ${locationNameTruncate}
                        </div>

                    </div>
                `;
            }

            return _template;
        },
        dinamicBody(location) {
            const vesselByType = type => {
                switch (type) {
                    case "shipname":
                        return this.$t("trips.vessel_name");
                    case "callsign":
                        return this.$t("trips.call_sign");
                    case "imo":
                        return "IMO";
                    case "mmsi":
                        return "MMSI";
                    default:
                        return "Vessel Input";
                }
            };
            let _body = [];
            let vesselType = vesselByType(location?.vesselInputType);
            let name = location?.name || location?.portName;

            if (location) {
                _body.push({
                    text: this.$t("general.location"),
                    value: location?.portName || null
                });

                let _location = location.type
                    ? location.type
                    : location.portTransportType
                    ? location.portTransportType
                    : null;

                switch (_location) {
                    case "POL":
                        _body.push({
                            text: this.$t("headers.code"),
                            value: location.portCode
                        });
                        _body.push({
                            text: this.$t("headers.vessel"),
                            value: location?.vessel?.name
                        });
                        _body.push({
                            text: this.$t("trips.voyage"),
                            value: location.voyage
                        });

                        _body.push({
                            text: this.$t("trips.actual_departure"),
                            value: location.actualDepartureDate
                        });
                        _body.push({
                            text: this.$t("trips.original_estimated_departure"),
                            value: location.originalEstimatedDepartureDate
                        });
                        _body.push({
                            text: this.$t("trips.estimated_departure"),
                            value: location.estimatedDepartureDate
                        });
                        _body.push({
                            text: this.$t("trips.time_lapse"),
                            value: this.getDelay(location, "etd").text
                        });

                        if (vesselType) {
                            _body.push({
                                text: vesselType,
                                value: location.vesselInputValue
                            });
                        }

                        break;
                    case "POD":
                        _body.push({
                            text: this.$t("headers.code"),
                            value: location.portCode
                        });
                        _body.push({
                            text: this.$t("headers.vessel"),
                            value: location?.vessel?.name
                        });
                        _body.push({
                            text: this.$t("trips.voyage"),
                            value: location.voyage
                        });

                        _body.push({
                            text: this.$t("trips.actual_arrival"),
                            value: location.actualArrivalDate
                        });
                        _body.push({
                            text: this.$t("trips.original_estimated_arrival"),
                            value: location.originalEstimatedArrivalDate
                        });
                        _body.push({
                            text: this.$t("trips.estimated_arrival"),
                            value: location.estimatedArrivalDate
                        });
                        _body.push({
                            text: this.$t("trips.time_lapse"),
                            value: this.getDelay(location, "eta").text
                        });

                        if (vesselType) {
                            _body.push({
                                text: vesselType,
                                value: location.vesselInputValue
                            });
                        }

                        break;
                    case "T/S":
                        _body.push({
                            text: this.$t("headers.code"),
                            value: location.portCode
                        });
                        _body.push({
                            text: this.$t("headers.vessel"),
                            value: location?.vessel?.name
                        });
                        _body.push({
                            text: this.$t("trips.voyage"),
                            value: location.voyage
                        });

                        _body.push({
                            text: this.$t("trips.actual_departure"),
                            value: location.actualDepartureDate
                        });
                        _body.push({
                            text: this.$t("trips.original_estimated_departure"),
                            value: location.originalEstimatedDepartureDate
                        });
                        _body.push({
                            text: this.$t("trips.estimated_departure"),
                            value: location.estimatedDepartureDate
                        });
                        _body.push({
                            text: this.$t("trips.time_lapse"),
                            value: this.getDelay(location, "etd").text
                        });
                        _body.push({
                            text: this.$t("trips.actual_arrival"),
                            value: location.actualArrivalDate
                        });

                        if (vesselType) {
                            _body.push({
                                text: vesselType,
                                value: location.vesselInputValue
                            });
                        }

                        break;
                    default:
                        if (location.type === "Origin") {
                            _body.push({
                                text: this.$t("trips.estimated_departure"),
                                value: location.plannedDepartureDate
                            });
                        } else {
                            _body.push({
                                text: this.$t("trips.estimated_arrival"),
                                value: location.plannedArrivalDate
                            });
                        }
                        break;
                }
            }

            return _body;
        },
        save() {
            if ( this.editObjectType==='location') {
                this.saveLocation();
            } else {
                this.saveSeaTransport();
            }
            this.loadingBtn = true;
        },
        saveLocation() {
            let payload = {
                tripId: this.tripObj.id,
                originLocationId: this.editItem.origin,
                destinationLocationId: this.editItem.destination,
                plannedDepartureDate: this.editItem.plannedDepartureDate
                    ? helper.convertDateTimeToUTC(this.editItem.plannedDepartureDate)
                    : null,
                plannedArrivalDate: this.editItem.plannedArrivalDate
                    ? helper.convertDateTimeToUTC(this.editItem.plannedArrivalDate)
                    : null
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateTripLocation($input: UpdateTripLocationInput!) {
                            updateTripLocation(input: $input) {
                                trip {
                                    id
                                    trade
                                    logco
                                    loadPoint
                                    loadDate
                                    originLocation {
                                        id
                                        name
                                        city
                                        country
                                    }
                                    destinationLocation {
                                        id
                                        name
                                        city
                                        country
                                    }
                                    plannedDepartureDate
                                    plannedArrivalDate
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    const payload = {
                        color: "blue",
                        message: this.$t("general.trip_edited")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.loadingBtn = false;
                    this.editedMode = false;
                    this.editDialog = false;
                });
        },
        saveSeaTransport() {
            let seaTransportsInput = [];

            //update sea transport
            let copyArray = this.computedSeaTransport.reduce((acc, i) => {
                if (i.id === this.editItem.id) {
                    acc.push({
                        id: i.id,
                        sequence: i.sequence,
                        portCode: this.editItem.portCode,
                        portTransportType: i.portTransportType,
                        voyage: this.editItem.voyage,
                        vesselInputType: this.editItem.vesselInputType,
                        vesselInputValue: this.editItem.vesselInputValue,
                        portName: i.portName,
                        portTimezone: i.portTimezone,
                        actualDepartureDate: this.editItem.actualDepartureDate,
                        actualArrivalDate: this.editItem.actualArrivalDate,
                        estimatedDepartureDate: this.editItem.estimatedDepartureDate,
                        estimatedArrivalDate: this.editItem.estimatedArrivalDate,
                        originalEstimatedDepartureDate: this.editItem.estimatedDepartureDate,
                        originalEstimatedArrivalDate: this.editItem.estimatedArrivalDate,
                        actualDepartureDateSource: i.actualDepartureDateSource,
                        actualArrivalDateSource: i.actualArrivalDateSource,
                        estimatedDepartureDateSource: i.estimatedDepartureDateSource,
                        estimatedArrivalDateSource: i.estimatedArrivalDateSource
                    });
                } else {
                    acc.push({
                        id: i.id,
                        sequence: i.sequence,
                        portCode: i.portCode,
                        portTransportType: i.portTransportType,
                        voyage: i.voyage,
                        vesselInputType: i.vesselInputType,
                        vesselInputValue: i.vesselInputValue,
                        portName: i.portName,
                        portTimezone: i.portTimezone,
                        actualDepartureDate: this.formatDateTime(i.actualDepartureDate),
                        actualArrivalDate: this.formatDateTime(i.actualArrivalDate),
                        estimatedDepartureDate: this.formatDateTime(i.estimatedDepartureDate),
                        estimatedArrivalDate: this.formatDateTime(i.estimatedArrivalDate),
                        originalEstimatedDepartureDate: this.formatDateTime(i.originalEstimatedDepartureDate),
                        originalEstimatedArrivalDate: this.formatDateTime(i.originalEstimatedArrivalDate),
                        actualDepartureDateSource: i.actualDepartureDateSource,
                        actualArrivalDateSource: i.actualArrivalDateSource,
                        estimatedDepartureDateSource: i.estimatedDepartureDateSource,
                        estimatedArrivalDateSource: i.estimatedArrivalDateSource
                    });
                }

                return acc;
            }, []);

            seaTransportsInput = copyArray.map(el => {
                return {
                    id: el.id,
                    sequence: el.sequence,
                    portCode: el.portCode,
                    portTransportType: el.portTransportType,
                    voyage: el.voyage,
                    vesselInputType: el.vesselInputType,
                    vesselInputValue: el.vesselInputValue,
                    originalEstimatedDepartureDate:
                        helper.convertDateTimeToUTC(el.originalEstimatedDepartureDate) || null,
                    originalEstimatedArrivalDate: helper.convertDateTimeToUTC(el.originalEstimatedArrivalDate) || null,
                    estimatedDepartureDate: helper.convertDateTimeToUTC(el.estimatedDepartureDate) || null,
                    estimatedArrivalDate: helper.convertDateTimeToUTC(el.estimatedArrivalDate) || null,
                    actualDepartureDate: helper.convertDateTimeToUTC(el.actualDepartureDate) || null,
                    actualArrivalDate: helper.convertDateTimeToUTC(el.actualArrivalDate) || null
                };
            });

            let payload = {
                tripId: this.tripObj.id,
                trade: null,
                logco: null,
                loadPoint: null,
                loadDate: null,
                seaTransports: seaTransportsInput
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateTripSeaTransports($input: UpdateTripSeaTransportsInput!) {
                            updateTripSeaTransports(input: $input) {
                                trip {
                                    id
                                    trade
                                    logco
                                    loadPoint
                                    loadDate
                                    tripSeaTransportSet {
                                        edges {
                                            node {
                                                id
                                                sequence
                                                portCode
                                                portTransportType
                                                vesselInputType
                                                vesselInputValue
                                                voyage
                                                sequence
                                                portCode
                                                vesselInputType
                                                vesselInputValue
                                                actualDepartureDate
                                                actualArrivalDate
                                                estimatedDepartureDate
                                                estimatedArrivalDate
                                                originalEstimatedDepartureDate
                                                originalEstimatedArrivalDate
                                                portName
                                                portTimezone
                                                vessel {
                                                    id
                                                    name
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    const payload = {
                        color: "blue",
                        message: this.$t("trips.trip_sea_saved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.loadingBtn = false;
                    this.editedMode = false;
                    this.editDialog = false;
                });
        },
        overviewParser(origin, destination) {
            if (Object.keys(origin)?.lenght === 0 || Object.keys(destination)?.length === 0) return null;

            let overview = {
                id: origin.id,
                ATD: origin.actualDepartureDate ? this.formatDateTime(origin.actualDepartureDate) : null,
                ETD: origin?.estimatedDepartureDate
                    ? this.formatDateTime(origin?.estimatedDepartureDate)
                    : null || origin?.originalEstimatedDepartureDate
                    ? this.formatDateTime(origin?.originalEstimatedDepartureDate)
                    : null,
                ATA: this.formatDateTime(destination.actualArrivalDate),
                ETA: destination?.estimatedArrivalDate
                    ? this.formatDateTime(destination?.estimatedArrivalDate)
                    : null || destination?.originalEstimatedArrivalDate
                    ? this.formatDateTime(destination?.originalEstimatedArrivalDate)
                    : null || destination?.estimatedDepartureDate
                    ? this.formatDateTime(destination?.estimatedDepartureDate)
                    : null,
                destinationATD: destination?.actualDepartureDate
                    ? this.formatDateTime(destination?.actualDepartureDate)
                    : null
            };

            overview.progress = this.calculateProgress(
                overview.ETD,
                overview.ATD,
                overview.ETA,
                overview.ATA,
                overview.destinationATD,
                origin,
                destination
            );

            return overview;
        },
        calculateProgress(originETD, originATD, destinationETA, destinationATA, destinationATD, origin, destination) {
            const now = this.formatDateTime(new Date());
            let tripEnd = this.tripObj?.plannedArrivalDate;
            let tripActualDate = this.tripTimeRange?.end_date
                ? this.formatDateTime(this.tripTimeRange?.end_date)
                : null;
            let isFinished = false;
            let isComplete = this.tripObj?.completedAt ? true : false;
            let estimatedEnd = "";

            if (origin.portTransportType === "POD") {
                let actualArrivalDate = origin.actualArrivalDate;
                isFinished = tripActualDate ? this.checkDate(actualArrivalDate, tripActualDate) === "equal" : false;
            }

            if (isFinished) {
                let progress = 100;
                let daysLeft = 0;
                let disabled = true;
                let status = "Completed";
                return { progress, daysLeft, disabled, status };
            }

            if (!isComplete) {
                const howManyDaysToEnd = (date1, date2) => {
                    let formats = ["DD/MM/YYYY HH:mm", "YYYY/MM/DD HH:mm", "MM/DD/YYYY HH:mm"];

                    let momentDateA = moment(date1, formats);
                    let momentDateB = moment(date2, formats);
                    let _dateA = momentDateA.toDate();
                    let _dateB = momentDateB.toDate();

                    return Math.ceil((_dateB - _dateA) / (1000 * 60 * 60 * 24));
                };

                if (howManyDaysToEnd(now, tripEnd) >= 0) {
                    estimatedEnd = tripEnd;
                } else {
                    estimatedEnd = now;
                }

                const effectiveStart = originATD || originETD || now;
                const effectiveEnd = destinationATA || destinationATD || destinationETA || estimatedEnd;

                let { progress, days, disabled, status } = this.calculateProgressAndDays(effectiveStart, effectiveEnd);
                let daysLeft = days < 0 ? this.$t("trips.delayed") : `${days} ${this.$t("date.days")}`;
                return { progress, daysLeft, disabled, status };
            } else {
                let progress = 100;
                let daysLeft = 0;
                let disabled = true;
                let status = "Completed";
                return { progress, daysLeft, disabled, status };
            }
        },
        checkDate(date1, date2) {
            if (!date1 || !date2) return null;

            // let _dateA = this.formatDateTime(date1);
            // let _dateB = this.formatDateTime(date2);

            // Define an array of expected date formats
            const dateFormats = ["DD/MM/YYYY HH:mm", "YYYY/MM/DD HH:mm", "MM/DD/YYYY HH:mm"];

            // Create Moment objects using the array of formats
            let momentDateA = moment(date1, dateFormats);
            let momentDateB = moment(date2, dateFormats);

            // Compare the dates
            if (momentDateA.isBefore(momentDateB)) {
                return "lessThan";
            } else if (momentDateA.isAfter(momentDateB)) {
                return "greaterThan";
            } else if (momentDateA.isSame(momentDateB)) {
                return "equal";
            }
        },
        convertToISO(dateString) {
            const parts = dateString.split(/[/ :]/);
            return new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4]).toISOString();
        },
        calculateProgressAndDays(start, end) {
            let dateFormats = ["DD/MM/YYYY HH:mm", "YYYY/MM/DD HH:mm", "MM/DD/YYYY HH:mm"];
            let momentDateA = moment(start, dateFormats);
            let momentDateB = moment(end, dateFormats);
            let _dateA = momentDateA.toDate();
            let _dateB = momentDateB.toDate();
            let now = new Date();

            if (this.checkDate(_dateA, _dateB) === "equal") {
                return {
                    progress: 100,
                    days: 0,
                    disabled: true,
                    status: "Completed"
                };
            }

            if (this.checkDate(_dateA, _dateB) === "greaterThan") {
                return {
                    progress: 100,
                    days: 0,
                    disabled: true,
                    status: "Completed"
                };
            }

            let startPoint = new Date(_dateA);
            let endPoint = new Date(_dateB);

            if (isNaN(startPoint) || isNaN(endPoint)) {
                return {
                    progress: 0,
                    days: 0,
                    disabled: true,
                    status: "Invalid dates"
                };
            }

            if (this.checkDate(now, startPoint) === "lessThan") {
                return {
                    progress: 0,
                    days: Math.ceil((startPoint - now) / (1000 * 60 * 60 * 24)),
                    disabled: false,
                    status: "Not started"
                };
            } else if (this.checkDate(now, endPoint) === "greaterThan") {
                return {
                    progress: 100,
                    days: 0,
                    disabled: true,
                    status: "Completed"
                };
            }

            let totalDuration = endPoint - startPoint;
            let elapsed = now - startPoint;
            let progress = Math.round((elapsed / totalDuration) * 100);

            let days = Math.ceil((endPoint - now) / (1000 * 60 * 60 * 24));

            return { progress, days, disabled: false, status: "In progress" };
        }
    }
};
</script>
<style scoped>
.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
    color: #e6e9f5;
}
.contentBg {
    background-color: #f9fafe;
}
.content-box {
    columns: 2.5 auto;
}

/* Small devices (sm, >=576px) */

.cardContainer {
    height: 250px;
    overflow-y: auto !important;
    margin-bottom: 12px;
}
.cardContainer::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #cfd0d5ed;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color);
}
.iconColor {
    color: #0e0d35;
}
.textTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textTitle2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
.textHeader {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #868599;
    height: 15px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 15px;
}

.chipText {
    color: #1f3245;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
}
.textDetails {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.dialogCardContainer {
    max-width: 100%;
    max-height: 500px;
    overflow-y: auto;
}
.minWidth {
    min-width: 20px;
}
</style>
