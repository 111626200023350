<template>
    <LoadingBar v-if="$apollo.queries.tenantAlertRuleObject.loading && !alertRule && !tenantAlertRule"></LoadingBar>
    <v-card fill-height flat width="100%" color="background" v-else >
        <v-toolbar flat color="background">
            <!----- TOP LEFT ICONS ---->
            <v-btn icon @click="$emit('close')">
                <v-icon>arrow_back_ios_new</v-icon>
            </v-btn>
            <v-btn v-if="shouldShowFullScreenButton" icon @click="changeFullScreen">
                <v-icon>{{ isFullScreen ? "fullscreen_exit" : "crop_free" }}</v-icon>
            </v-btn>
            <v-switch
                v-if="canEdit"
                dense
                v-model="isEnabled"
                :label="isEnabled ? this.$t('headers.enabled') : this.$t('headers.disabled')"
                class="mt-5 ml-3"
                @change="updateAlertRuleStatus()"
            ></v-switch>
            <v-spacer></v-spacer>

            <!---- TOP RIGHT ICONS ---->
            <v-btn icon v-if="canEdit">
                <v-icon @click="editAlertRuleDialogHandler()" :disabled="loadingUpdate">
                    edit
                </v-icon>
            </v-btn>
            <v-btn icon v-if="canDelete">
                <v-icon @click="openDeleteAlertRuleDialog = true">
                    delete
                </v-icon>
            </v-btn>

            <!---- TODO not working, need to create new emits like the edit had (ABOVE) ---->
            <AddAlertRuleDialog
                :openAddAlertRuleDialog="openEditAlertRuleDialog"
                :edit="editMode"
                v-on:editClose="editMode = false"
                ref="addAlertRule"
                v-on:close-dialog="openEditAlertRuleDialog = false"
                v-on:item-updated="updateAlertRuleValues"
            ></AddAlertRuleDialog>

            <!---- DELETE ALERT RULE DIALOG ---->
            <DeleteAlertRuleDialog
                :openDeleteAlertRuleDialog="openDeleteAlertRuleDialog"
                :alertRuleId="alertRule.id"
                v-on:refreshDataTable="closeDrawerAndRefresh"
                v-on:closeDeleteDialog="openDeleteAlertRuleDialog = false"
            >
                <template v-slot:title>
                    {{ $t("alert_rules.delete_alert") }}
                </template>
                <template #content>
                    {{ $t("alert_rules.sure_alert") }}
                </template>
            </DeleteAlertRuleDialog>

            <!---- FILTER DIALOG ---->
            <FilterMenu
                :title="$t('dashboard.customize')"
                :description="$t('dashboard.display_tabs')"
                :choices="filterChoices"
                :open-on-hover="false"
                height="500px"
                width="250px"
                v-on:changed="onFilterMenuChanged"
                class="mr-n2"
            >
            </FilterMenu>
        </v-toolbar>

        <v-card-title class="pt-0 px-5">
            <!---- GROUP GENERAL INFO DISPLAY (NAME, USERS...) ---->
            <v-row class="d-flex align-center pt-2 mx-2">
                <!---- NAME ---->
                <div class="py-0 px-0 mb-1">
                    <span class="text-caption">{{ $t("headers.name") }}</span
                    ><br />
                    <span class="font-weight-medium">
                        {{ tenantAlertRule.name }}
                    </span>
                </div>
                <!---- NOTIFICATIONS ---->
                <v-col cols="3" sm="2" md="2" lg="2" xl="2" xxl="2" class="py-0 px-0 mb-1 ml-10">
                    <span class="text-caption">{{ $t("headers.notify_partner") }}</span
                    ><br />
                    <span class="font-weight-medium" v-if="shouldNotifyPartner">
                        {{ $t("headers.enabled") }}
                    </span>
                    <span class="font-weight-medium" v-else>
                        {{ $t("headers.disabled") }}
                    </span>
                </v-col>
                <v-col cols="3" sm="2" md="2" lg="2" xl="2" xxl="2" class="py-0 px-0 mb-1">
                    <span class="text-caption">{{ $t("headers.notify_record") }}</span
                    ><br />
                    <span class="font-weight-medium" v-if="tenantAlertRule.shouldNotifyForEveryRecord">
                        {{ $t("headers.enabled") }}
                    </span>
                    <span class="font-weight-medium" v-else>
                        {{ $t("headers.disabled") }}
                    </span>
                </v-col>
                <v-spacer></v-spacer>

                <!---- SELECT TAB OR LIST VIEW ---->
                <v-col cols="1" sm="1" class="d-flex justify-end">
                    <v-select
                        v-model="view"
                        :items="viewChoices"
                        dense
                        item-text="name"
                        item-value="code"
                        return-value
                        outlined
                        hide-details
                        class="fit"
                    ></v-select>
                </v-col>
            </v-row>
        </v-card-title>
    
        <!---- tab view ---->
        <AlertRuleDetailTabView
            v-if="view === 'tab'"
            :alertRule="tenantAlertRule"
            :selectedFilters="selectedFilters"
            v-on:refreshDataTable="refreshDataTable()"
        />

        <!---- list view ---->
        <AlertRuleDetailListView
            v-if="view === 'list'"
            class="mx-5 pb-10"
            :alertRule="tenantAlertRule"
            :selectedFilters="selectedFilters"
            v-on:refreshDataTable="refreshDataTable()"
        />
    </v-card>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper";
import FilterMenu from "@/components/base/FilterMenu.vue";
import AlertRuleDetailTabView from "@/components/alertrules/AlertRuleDetailTabView.vue";
import AlertRuleDetailListView from "@/components/alertrules/AlertRuleDetailListView.vue";
import DeleteAlertRuleDialog from "@/components/alertrules/delete/DeleteAlertRuleDialog.vue";
import { TenantPermissions } from "@/core/enum";
import AddAlertRuleDialog from "@/components/alertrules/create/AddAlertRuleDialog.vue";
import Config from "@/utils/config.json";
import LoadingBar from "@/components/base/LoadingBar.vue";

export default {
    name: "AlertRuleDetail",
    props: {
        alertRuleProp: {
            type: Object
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        },
        alertId: {
            type: String
        }
    },
    components: {
        AlertRuleDetailTabView,
        AlertRuleDetailListView,
        FilterMenu,
        DeleteAlertRuleDialog,
        AddAlertRuleDialog,
        LoadingBar
    },
    apollo: {
        tenantAlertRuleObject: {
            query: gql`
                query tenantAlertRule($tenantAlertRuleId: ID!) {
                    tenantAlertRule(id: $tenantAlertRuleId) {
                        id
                        tag
                        name
                        isEnabled
                        description
                        alertSeverity
                        createdAt
                        modifiedAt
                        applyToAllNewTrips
                        applyToCarriers
                        applyToConsignees
                        applyToConsignors
                        applyToForwarders
                        applyToOthers
                        isStandardAlert
                        shouldNotifyCarrier
                        shouldNotifyForEveryRecord
                        shouldNotifyPartner
                        standardAlertCode
                        tenant {
                            id
                            name
                        }
                        tenantAlertRuleActionGroupSet {
                            totalCount
                            edges {
                                node {
                                    id
                                    tenantActionGroup {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                        tenantAlertRuleTriggerSet {
                            totalCount
                            edges {
                                node {
                                    id
                                    triggerJson
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                let _alertId = this.alertRuleProp ? this.alertRuleProp.id : this.alertId;
                return {
                    tenantAlertRuleId: this.alertId
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenantAlertRule,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            result({ loading, data }) {
                if (!loading  && data && data.tenantAlertRule) {
                    this.isEnabled = data.tenantAlertRule.isEnabled || false;
                }
            }
        }
    },
    data() {
        return {
            /**********************/
            /* --- QUERY DATA --- */
            /**********************/
            updatedAlertRule: null,
            tenantAlertRuleObject: null,
            /***********************************/
            /* --- DIALOGS/LOADINGS/MENUS --- */
            /***********************************/
            openDeleteAlertRuleDialog: false,
            openEditAlertRuleDialog: false,
            isEnabled: false,
            loadingUpdate: false,
            /*******************/
            /* --- FILTERS --- */
            /*******************/
            view: "tab",
            viewChoices: [
                { code: "tab", name: this.$t("general.tab") },
                { code: "list", name: this.$t("general.list") }
            ],
            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "alertDetails",
                    name: "Alert Details",
                    tag: "alert_rules.alert_details",
                    enabled: true
                }
                /*  {
                    // for filter menu
                    code: "actionGroups",
                    name: "Action Groups",
                    enabled: true
                } */
            ],
            filterChoices: [],
            editMode: false
        };
    },
    created() {
        this.viewType = helper.getViewType("alertRuleDetailViewType");
        this.filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "alertRuleDetailFilterChoices");
        this.isEnabled = this.alertRule ? this.alertRule?.isEnabled : false;
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
    },
    computed: {
        tenantAlertRule() {
            let _alertRule = JSON.parse(JSON.stringify(this.alertRule));
            if (this.updatedAlertRule) {
                let _updatedAlertRule = JSON.parse(JSON.stringify(this.updatedAlertRule));
                _alertRule = _updatedAlertRule;
            }

            return _alertRule;
        },
        shouldNotifyPartner() {
            if (!this.tenantAlertRule.shouldNotifyPartner) return false;
            const {
                shouldNotifyCarrier,
                shouldNotifyConsignor,
                shouldNotifyConsignee,
                shouldNotifyForwarder,
                shouldNotifyOther
            } = JSON.parse(this.tenantAlertRule.shouldNotifyPartner);

            return (
                shouldNotifyCarrier ||
                shouldNotifyConsignor ||
                shouldNotifyConsignee ||
                shouldNotifyForwarder ||
                shouldNotifyOther
            );
        },
        alertRule() {
            return this.tenantAlertRuleObject ? this.tenantAlertRuleObject : this.alertRuleProp;
        },
        selectedFilters() {
            // Return array only with enabled filters
            let _filters = [];

            _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        canEdit() {
            return this.hasPermission(TenantPermissions.EDIT_TENANT_ALERT_RULE);
        },
        canDelete() {
            return this.hasPermission(TenantPermissions.DELETE_TENANT_ALERT_RULE);
        }
    },
    watch: {
        viewType: {
            handler(value) {
                if (value) {
                    helper.updateMyPreferences({
                        alertRuleDetailViewType: value
                    });
                }
            },
            deep: true
        },
        filterChoices: {
            handler(value) {
                if (value) {
                    helper.updateMyPreferences({
                        alertRuleDetailFilterChoices: value
                    });
                }
            },
            deep: true
        },
        tenantAlertRule: {
            handler(value) {
                if (value) {
                    this.isEnabled = value.isEnabled ? value.isEnabled : false;
                }
            },
            deep: true
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "alert_rule_details_svw"
        });
        this.setUserProperties();
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("alert_rule_details_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("alert_rule_details_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        editAlertRuleDialogHandler() {
            this.openEditAlertRuleDialog = true;
            this.editMode = true;

            let _alertRule = JSON.parse(JSON.stringify(this.alertRule));

            if (this.updatedAlertRule) {
                let _updatedAlertRule = JSON.parse(JSON.stringify(this.updatedAlertRule));
                _alertRule = _updatedAlertRule;
            }

            this.$refs.addAlertRule.editModeTrigger(_alertRule);

            //this.$refs.editAlertRuleDialog.initializeEditing(item);
        },
        closeDialog() {
            this.openEditAlertRuleDialog = false;
            /* this.$emit("refreshDataTable"); */
        },

        closeDrawerAndRefresh() {
            // Emit event that will refresh table data
            this.$emit("refreshDataTable");
            // Close detail drawer if open
            this.$emit("close");
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        },
        updateAlertRuleValues(val) {
            this.$emit("refreshDataTable");
            this.openEditAlertRuleDialog = false;
            this.updatedAlertRule = val;
        },
        refreshDataTable() {
            this.$emit("refreshDataTable");
        },
        updateAlertRuleStatus() {
            this.loadingUpdate = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateTenantAlertRuleStatus($input: UpdateTenantAlertRuleStatusInput!) {
                            updateTenantAlertRuleStatus(input: $input) {
                                tenantAlertRule {
                                    id
                                    tag
                                    name
                                    isEnabled
                                    description
                                    alertSeverity
                                    createdAt
                                    modifiedAt
                                    applyToAllNewTrips
                                    applyToCarriers
                                    applyToConsignees
                                    applyToConsignors
                                    applyToForwarders
                                    applyToOthers
                                    isStandardAlert
                                    shouldNotifyCarrier
                                    shouldNotifyForEveryRecord
                                    shouldNotifyPartner
                                    standardAlertCode
                                    tenant {
                                        id
                                        name
                                    }
                                    tenantAlertRuleActionGroupSet {
                                        totalCount
                                        edges {
                                            node {
                                                id
                                                tenantActionGroup {
                                                    id
                                                    name
                                                }
                                            }
                                        }
                                    }
                                    tenantAlertRuleTriggerSet {
                                        totalCount
                                        edges {
                                            node {
                                                id
                                                triggerJson
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.alertRule.id
                        }
                    }
                })
                .then(({ data }) => {
                    const { updateTenantAlertRuleStatus } = data;
                    const { tenantAlertRule } = updateTenantAlertRuleStatus;

                    this.updatedAlertRule = tenantAlertRule;
                    const payload = {
                        color: "blue",
                        message: "Change was save successfully"
                    };

                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loadingUpdate = false;
                });
        }
    }
};
</script>
<style scoped>
.v-select.fit {
    max-width: min-content;
    padding: 10px;
}
.v-select.fit .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}
</style>
