<template>
    <v-card elevation="0" outlined v-resize="onResize" color="transparent">
        <v-card-text class="pa-0">
            <v-row dense align="center">
                <v-col cols="auto" class="pt-2">
                    <SortBy
                        style="max-width: 280px;"
                        v-model="tripFilter.orderBy"
                        :sortByChoices="sortByChoices"
                        v-on:updateFilter="updateTableFilter"
                    ></SortBy>
                </v-col>
                <v-col cols="2" class="mb-4">
                    <v-switch
                        v-model="showAll"
                        :label="showAll ? $t('general.active') : $t('general.all_trips')"
                        hide-details
                        inset
                        color="primary"
                        class="ml-2 text-no-wrap"
                        @change="updateTableFilter"
                    ></v-switch>
                </v-col>
                <v-col cols="12" class="d-flex align-center justify-center" v-if="tripIsLoading && !hasTrips">
                    <LoadingBar></LoadingBar>
                </v-col>
                <v-col cols="12" v-else>
                    <div v-if="hasTrips">
                        <v-card
                            v-for="(trip, index) in trips.edges"
                            :key="index"
                            class="mb-4 rounded-lg px-3"
                            outlined
                            flat
                            @click.stop="detailsPage(trip)"
                        >
                            <v-card-text>
                                <v-row>
                                    <v-card
                                        ref="actionCard"
                                        flat
                                        color="transparent "
                                        :class="!layoutTransitionLgToMd ? 'py-3 pr-6' : 'py-3'"
                                        :width="!layoutTransitionLgToMd ? '' : '100%'"
                                    >
                                        <!--? Section 1 - Action Card -->
                                        <v-row :class="!layoutTransitionLgToMd ? 'd-flex flex-column' : ''">
                                            <v-col cols="1" xs="1" sm="4" md="4" lg="4" xl="4">
                                                <v-icon
                                                    :color="transport(trip).colorIcon"
                                                    :size="transport(trip).iconSize"
                                                    :style="transport(trip).style"
                                                >
                                                    {{ transport(trip).icon }}
                                                </v-icon>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <v-col
                                                v-for="(item, idx) in actionCard(trip)"
                                                :key="idx"
                                                :cols="item.col.xs"
                                                :xl="item.col.xl"
                                                :lg="item.col.lg"
                                                :md="item.col.md"
                                                :sm="item.col.sm"
                                                :xs="item.col.xs"
                                            >
                                                <v-badge
                                                    bordered
                                                    :color="item.badgeColor.color"
                                                    offset-x="5"
                                                    offset-y="10"
                                                >
                                                    <template v-slot:badge>
                                                        <div :style="`color: ${item.badgeColor.colorText}`">
                                                            {{ item.value }}
                                                        </div>
                                                    </template>
                                                    <v-tooltip bottom>
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-icon
                                                                v-if="item.icon"
                                                                v-bind="attrs"
                                                                :size="item.iconSize"
                                                                v-on="on"
                                                                :color="item.colorIcon"
                                                            >
                                                                {{ item.icon }}
                                                            </v-icon>
                                                            <v-img
                                                                v-if="item.img"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                :src="item.img"
                                                                :width="item.iconSize"
                                                                :style="item.style"
                                                            ></v-img>
                                                        </template>
                                                        <span class="text"> {{ item.title }}</span>
                                                    </v-tooltip>
                                                </v-badge>
                                            </v-col>
                                            <v-col cols="auto" v-if="layoutTransitionLgToMd" class="mt-n1 ">
                                                <v-menu offset-y transition="slide-x-reverse-transition" width="250px">
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-btn icon v-bind="attrs" v-on="on">
                                                            <v-icon>more_vert</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item>
                                                            <v-btn plain @click="shareTripts">
                                                                <v-icon left>
                                                                    share
                                                                </v-icon>
                                                                <span>{{ $t("headers.share_trip") }}</span>
                                                            </v-btn>
                                                        </v-list-item>
                                                        <v-list-item>
                                                            <v-btn
                                                                v-if="!trip.node.isUserFavorite"
                                                                plain
                                                                @click.stop="favoriteBtn(trip.node.id, true)"
                                                            >
                                                                <v-icon left>
                                                                    star
                                                                </v-icon>
                                                                <span>{{ $t("general.favorites") }}</span>
                                                            </v-btn>
                                                            <v-btn
                                                                v-else
                                                                plain
                                                                @click.stop="favoriteBtn(trip.node.id, false)"
                                                            >
                                                                <v-icon left>
                                                                    playlist_remove
                                                                </v-icon>
                                                                <span>{{ $t("general.unfavorite") }}</span>
                                                            </v-btn>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    <v-col ref="tripInfoCard" cols="12" xs="12" sm="12" md="" lg="" xl="">
                                        <!--? Section 2 - Trip Info and Freight -->
                                        <v-row class="py-1 " ref="tripInfo">
                                            <!-- Trip Info -->
                                            <v-col
                                                v-for="(item, idxc) in mainCard(trip)"
                                                :key="idxc"
                                                :cols="item.col.xs"
                                                :xl="item.col.xl"
                                                :lg="item.col.lg"
                                                :md="item.col.md"
                                                :sm="item.col.sm"
                                                :xs="item.col.xs"
                                                :class="
                                                    !layoutTransitionLgToMd
                                                        ? 'd-flex align-top px-4 flex-column'
                                                        : 'd-flex align-top px-6 flex-column'
                                                "
                                            >
                                                <div v-if="item.title" class="classHeader">
                                                    {{ item.title }}
                                                </div>
                                                <div class="textTruncate">
                                                    <div :style="item.style">
                                                        <v-icon
                                                            v-if="item.icon"
                                                            :size="item.iconSize"
                                                            :color="item.colorIcon"
                                                            >{{ item.icon }}</v-icon
                                                        >
                                                        <div v-if="item.value && item.tag !== 'consignee'">
                                                            <span
                                                                v-if="item.value.length < 2"
                                                                class="classText text-uppercase"
                                                                @click.stop.prevent
                                                                style="user-select: text;"
                                                            >
                                                                {{ item.value }}
                                                            </span>
                                                            <v-tooltip bottom v-else>
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <span v-bind="attrs" v-on="on" class="classText">
                                                                        {{ item.value }}
                                                                    </span>
                                                                </template>
                                                                <span
                                                                    class="class-text-tooltip"
                                                                    @click.stop.prevent
                                                                    style="user-select: text;"
                                                                >
                                                                    {{ item.value }}</span
                                                                >
                                                            </v-tooltip>
                                                        </div>
                                                        <div v-else>
                                                            <div
                                                                v-for="(consignee, id) in item.value"
                                                                :key="id"
                                                                class="d-flex align-left justify-start "
                                                            >
                                                                <v-tooltip bottom>
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs
                                                                        }"
                                                                    >
                                                                        <span
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            class="classText d-flex justify-start align-left text-uppercase"
                                                                        >
                                                                            {{ consignee.node.tenantPartner.name }}
                                                                        </span>
                                                                    </template>
                                                                    <span
                                                                        class="class-text-tooltip text-uppercase"
                                                                        @click.stop.prevent
                                                                        style="user-select: text;"
                                                                    >
                                                                        {{ consignee.node.tenantPartner.name }}
                                                                    </span>
                                                                </v-tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <!--? Aditional Info about trip -->
                                        <v-row class="mb-2">
                                            <v-col
                                                v-for="(info, i) in infoCard(trip)"
                                                :key="i"
                                                :cols="info.col.xs"
                                                :xl="info.col.xl"
                                                :lg="info.col.lg"
                                                :md="info.col.md"
                                                :sm="info.col.sm"
                                                :xs="info.col.xs"
                                                :class="
                                                    !layoutTransitionLgToMd
                                                        ? 'd-flex align-top px-4 flex-column'
                                                        : 'd-flex align-top px-6 flex-column'
                                                "
                                            >
                                                <div class="classHeader">
                                                    {{ info.title }}
                                                </div>
                                                <div class="textTruncate">
                                                    <div :style="info.style">
                                                        <v-tooltip bottom v-if="info.tooltip">
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs
                                                                }"
                                                            >
                                                                <table
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    class="classText"
                                                                    style="width: 100%;"
                                                                >
                                                                    <colgroup>
                                                                        <col style="width: 60%;" />
                                                                        <col style="width: 10%;" />
                                                                        <col style="width: 30%;" />
                                                                    </colgroup>
                                                                    <tr style="text-align: left;">
                                                                        <th class="mr-3">
                                                                            {{ info.value }}
                                                                        </th>
                                                                        <v-card
                                                                            elevation="0"
                                                                            outlined
                                                                            width="16px"
                                                                            color="transparent"
                                                                        ></v-card>
                                                                        <td
                                                                            :class="info.delayColor"
                                                                            v-if="info.delay && info.delay !== '0'"
                                                                        >
                                                                            {{ info.tooltip.title }}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </template>
                                                            <v-row>
                                                                <v-col cols="12">
                                                                    <table style="width: 100%;">
                                                                        <thead style="text-align:center;">
                                                                            <tr>
                                                                                <th colspan="2">
                                                                                    {{ info.tooltip.title }}
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr class="mb-1">
                                                                                <th class="classHeader2">
                                                                                    {{ info.tooltip.headerOET }}
                                                                                </th>
                                                                                <td class="classText2">
                                                                                    {{ info.tooltip.OET }}
                                                                                </td>
                                                                            </tr>
                                                                            <tr class="mb-1">
                                                                                <th class="classHeader2">
                                                                                    {{ info.tooltip.headerET }}
                                                                                </th>
                                                                                <td class="classText2">
                                                                                    {{ info.tooltip.ET }}
                                                                                </td>
                                                                            </tr>
                                                                            <tr class="mb-1">
                                                                                <th class="classHeader2">
                                                                                    {{ info.tooltip.headerACT }}
                                                                                </th>
                                                                                <td class="classText2">
                                                                                    {{ info.tooltip.ACT }}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </v-col>
                                                            </v-row>
                                                        </v-tooltip>
                                                        <div v-else-if="isArray(info.value)">
                                                            <div
                                                                v-for="(el, j) in info.value"
                                                                :key="j"
                                                                class="d-flex align-left justify-start "
                                                            >
                                                                <v-tooltip bottom>
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs
                                                                        }"
                                                                    >
                                                                        <span
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            class="classText d-flex justify-start align-left text-uppercase"
                                                                        >
                                                                            {{ el }}
                                                                        </span>
                                                                    </template>
                                                                    <span
                                                                        class="class-text-tooltip text-uppercase"
                                                                        @click.stop.prevent
                                                                        style="user-select: text;"
                                                                    >
                                                                        {{ el }}
                                                                    </span>
                                                                </v-tooltip>
                                                            </div>
                                                        </div>
                                                        <span
                                                            v-else
                                                            class="classText text-uppercase"
                                                            @click.stop.prevent
                                                            style="user-select: text;"
                                                        >
                                                            {{ info.value }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <!--? Freight Info -->
                                        <v-row
                                            ref="freightInfo"
                                            v-for="(freight, ids) in trip.node.tripFreightSet.edges"
                                            :key="ids"
                                            class="rounded-lg outlineD backgroundColor mb-2"
                                        >
                                            <v-col
                                                v-for="(item, idv) in secondaryCard(freight, trip.node.transportMode)"
                                                :key="idv"
                                                :cols="item.col.xs"
                                                :xl="item.col.xl"
                                                :lg="item.col.lg"
                                                :md="item.col.md"
                                                :sm="item.col.sm"
                                                :xs="item.col.xs"
                                                :class="
                                                    !layoutTransitionLgToMd
                                                        ? 'd-flex align-top px-4 flex-column'
                                                        : 'd-flex align-top px-6 flex-column'
                                                "
                                            >
                                                <div class="textTruncate">
                                                    <div class="classHeader">
                                                        {{ item.title }}
                                                    </div>
                                                    <div :style="item.style" @click.stop>
                                                        <v-icon
                                                            v-if="item.icon"
                                                            :size="item.iconSize"
                                                            class="mr-1"
                                                            :color="item.colorIcon"
                                                            >{{ item.icon }}</v-icon
                                                        >
                                                        <div v-if="item.value && item.tag !== 'tracker'">
                                                            <span
                                                                v-if="item.value.length < 20"
                                                                class="classText text-uppercas"
                                                                @click.stop.prevent
                                                                style="user-select: text;"
                                                            >
                                                                {{ item.value }}</span
                                                            >
                                                            <v-tooltip bottom v-else>
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <span
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        class="classText text-uppercase"
                                                                        @click.stop
                                                                    >
                                                                        {{ item.value }}
                                                                    </span>
                                                                </template>
                                                                <span
                                                                    class="class-text-tooltip"
                                                                    @click.stop.prevent
                                                                    style="user-select: text;"
                                                                >
                                                                    {{ item.value }}
                                                                </span>
                                                            </v-tooltip>
                                                        </div>
                                                        <div v-else>
                                                            <div
                                                                v-for="(tracker, idv) in item.value"
                                                                :key="idv"
                                                                class="d-flex justify-start flex-column"
                                                            >
                                                                <v-tooltip bottom>
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs
                                                                        }"
                                                                    >
                                                                        <span
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            class="classText d-flex justify-start align-left text-uppercase"
                                                                        >
                                                                            {{ tracker.serial }}
                                                                            <v-icon
                                                                                size="18"
                                                                                color="primary"
                                                                                @click.stop="
                                                                                    copyToClipboard(tracker.serial)
                                                                                "
                                                                                class="ml-1"
                                                                                >content_copy</v-icon
                                                                            >
                                                                        </span>
                                                                    </template>
                                                                    <span
                                                                        class="d-flex align-left justify-start class-text-tooltip"
                                                                    >
                                                                        {{ tracker.text }} {{ tracker.activatedDate }}
                                                                    </span>
                                                                </v-tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-card class="mt-3" flat color="transparent" v-if="!layoutTransitionLgToMd">
                                        <!--? Action Menu -->
                                        <v-menu
                                            offset-y
                                            transition="slide-x-reverse-transition"
                                            width="250px"
                                            attach
                                            left
                                            bottom
                                            close-on-click
                                            nudge-top="-3"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-btn icon v-bind="attrs" v-on="on">
                                                    <v-icon>more_vert</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list class="white">
                                                <v-list-item>
                                                    <v-btn plain @click="shareTripts">
                                                        <v-icon left>
                                                            share
                                                        </v-icon>
                                                        <span>{{ $t("headers.share_trip") }}</span>
                                                    </v-btn>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-btn
                                                        v-if="!trip.node.isUserFavorite"
                                                        plain
                                                        @click.stop="favoriteBtn(trip.node.id, true)"
                                                    >
                                                        <v-icon left>
                                                            star
                                                        </v-icon>
                                                        <span>{{ $t("general.favorites") }}</span>
                                                    </v-btn>
                                                    <v-btn v-else plain @click.stop="favoriteBtn(trip.node.id, false)">
                                                        <v-icon left>
                                                            playlist_remove
                                                        </v-icon>
                                                        <span>{{ $t("general.unfavorite") }}</span>
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-card>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div v-else>
                        <v-row class="d-flex align-center justify-center">
                            <v-col cols="4" class="text-center">
                                <v-img class="mt-3" :src="require('@/assets/empty_data_small.png')"></v-img>
                                <v-card flat color="transparent">
                                    <v-card-title>
                                        <div class="textTitle" v-if="tab === 0">
                                            {{ $t("general.empty_data_title") }}
                                        </div>
                                        <div class="textTitle" v-else>
                                            {{ $t("general.empty_data_title_favorite") }}
                                        </div>
                                    </v-card-title>
                                    <v-card-text class="px-0">
                                        <div class="textSubtitle" v-if="tab === 0">
                                            {{ $t("general.empty_data_subtitle") }}
                                        </div>
                                        <div v-else>
                                            <div class="textSubtitle">
                                                {{ $t("general.step") }} 1:
                                                {{ $t("general.empty_data_subtitle_favorite_1") }}
                                                <v-icon>more_vert</v-icon>
                                            </div>
                                            <div class="textSubtitle">
                                                {{ $t("general.step") }} 2:
                                                {{ $t("general.empty_data_subtitle_favorite_2") }}
                                                <v-icon>star_border</v-icon>
                                                {{ $t("general.favorites") }}
                                            </div>
                                        </div>
                                    </v-card-text>
                                    <v-card-actions class="px-10" v-if="!isSharedTrip">
                                        <v-btn color="primary" @click="createTrip()" block>
                                            {{ $t("trips.create_trip_2") }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="justify-center mb-5">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.trips.loading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                Load more
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import helper from "../../utils/helper";
import LoadingBar from "@/components/base/LoadingBar.vue";
import SortBy from "@/components/base/SortBy.vue";

const GET_TRIPS = gql`
    query trips(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $referenceNumber_Icontains: String
        $tripFreightSet_ContainerNumber_Icontains: String
        $tripFreightSet_AirWaybillNumber_Icontains: String
        $tripFreightSet_OrderNumber_Icontains: String
        $tripFreightSet_Contact_Icontains: String
        $tenantPartnerId: ID
        $status_In: [String]
        $plannedDepartureDate_Gte: DateTime
        $plannedDepartureDate_Lte: DateTime
        $plannedArrivalDate_Gte: DateTime
        $plannedArrivalDate_Lte: DateTime
        $orderBy: String
        $pol: String
        $transshipment: String
        $transportMode_In: [String]
        $pod: String
        $alertCode: String
        $tripAlertSetIsResolved: Boolean
        $tenantCarrier: ID
        $consignor: [String]
        $consignee: [String]
        $forwarder: [String]
        $goods: String
        $isFavorite: Boolean
        $tripFreightSet_ReferenceNumber_Icontains: String
        $polvessel: ID
        $tsvessel: ID
        $trackerLastLocation_Icontains: String
        $search: String
        $trackerSerialNumber: String
        $alertTag: String
        $trade_Icontains: String
        $awbNumber: String
        $containerNumber: String
        $trackerNumber: String
        $blNumber: String
        $orderNumber: String
    ) {
        trips(
            first: $first
            last: $last
            before: $before
            after: $after
            referenceNumber_Icontains: $referenceNumber_Icontains
            tripFreightSet_ContainerNumber_Icontains: $tripFreightSet_ContainerNumber_Icontains
            tripFreightSet_AirWaybillNumber_Icontains: $tripFreightSet_AirWaybillNumber_Icontains
            tripFreightSet_OrderNumber_Icontains: $tripFreightSet_OrderNumber_Icontains
            tripFreightSet_Contact_Icontains: $tripFreightSet_Contact_Icontains
            tripPartnerSet_TenantPartner: $tenantPartnerId
            status_In: $status_In
            plannedDepartureDate_Gte: $plannedDepartureDate_Gte
            plannedDepartureDate_Lte: $plannedDepartureDate_Lte
            plannedArrivalDate_Gte: $plannedArrivalDate_Gte
            plannedArrivalDate_Lte: $plannedArrivalDate_Lte
            orderBy: $orderBy
            pol: $pol
            transshipment: $transshipment
            transportMode_In: $transportMode_In
            alertCode: $alertCode
            tripAlertSet_IsResolved: $tripAlertSetIsResolved
            pod: $pod
            tenantCarrier: $tenantCarrier
            consignor: $consignor
            consignee: $consignee
            forwarder: $forwarder
            goods: $goods
            tripFreightSet_ReferenceNumber_Icontains: $tripFreightSet_ReferenceNumber_Icontains
            isFavorite: $isFavorite
            polvessel: $polvessel
            tsvessel: $tsvessel
            trackerLastLocation_Icontains: $trackerLastLocation_Icontains
            search: $search
            trackerSerialNumber: $trackerSerialNumber
            alertTag: $alertTag
            trade_Icontains: $trade_Icontains
            awbNumber: $awbNumber
            containerNumber: $containerNumber
            trackerNumber: $trackerNumber
            blNumber: $blNumber
            orderNumber: $orderNumber
        ) {
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
            edges {
                node {
                    id
                    createdAt
                    tenantCarrier {
                        id
                        name
                    }
                    originLocation {
                        id
                        name
                        city
                        country
                    }
                    destinationLocation {
                        id
                        name
                        city
                        country
                    }
                    referenceNumber
                    transportMode
                    status
                    plannedDepartureDate
                    numberOfFreights
                    numberOfUnresolvedAlerts
                    numberOfDocuments
                    trackerLastConnectionTime
                    trackerLastLocation
                    trackerLastTemperatureValue
                    tripPartnerSet {
                        edges {
                            node {
                                id
                                tenantPartner {
                                    id
                                    name
                                    partnerType
                                }
                            }
                        }
                    }
                    tripFreightSet {
                        edges {
                            node {
                                id
                                referenceNumber
                                orderNumber
                                containerNumber
                                airWaybillNumber
                                billOfLadingNumber
                                goods
                                additionalInfo
                                tripFreightTrackerSet {
                                    edges {
                                        node {
                                            id
                                            tracker {
                                                id
                                                serialNumber
                                                activatedDate
                                                model
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    tripSeaTransportSet(orderBy: "sequence") {
                        edges {
                            node {
                                id
                                sequence
                                portCode
                                portTransportType
                                portName
                                vessel {
                                    id
                                    name
                                }
                                estimatedArrivalDate
                                originalEstimatedArrivalDate
                                actualArrivalDate
                                originalEstimatedDepartureDate
                                estimatedDepartureDate
                                actualDepartureDate
                            }
                        }
                    }
                    isUserFavorite
                }
            }
        }
    }
`;

const GET_SHARED_TRIPS = gql`
    query trips(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $referenceNumber_Icontains: String
        $tripFreightSet_ContainerNumber_Icontains: String
        $tripFreightSet_AirWaybillNumber_Icontains: String
        $tripFreightSet_OrderNumber_Icontains: String
        $tripFreightSet_Contact_Icontains: String
        $tenantPartnerId: ID
        $status_In: [String]
        $plannedDepartureDate_Gte: DateTime
        $plannedDepartureDate_Lte: DateTime
        $plannedArrivalDate_Gte: DateTime
        $plannedArrivalDate_Lte: DateTime
        $orderBy: String
        $pol: String
        $transshipment: String
        $transportMode_In: [String]
        $pod: String
        $alertCode: String
        $tripAlertSetIsResolved: Boolean
        $tenantCarrier: ID
        $consignor: [String]
        $consignee: [String]
        $forwarder: [String]
        $goods: String
        $isFavorite: Boolean
        $tripFreightSet_ReferenceNumber_Icontains: String
        $polvessel: ID
        $tsvessel: ID
        $trackerLastLocation_Icontains: String
        $tenant: String
        $sharedtrips: String
        $userFavoriteTripSetIdIsnull: Boolean
        $userId: ID
        $trackerSerialNumber: String
        $alertTag: String
        $trade_Icontains: String
        $awbNumber: String
        $containerNumber: String
        $trackerNumber: String
        $blNumber: String
        $orderNumber: String
        $search: String
    ) {
        trips(
            first: $first
            last: $last
            before: $before
            after: $after
            referenceNumber_Icontains: $referenceNumber_Icontains
            tripFreightSet_ContainerNumber_Icontains: $tripFreightSet_ContainerNumber_Icontains
            tripFreightSet_AirWaybillNumber_Icontains: $tripFreightSet_AirWaybillNumber_Icontains
            tripFreightSet_OrderNumber_Icontains: $tripFreightSet_OrderNumber_Icontains
            tripFreightSet_Contact_Icontains: $tripFreightSet_Contact_Icontains
            tripPartnerSet_TenantPartner: $tenantPartnerId
            status_In: $status_In
            plannedDepartureDate_Gte: $plannedDepartureDate_Gte
            plannedDepartureDate_Lte: $plannedDepartureDate_Lte
            plannedArrivalDate_Gte: $plannedArrivalDate_Gte
            plannedArrivalDate_Lte: $plannedArrivalDate_Lte
            orderBy: $orderBy
            pol: $pol
            transshipment: $transshipment
            transportMode_In: $transportMode_In
            alertCode: $alertCode
            tripAlertSet_IsResolved: $tripAlertSetIsResolved
            pod: $pod
            tenantCarrier: $tenantCarrier
            consignor: $consignor
            consignee: $consignee
            forwarder: $forwarder
            goods: $goods
            tripFreightSet_ReferenceNumber_Icontains: $tripFreightSet_ReferenceNumber_Icontains
            isFavorite: $isFavorite
            polvessel: $polvessel
            tsvessel: $tsvessel
            trackerLastLocation_Icontains: $trackerLastLocation_Icontains
            tenant_Name_Icontains: $tenant
            sharedtrips: $sharedtrips
            userFavoriteTripSet_Id_Isnull: $userFavoriteTripSetIdIsnull
            trackerSerialNumber: $trackerSerialNumber
            alertTag: $alertTag
            trade_Icontains: $trade_Icontains
            awbNumber: $awbNumber
            containerNumber: $containerNumber
            trackerNumber: $trackerNumber
            blNumber: $blNumber
            orderNumber: $orderNumber
            search: $search
        ) {
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
            edges {
                node {
                    id
                    createdAt
                    tenant {
                        id
                        name
                        organisationPermissions
                    }
                    tenantCarrier {
                        id
                        name
                    }
                    modifiedAt
                    originLocation {
                        id
                        name
                        city
                        country
                    }
                    destinationLocation {
                        id
                        name
                        city
                        country
                    }
                    referenceNumber
                    transportMode
                    status
                    plannedDepartureDate
                    numberOfUnresolvedAlerts
                    numberOfDocuments
                    trackerLastConnectionTime
                    trackerLastLocation
                    tripPartnerSet {
                        edges {
                            node {
                                id
                                tenantPartner {
                                    id
                                    name
                                    partnerType
                                    organisationPermissions
                                    tenantPartnerUserSet(user: $userId) {
                                        totalCount
                                    }
                                }
                            }
                        }
                    }
                    tripFreightSet {
                        edges {
                            node {
                                id
                                referenceNumber
                                containerNumber
                                airWaybillNumber
                                billOfLadingNumber
                                goods
                                additionalInfo
                                tripFreightTrackerSet {
                                    edges {
                                        node {
                                            id
                                            tracker {
                                                id
                                                serialNumber
                                                activatedDate
                                                model
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    tripSeaTransportSet(orderBy: "sequence") {
                        edges {
                            node {
                                id
                                sequence
                                portCode
                                portTransportType
                                vesselInputType
                                vesselInputValue
                                estimatedArrivalDate
                                originalEstimatedArrivalDate
                                actualArrivalDate
                                estimatedDepartureDate
                                originalEstimatedDepartureDate
                                actualDepartureDate
                                vessel {
                                    id
                                    name
                                }
                            }
                        }
                    }
                    isUserFavorite
                }
            }
        }
    }
`;

export default {
    props: {
        tripFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        tab: {
            type: String,
            required: false,
            default: "all"
        }
    },
    components: {
        LoadingBar,
        SortBy
    },
    apollo: {
        trips: {
            query() {
                return this.isSharedTrip ? GET_SHARED_TRIPS : GET_TRIPS;
            },
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                let request = {
                    ...this.tripFilter
                };

                if (this.isSharedTrip) {
                    request = {
                        ...request,
                        sharedtrips: "sharedtrips",
                        userId: this.me.id
                    };
                }
                if (request?.tripFreightSet_AirWaybillNumber_Icontains) {
                    request.tripFreightSet_AirWaybillNumber_Icontains = this.removeHifenFromAirwaybill(
                        request.tripFreightSet_AirWaybillNumber_Icontains
                    );
                }

                if (this.tab === "favorite") {
                    request.isFavorite = true;
                } else {
                    request.isFavorite = null;
                }

                return request;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trips,
            watchLoading(isLoading) {
                this.tripIsLoading = isLoading;
                this.$emit("loading", isLoading);
            },
            debounce: 800
        }
    },
    beforeCreate() {
        this.formatAirwaybillNumber = helper.formatAirwaybillNumber;
        this.removeHifenFromAirwaybill = helper.removeHifenFromAirwaybill;
    },
    created() {
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.calculateDelaysInDay = helper.calculateDelaysInDay;
        this.formatDateTime = helper.formatDateTime;
        this.formatTemperature = helper.formatTemperature;
    },
    data() {
        return {
            tableFilter: {
                first: 10,
                status_In: "active",
                orderBy: "-createdAt",
                isFavorite: null
            },
            infiniteDot: "",
            showAddNewTripPage: false,
            shouldLoadMore: false,
            isInitialLoad: true,
            trips: { edges: [] },
            tripIsLoading: true,
            tableHeight: 800,
            tableHeaderChoices: [],
            tableHeaderFilter: {
                referenceNumber: true,
                status: true,
                containers: true,
                alerts: true
            },
            storeFilter: {},
            sortBy: null,
            sortAsc: true,
            menu: false
        };
    },
    computed: {
        hasTrips() {
            return this.trips.edges.length > 0;
        },
        layoutTransitionLgToMd() {
            return this.$vuetify.breakpoint.width <= 1100;
        },
        hideToMobileScreen() {
            return !(this.$vuetify.breakpoint.width < 600);
        },
        screenWidth() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            return _resposiveView;
        },
        me() {
            return helper.me();
        },
        domIsAlreadyRendered() {
            return (
                this.$refs.tripInfoCard !== undefined &&
                this.$refs.tripInfoCard !== null &&
                this.$refs.freightInfo !== undefined &&
                this.$refs.freightInfo !== null
            );
        },
        sortByChoices: function() {
            let _sortByChoices = [
                {
                    code: "referenceNumber",
                    name: this.$t("headers.reference_number")
                },
                {
                    code: "status",
                    name: this.$t("headers.status")
                },
                {
                    code: "createdAt",
                    name: this.$t("headers.created_at")
                },
                {
                    code: "modifiedAt",
                    name: this.$t("headers.modified_at")
                },
                {
                    code: "plannedDepartureDate",
                    name: this.$t("headers.planned_departure")
                },
                {
                    code: "consignor",
                    name: this.$t("headers.consignor")
                },
                {
                    code: "consignee",
                    name: this.$t("headers.consignee")
                },
                {
                    code: "forwarder",
                    name: this.$t("headers.forwarder")
                },
                {
                    code: "tenant_carrier",
                    name: this.$t("headers.carrier")
                }
            ];
            return _sortByChoices;
        },
        centralizeLoadBtnClass() {
            let _centralizeLoadBtnClass = {
                position: "sticky",
                left: "50%"
            };
            return _centralizeLoadBtnClass;
        },
        hasMoreData() {
            return this.trips?.pageInfo?.hasNextPage;
        },
        showAll: {
            get() {
                return this.tripFilter?.status_In === null ? false : true;
            },
            set(val) {
                if (val) {
                    this.tripFilter.status_In = "active";
                } else {
                    this.tripFilter.status_In = null;
                }
            }
        },
        isTripsRoute() {
            let route = this.$route;
            return route.name == "trips" && route?.query?.id !== null;
        },
        switchTableHeaders() {
            return this.isSharedTrip ? "sharedTripTableHeaders" : "tripTableHeaders";
        }
    },
    watch: {
        tripFilter: {
            handler(newValue, oldValue) {
                let newValueString = JSON.parse(JSON.stringify(newValue));
                let oldValueString = JSON.parse(JSON.stringify(oldValue));

                if (newValueString !== oldValueString) {
                    this.tripIsLoading = true;
                    this.trips = { edges: [] };
                }
            },
            deep: true
        }
    },
    mounted() {},
    methods: {
        updateTableFilter(event) {
            let _filter = { ...this.tripFilter };
            helper.setFilterValues("myTripTableFilters", _filter);
        },
        copyToClipboard(text) {
            let dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            const payload = {
                color: "primary",
                message: this.$t("general.copied")
            };
            this.$store.dispatch("snackbar/showMessage", payload);
        },
        transport(item) {
            const { status, transportMode } = item.node;

            const iconRotate = mode => {
                return mode && mode === "AIR" ? "transform: rotate(45deg);" : "";
            };
            const statusColor = status => {
                return status === "ACTIVE" ? "#4CAF50" : "";
            };

            return {
                title: null,
                tag: "icon",
                icon: this.getIcon(transportMode),
                iconSize: 20,
                colorIcon: statusColor(status),
                style: iconRotate(transportMode),
                align: "left",
                value: null,
                col: {
                    xs: "auto",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                    xl: "auto"
                }
            };
        },
        mainCard(item) {
            let { referenceNumber, plannedDepartureDate, tenantCarrier, tripPartnerSet } = item.node;
            if (item.node.transportMode === "SEA") {
                let { tripSeaTransportSet } = item.node;
                if (!plannedDepartureDate) {
                    let polNode = tripSeaTransportSet.edges[0]?.node;
                    plannedDepartureDate = polNode?.originalEstimatedDepartureDate ?? polNode?.estimatedDepartureDate;
                }
            }
            /* block code */
            const containerStyle = tag => {
                return `background-color: ${
                    this.statusColor(tag).color1
                }; border-radius: 4px; width: 70px; display: flex; justify-content: center; align-items: center;`;
            };

            const formatDate = date => {
                return date ? this.formatDateTime(date) : "";
            };

            const filterConsigneePartner = tripPartnerSet.edges.filter(
                ({ node }) => node.tenantPartner.partnerType === "consignee"
            );

            let _mainCard = [
                {
                    title: this.$t("headers.reference_number"),
                    tag: "referenceNumber",
                    icon: null,
                    iconSize: 8,
                    colorIcon: null,
                    style: "",
                    align: "left",
                    value: referenceNumber,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: this.screenWidth < 1295 ? 4 : 2,
                        xl: 2
                    }
                },
                {
                    title: this.$t("headers.consignee"),
                    tag: "consignee",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: filterConsigneePartner,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: this.screenWidth < 1295 ? 4 : 2,
                        xl: 2
                    }
                },
                {
                    title: this.$t("headers.planned_departure"),
                    tag: "plannedDepartureDate",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: formatDate(plannedDepartureDate),
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: this.screenWidth < 1295 ? 4 : 2,
                        xl: 2
                    }
                },
                {
                    title: this.$t("headers.origin"),
                    tag: "origin",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: this.getOrigin(item.node),
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: this.screenWidth < 1295 ? 4 : 2,
                        xl: 2
                    }
                },
                {
                    title: this.$t("headers.destination"),
                    tag: "destination",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: this.getDestination(item.node),
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: this.screenWidth < 1295 ? 4 : 2,
                        xl: 2
                    }
                },
                {
                    title: this.$t("headers.carrier"),
                    tag: "carrier",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: tenantCarrier?.name,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: this.screenWidth < 1295 ? 4 : 2,
                        xl: 2
                    }
                }
            ];

            return _mainCard;
        },
        secondaryCard(item, transportMode) {
            /* validation */
            if (!item || !item.node) return [];
            const {
                referenceNumber,
                orderNumber,
                containerNumber,
                airWaybillNumber,
                billOfLadingNumber,
                goods,
                additionalInfo
            } = item.node;

            /* scope */
            let _secondaryCard = [];
            _secondaryCard.push(
                {
                    title: this.$t("headers.freight_ref_number"),
                    tag: "freightRefNumber",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: referenceNumber,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: this.screenWidth < 1295 ? 4 : 2,
                        xl: 2
                    }
                },
                {
                    title: this.$t("headers.order_number"),
                    tag: "orderNumber",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: orderNumber,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: this.screenWidth < 1295 ? 4 : 2,
                        xl: 2
                    }
                },
                {
                    title: this.$t("headers.tracker"),
                    tag: "tracker",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: this.trackerSerie(item.node),
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: this.screenWidth < 1295 ? 4 : 2,
                        xl: 2
                    }
                },
                {
                    title: this.$t("headers.goods"),
                    tag: "goods",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: goods,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: this.screenWidth < 1295 ? 4 : 2,
                        xl: 2
                    }
                }
            );

            if (transportMode === "SEA") {
                _secondaryCard.splice(
                    2,
                    0,
                    {
                        title: this.$t("headers.container_number"),
                        tag: "containerNumber",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: containerNumber,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: this.screenWidth < 1295 ? 4 : 2,
                            xl: 2
                        }
                    },
                    {
                        title: this.$t("headers.bill"),
                        tag: "billOfLadingNumber",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: billOfLadingNumber,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: this.screenWidth < 1295 ? 4 : 2,
                            xl: 2
                        }
                    }
                );
            }
            if (transportMode === "AIR") {
                _secondaryCard.splice(2, 0, {
                    title: this.$t("headers.airwaybill_number"),
                    tag: "airwaybillNumber",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: airWaybillNumber,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: this.screenWidth < 1295 ? 4 : 2,
                        xl: 2
                    }
                });
            }
            return _secondaryCard;
        },
        infoCard(item) {
            const { tripSeaTransportSet, tripPartnerSet, transportMode } = item.node;
            let _infoCard = [];
            let _vessels = [];

            if (transportMode === "SEA") {
                _infoCard.push(
                    {
                        id: 0,
                        title: this.$t("others.pol"),
                        tag: "pol",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: null,
                        delay: null,
                        delayColor: null,
                        tooltip: null,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: this.screenWidth < 1295 ? 4 : 2,
                            xl: 2
                        }
                    },
                    {
                        id: 1,
                        title: this.$t("others.ts"),
                        tag: "transshipment",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: null,
                        delay: null,
                        delayColor: null,
                        tooltip: null,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: this.screenWidth < 1295 ? 4 : 2,
                            xl: 2
                        }
                    },
                    {
                        id: 2,
                        title: this.$t("others.pod"),
                        tag: "pod",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: null,
                        delay: null,
                        delayColor: null,
                        tooltip: null,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: this.screenWidth < 1295 ? 4 : 2,
                            xl: 2
                        }
                    },
                    {
                        id: 3,
                        title: this.$t("headers.vessel"),
                        tag: "vessel",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: null,
                        delay: null,
                        delayColor: "",
                        tooltip: null,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: this.screenWidth < 1295 ? 4 : 2,
                            xl: 2
                        }
                    }
                );

                tripSeaTransportSet.edges.forEach(({ node }) => {
                    let _portName = node.portName;
                    let _portCode = node.portCode;
                    let _portType = node.portTransportType;
                    let _estimatedArrivalDate = node.estimatedArrivalDate;
                    let _originalEstimatedArrivalDate = node.originalEstimatedArrivalDate;
                    let _actualArrivalDate = node.actualArrivalDate;
                    let _originalEstimatedDepartureDate = node.originalEstimatedDepartureDate;
                    let _estimatedDepartureDate = node.estimatedDepartureDate;
                    let _actualDepartureDate = node.actualDepartureDate;

                    if (node.vessel) {
                        if (!_vessels.includes(node.vessel.name)) _vessels.push(node.vessel.name);
                    }

                    if (_portType === "POL") {
                        const { offset, event, color, text } = this.getDelay(node, "etd");
                        //populate infoCard
                        _infoCard[0].value = _portCode;
                        _infoCard[0].delay = offset;
                        _infoCard[0].delayColor = color;
                        _infoCard[0].tooltip = {
                            title: text,
                            titleColor: color,
                            headerOET: this.$t("trips.original_estimated_departure"),
                            OET: this.formatDateTime(_originalEstimatedDepartureDate),
                            headerET: this.$t("trips.estimated_departure"),
                            ET: this.formatDateTime(_estimatedDepartureDate),
                            headerACT: this.$t("trips.actual_departure"),
                            ACT: this.formatDateTime(_actualDepartureDate)
                        };
                    }

                    if (_portType === "T/S") {
                        const { offset, event, color, text } = this.getDelay(node, "etd");

                        _infoCard[1].value = _portCode;
                        _infoCard[1].delay = offset;
                        _infoCard[1].delayColor = color;
                        _infoCard[1].tooltip = {
                            title: text,
                            titleColor: color,
                            headerOET: this.$t("trips.original_estimated_departure"),
                            OET: this.formatDateTime(_originalEstimatedDepartureDate),
                            headerET: this.$t("trips.estimated_departure"),
                            ET: this.formatDateTime(_estimatedDepartureDate),
                            headerACT: this.$t("trips.actual_departure"),
                            ACT: this.formatDateTime(_actualDepartureDate)
                        };
                    }

                    if (_portType === "POD") {
                        const { offset, event, color, text } = this.getDelay(node, "eta");

                        _infoCard[2].value = _portCode;
                        _infoCard[2].delay = offset;
                        _infoCard[2].delayColor = color;
                        _infoCard[2].tooltip = {
                            title: text,
                            titleColor: color,
                            headerOET: this.$t("trips.original_estimated_arrival"),
                            OET: this.formatDateTime(_originalEstimatedArrivalDate),
                            headerET: this.$t("trips.estimated_arrival"),
                            ET: this.formatDateTime(_estimatedArrivalDate),
                            headerACT: this.$t("trips.actual_arrival"),
                            ACT: this.formatDateTime(_actualArrivalDate)
                        };
                    }
                });

                /* Vessel */
                if (_vessels.length > 0) {
                    _infoCard[3].value = _vessels;
                }
            }
            /* Partners show partner name  */
            const _consignor = tripPartnerSet.edges
                .filter(({ node }) => node.tenantPartner.partnerType === "consignor")
                .map(({ node }) => node.tenantPartner.name);

            const _forwarder = tripPartnerSet.edges
                .filter(({ node }) => node.tenantPartner.partnerType === "forwarder")
                .map(({ node }) => node.tenantPartner.name);

            _infoCard.push(
                {
                    id: 4,
                    title: this.$t("headers.consignor"),
                    tag: "consignor",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: _consignor,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: this.screenWidth < 1295 ? 4 : 2,
                        xl: 2
                    }
                },
                {
                    id: 5,
                    title: this.$t("headers.forwarder"),
                    tag: "forwarder",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: _forwarder,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: this.screenWidth < 1295 ? 4 : 2,
                        xl: 2
                    }
                }
            );

            if (transportMode == "AIR") {
                _infoCard.push(
                    {
                        id: 6,
                        title: this.$t("headers.last_connection"),
                        tag: "lastConnection",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: item.node.trackerLastConnectionTime
                            ? this.formatDateTime(item.node.trackerLastConnectionTime)
                            : "",
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: this.screenWidth < 1295 ? 4 : 2,
                            xl: 2
                        }
                    },
                    {
                        id: 7,
                        title: this.$t("headers.trackerLastTemperature"),
                        tag: "lastTemperature",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: item.node.trackerLastTemperatureValue
                            ? `${this.formatTemperature(item.node.trackerLastTemperatureValue).toFixed(
                                  2
                              )} ${this.getTemperatureSymbol()}`
                            : "",
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: this.screenWidth < 1295 ? 4 : 2,
                            xl: 2
                        }
                    },
                    {
                        id: 8,
                        title: this.$t("headers.last_location"),
                        tag: "lastLocation",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: item.node.trackerLastLocation || "",
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 12 : 8,
                            md: this.screenWidth < 1095 ? 6 : 4,
                            lg: this.screenWidth < 1295 ? 6 : 4,
                            xl: 4
                        }
                    }
                );
            }

            _infoCard = _infoCard.sort((a, b) => a.id - b.id);
            return _infoCard;
        },
        actionCard(item) {
            const { numberOfDocuments, numberOfUnresolvedAlerts } = item.node;

            const disabledDocsColor = value => {
                return value === 0 ? "opacity: 0.5; filter: grayscale(100%);" : "";
            };

            const disabledAlertsColor = value => {
                return value === 0 ? "#616161" : "#EB4F46";
            };

            const badgeColor = (type, value) => {
                if (type === "documents") {
                    if (this.isEmpty(value) || value === 0) {
                        return {
                            color: "#E0E0E0",
                            colorText: "#616161"
                        };
                    } else {
                        return {
                            color: "#E0E0E0",
                            colorText: "#7F7EA3"
                        };
                    }
                }
                if (type === "alerts") {
                    if (this.isEmpty(value) || value === 0) {
                        return {
                            color: "#E0E0E0",
                            colorText: "#616161"
                        };
                    } else {
                        return {
                            color: "#FFCDD2",
                            colorText: "#A64640FF"
                        };
                    }
                }
            };

            let _action = [
                {
                    title: this.$t("headers.documents"),
                    tag: "documents",
                    icon: null,
                    img: require("@/assets/description.png"),
                    iconSize: "20",
                    colorIcon: "",
                    style: disabledDocsColor(numberOfDocuments),
                    badgeColor: badgeColor("documents", numberOfDocuments),
                    align: "left",
                    value: numberOfDocuments,
                    col: {
                        xs: "auto",
                        sm: "auto",
                        md: "auto",
                        lg: 4,
                        xl: 4
                    }
                },
                {
                    title: this.$t("headers.alerts"),
                    tag: "alerts",
                    icon: "warning_amber",
                    iconSize: 20,
                    colorIcon: disabledAlertsColor(numberOfUnresolvedAlerts),
                    badgeColor: badgeColor("alerts", numberOfUnresolvedAlerts),
                    style: "",
                    align: "left",
                    value: numberOfUnresolvedAlerts,
                    col: {
                        xs: "auto",
                        sm: "auto",
                        md: "auto",
                        lg: 4,
                        xl: 4
                    }
                }
            ];

            return _action;
        },
        trackerSerie(freight) {
            let trackerSerie = [];
            let _additionalInfo = this.getExternalTrackerAdditionalInfo(freight.additionalInfo);
            let freightTrackers = freight.tripFreightTrackerSet.edges;
            let primary = this.$vuetify.theme.themes.light.primary;
           
            const formatDate = date => {
                return date ? this.formatDateTime(date) : "";
            };

            if (_additionalInfo) {
                trackerSerie.push({
                    serial: _additionalInfo.serialNumber,
                    model: _additionalInfo.type || "",
                    color: primary,
                    text: "External Tracker",
                    activatedDate: ""
                });
            }

            if (freightTrackers) {
                freightTrackers.forEach(element => {
                    if (element.node.tracker.activatedDate) {
                        trackerSerie.push({
                            serial: element.node.tracker.serialNumber,
                            model: element.node.tracker.model || "",
                            color: primary,
                            text: this.$t("general.activated"),
                            activatedDate: formatDate(element.node.tracker.activatedDate)
                        });
                    } else {
                        trackerSerie.push({
                            serial: element.node.tracker.serialNumber,
                            model: element.node.tracker.model || "",
                            color: "#FFCDD2",
                            text: this.$t("general.not_activated"),
                            activatedDate: ""
                        });
                    }
                });
                return trackerSerie;
            }
        },
        getDelay(item, type) {
            let actual = item?.actualArrivalDate;
            let offset = null;
            if (type === "eta") {
                offset = this.calculateDelaysInDay(
                    item?.originalEstimatedArrivalDate,
                    item?.estimatedArrivalDate,
                    item?.actualArrivalDate
                );
            } else if (type == "etd") {
                actual = item?.actualDepartureDate;
                offset = this.calculateDelaysInDay(
                    item?.originalEstimatedDepartureDate,
                    item?.estimatedDepartureDate,
                    item?.actualDepartureDate
                );
            }

            let event =
                offset < 0 && !actual
                    ? this.$t("date.to_go")
                    : offset < 0 && actual
                    ? this.$t("date.earlier")
                    : this.$t("date.late");

            if (!offset) {
                return {
                    offset: "",
                    event: "",
                    color: "success--text",
                    text: ""
                };
            }

            let color = offset > 0 ? "error--text" : "success--text";
            let text = offset === 0 ? "" : `${Math.abs(offset)} ${this.$t("date.days2")} ${event}`;

            return {
                offset: offset,
                event: event,
                color: color,
                text: text
            };
        },
        isEmpty(value) {
            return value === null || value === undefined || value === "" || value === 0;
        },
        detailsPage(item) {
            this.$emit("click:row", item);
        },
        goToFreight(freight, trip) {
            this.$router.push({
                path: "/previewtrips",
                query: {
                    id: trip,
                    tab: "freights",
                    freight: freight
                }
            });
        },
        shareTripts() {
            let url = window.location.href;
            let dummy = document.createElement("input"),
                text = url;
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            const payload = {
                color: "blue",
                message: this.$t("general.link")
            };
            this.$store.dispatch("snackbar/showMessage", payload);
        },
        favoriteBtn(id, value) {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateFavoriteTrip($input: UpdateFavoriteTripInput!) {
                            updateFavoriteTrip(input: $input) {
                                trip {
                                    id
                                    isUserFavorite
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            favorite: value,
                            tripId: id
                        }
                    }
                })
                .then(() => {
                    let message = value ? this.$t("general.save_favorite") : this.$t("general.remove_favorite");

                    const payload = {
                        color: "blue",
                        message: message
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                });
        },
        getIcon(name) {
            let icon = "";
            if (name !== null) {
                switch (name) {
                    case "AIR":
                        icon = "flight";
                        break;
                    case "SEA":
                        icon = "directions_boat";
                        break;
                    case "ROAD":
                        icon = "local_shipping";
                        break;
                    case "RAIL":
                        icon = "directions_railway";
                        break;
                    default:
                        icon = "directions_boat";
                        break;
                }
            }
            return icon;
        },
        getOrigin(trip) {
            let _origin = null;
            if (trip?.originLocation) {
                if (trip?.originLocation?.city && trip?.originLocation?.city !== "")
                    _origin = `${trip?.originLocation?.city}`;

                if (!_origin) _origin = `${trip?.originLocation?.name}`;
            } else if (!trip?.originLocation && trip.transportMode?.toLowerCase() === "sea") {
                _origin = trip?.tripSeaTransportSet?.edges
                    .find(edge => edge?.node?.portTransportType == "POL")
                    ?.node?.portName?.toLowerCase();
            }

            return _origin;
        },
        getDestination(trip) {
            let _destination = null;
            if (trip?.destinationLocation) {
                if (trip?.destinationLocation?.city && trip?.destinationLocation?.city !== "")
                    _destination = `${trip?.destinationLocation?.city}`;

                if (!_destination) _destination = `${trip?.destinationLocation?.name}`;
            } else if (!trip?.destinationLocation && trip.transportMode.toLowerCase() === "sea") {
                _destination = trip?.tripSeaTransportSet?.edges
                    .find(edge => edge?.node?.portTransportType == "POD")
                    ?.node?.portName?.toLowerCase();
            }

            return _destination;
        },
        loadHeaders() {
            let _default = [...this.defaultHeaderChoices];

            let newChoices = this.isSharedTrip ? _default : _default.filter(header => header.code !== "tenant");

            if (newChoices.some(choice => typeof choice === "undefined")) {
                // console.error("Undefined item found in newChoices");
            } else {
                this.headerChoices = helper.getTableHeaders(`${this.switchTableHeaders}`, newChoices);
            }
        },
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices.map(choice => choice);

            if (this.isSharedTrip) {
                helper.updateMyPreferences({
                    sharedTripTableHeaders: newChoices
                });
            } else {
                helper.updateMyPreferences({
                    tripTableHeaders: newChoices
                });
            }
        },
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 180 + "px";
            });
        },
        startScrolling(direction) {
            this.scrollInterval = setInterval(() => {
                const tableContainer = document.querySelector(".v-data-table__wrapper");
                const scrollAmount = direction === "left" ? -10 : 10;
                tableContainer.scrollLeft += scrollAmount;
            }, 10);
        },
        stopScrolling() {
            clearInterval(this.scrollInterval);
        },
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        isArray(value) {
            return Array.isArray(value);
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.trips?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refetchDate() {
            // use timeout to debouce
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.trips.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.trips?.pageInfo?.hasNextPage) {
                this.$apollo.queries.trips.fetchMore({
                    variables: {
                        after: this.trips.pageInfo.endCursor
                    }
                });
            }
        },
        getExternalTrackerAdditionalInfo(additionalInfo) {
            let additionalInfoToReturn = null;
            if (additionalInfo) {
                let additionalInfoObj = JSON.parse(additionalInfo);

                if (additionalInfoObj?.externalTracker) {
                    try {
                        let obj = JSON.parse(additionalInfoObj.externalTracker);
                        if (obj.hasOwnProperty("externalTracker")) additionalInfoToReturn = obj?.externalTracker;
                        else additionalInfoToReturn = obj;
                    } catch (e) {
                        additionalInfoToReturn = additionalInfoObj?.externalTracker;
                    }
                }
            }
            return additionalInfoToReturn;
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
.center-loading {
    position: sticky;
    left: 50% !important;
}
.textTruncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.classMainContent {
    padding-left: 4px !important;
    padding-right: 4px !important;
    margin-top: 1px !important;
    margin-bottom: 16px !important;
}
.classSecondaryContent {
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.secondaryRowSize {
    width: 100% !important;
}

.classHeader {
    color: #555464 !important;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
}

.class-text-tooltip {
    /* color: #0e0d35 !important; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}

.classText {
    color: #0e0d35 !important;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}

.textTitle {
    font-size: 32px !important;
    color: "#0e1e46" !important;
    font-weight: bold;
    word-break: normal !important;
    line-height: inherit !important;
    text-align: center !important;
}
.textSubtitle {
    font-size: 13px !important;
    color: "#868599" !important;
    height: 23px !important;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
}

.backgroundColor {
    background-color: #f9fafe !important;
}

.textLeft {
    text-align: left !important;
}

.tabItemCl {
    background-color: var(--v-background-base);
}

.disabledItem {
    pointer-events: none;
    opacity: 0.4;
}

.classHeader2 {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
}

.classText2 {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}
</style>
