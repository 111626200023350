<template>
    <v-card outlined class="pa-3 rounded-lg">
        <div v-if="!canAccess">
            <NoPermission></NoPermission>
        </div>
        <v-card flat v-else>
            <v-card-title class="textTitle">
                <div class="mr-2">
                    <v-img :src="require('@/assets/container.png')" width="30"></v-img>
                </div>
                <div>
                    {{ $t("headers.freight") }}
                </div>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="!isSharedTrip"
                    :disabled="!canEdit"
                    @click="clickAdd()"
                    elevation="0"
                    color="primary"
                    class="mt-n1"
                >
                    {{ $t("trips.add_freight") }}
                </v-btn>
            </v-card-title>
            <LoadingBar v-if="tripIsLoading"></LoadingBar>
            <!-- No data  -->
            <NoData v-else-if="!tripIsLoading && !hasTripFreight">
                <template v-slot:content>{{ $t("trips.no_freights") }}</template>
            </NoData>
            <div v-else class="cardContainer">
                <LoadingBar
                    v-if="tripIsLoading && hasTripFreight"
                    style="
                        z-index: 1000;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                    "
                ></LoadingBar>
                <div v-if="!hasTripFreight">
                    <NoData>
                        <template v-slot:content>{{ $t("trips.no_freights") }}</template>
                    </NoData>
                </div>
                <v-expansion-panels v-model="freightPanel" multiple flat class="rounded-lg px-4" v-else>
                    <v-expansion-panel
                        v-for="(freight, ftId) in computedTripFreights.edges"
                        :key="`freight-${ftId}`"
                        class="mb-2 outlineD contentBg rounded-lg"
                        @click="getMoreData(freight.node)"
                    >
                        <v-expansion-panel-header class="contentBg rounded-lg my-0 px-6">
                            <v-row>
                                <v-col
                                    v-for="(col, fIdx) in freightHeader(freight)"
                                    :key="fIdx"
                                    :cols="col.cols.xs"
                                    :xs="col.cols.xs"
                                    :sm="col.cols.sm"
                                    :md="col.cols.md"
                                    :lg="col.cols.lg"
                                    :xl="col.cols.xl"
                                >
                                    <div class="textHeader">
                                        {{ $t(col.text) }}
                                    </div>
                                    <div
                                        class="textBody"
                                        v-if="col.tag !== 'tracker'"
                                        @click.stop.prevent
                                        style="user-select: text;"
                                    >
                                        {{ col.value }}
                                    </div>
                                    <div v-else>
                                        <div v-for="(tracker, tIdx) in col.value" :key="tIdx">
                                            <v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs
                                                    }"
                                                >
                                                    <span
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        class="textBody d-flex justify-start align-left"
                                                    >
                                                        {{ tracker.serial }}
                                                        <v-icon
                                                            size="14"
                                                            color="primary"
                                                            @click.stop="copyToClipboard(tracker.serial)"
                                                            class="ml-1"
                                                            >content_copy</v-icon
                                                        >
                                                    </span>
                                                </template>
                                                <span>{{ tracker.serial }}</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="contentBg mt-n1 px-6 pt-6">
                            <v-card class="contentBg rounded-lg" flat>
                                <v-row class="px-3 contentBg">
                                    <v-spacer></v-spacer>
                                    <v-card flat max-width="20px" class="d-flex justify-center" color="transparent">
                                        <v-menu
                                            offset-y
                                            transition="slide-x-reverse-transition"
                                            width="250px"
                                            attach
                                            left
                                            bottom
                                            close-on-click
                                            nudge-top="-3"
                                            v-if="!isSharedTrip"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-btn icon v-bind="attrs" v-on="on">
                                                    <v-icon>more_vert</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list class="white">
                                                <v-list-item>
                                                    <v-btn
                                                        plain
                                                        elevation="0"
                                                        :disabled="!canEdit"
                                                        @click.stop.prevent="editItem(freight.node)"
                                                    >
                                                        <v-icon class="mr-2">edit</v-icon>
                                                        {{ $t("general.edit") }}
                                                    </v-btn>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-btn
                                                        plain
                                                        :disabled="!(canEdit && computedTripFreights.edges.length > 1)"
                                                        elevation="0"
                                                        @click.stop.prevent="deleteItem(freight.node)"
                                                    >
                                                        <v-icon class="mr-2">delete</v-icon>
                                                        {{ $t("general.delete") }}
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-card>
                                </v-row>
                                <v-card-text class="px-0 contentBg">
                                    <v-row align="stretch" class="mb-6">
                                        <v-col
                                            v-for="(col, fBInx) in freightBody(freight)"
                                            :key="`freightBody-${fBInx}`"
                                            :cols="col.cols.xs"
                                            :xs="col.cols.xs"
                                            :sm="col.cols.sm"
                                            :md="col.cols.md"
                                            :lg="col.cols.lg"
                                            :xl="col.cols.xl"
                                        >
                                            <div class="textHeader">
                                                {{ $t(col.text) }}
                                            </div>
                                            <div>
                                                <span class="textBody">{{ col.value }}</span>
                                            </div>
                                        </v-col>
                                        <!-- <v-col cols="12" v-if="hasPortBasePermission">Terminal Information</v-col> -->
                                        <v-col
                                            v-for="(col, pIdx) in portBaseEvents"
                                            :key="`portBaseEvents-${pIdx}`"
                                            :cols="col.cols.xs"
                                            :xs="col.cols.xs"
                                            :sm="col.cols.sm"
                                            :md="col.cols.md"
                                            :lg="col.cols.lg"
                                            :xl="col.cols.xl"
                                        >
                                            <div class="textHeader">
                                                {{ $t(col.text) }}
                                            </div>
                                            <div>
                                                <span class="textBody">{{ col.value }}</span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-expansion-panels v-model="panel" multiple flat>
                                        <v-expansion-panel class="mb-2 elevation-0 outlined rounded-lg">
                                            <v-expansion-panel-header>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        <div class="d-flex flex-row align-center mt-1">
                                                            <v-icon color="primary">{{
                                                                trackerOject(freight.node.tripFreightTrackerSet).icon
                                                            }}</v-icon>
                                                            <div class="textBody mr-1">
                                                                {{
                                                                    trackerOject(freight.node.tripFreightTrackerSet)
                                                                        .text
                                                                }}
                                                            </div>
                                                            <div>
                                                                ({{
                                                                    trackers(freight).length
                                                                        ? trackers(freight).length
                                                                        : 0
                                                                }})
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                    <v-spacer></v-spacer>
                                                    <v-col cols="auto" class="pt-2">
                                                        <span
                                                            class="textBody mr-1"
                                                            v-if="
                                                                trackerOject(freight.node.tripFreightTrackerSet).timer
                                                            "
                                                            >{{ $t("trips.last_connection") }}</span
                                                        >
                                                        <span class="textBody">{{
                                                            trackerOject(freight.node.tripFreightTrackerSet).timer
                                                        }}</span>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content class="px-5 py-4">
                                                <v-row
                                                    justify="center"
                                                    align="center"
                                                    class="px-5 py-4"
                                                    v-if="trackers(freight).length === 0"
                                                >
                                                    <v-col
                                                        cols="4"
                                                        class="px-5 py-4 d-flex justify-center align-center flex-column"
                                                    >
                                                        <div class="textBody">
                                                            {{ $t("loading.no_data") }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                    v-for="trackerInfo in trackers(freight)"
                                                    :key="trackerInfo.id"
                                                    class="mt-1 mx-0"
                                                    dense
                                                >
                                                    <v-col cols="12">
                                                        <v-row>
                                                            <v-col class="textBody" cols="auto">
                                                                <v-chip
                                                                    :color="
                                                                        alertTracker(
                                                                            trackerInfo.node.tracker.lastConnectionTime
                                                                        )
                                                                            ? ''
                                                                            : ''
                                                                    "
                                                                    class="pr-3"
                                                                >
                                                                    <span class="textBody">
                                                                        {{ getSerialNumber(trackerInfo.node.tracker) }}
                                                                    </span>
                                                                    <v-icon
                                                                        right
                                                                        size="14"
                                                                        color="white"
                                                                        @click.stop="
                                                                            copyToClipboard(
                                                                                trackerInfo.node.tracker.serialNumber
                                                                            )
                                                                        "
                                                                        >content_copy</v-icon
                                                                    >
                                                                </v-chip>
                                                            </v-col>
                                                            <v-spacer></v-spacer>
                                                            <v-col
                                                                cols="12"
                                                                xs="12"
                                                                sm="12"
                                                                md="5"
                                                                lg="4"
                                                                xl="4"
                                                                :class="
                                                                    transitionScreen.mdToSm
                                                                        ? 'grey--text textBody'
                                                                        : 'd-flex justify-end grey--text textBody'
                                                                "
                                                                v-if="trackerInfo.node.tracker.lastConnectionTime"
                                                            >
                                                                <div>
                                                                    {{ $t("trips.last_connection") }}
                                                                    {{
                                                                        relativeTime(
                                                                            trackerInfo.node.tracker.lastConnectionTime
                                                                        )
                                                                    }}
                                                                </div>
                                                            </v-col>
                                                            <v-col
                                                                cols="12"
                                                                xs="12"
                                                                sm="12"
                                                                md="5"
                                                                lg="4"
                                                                xl="4"
                                                                :class="
                                                                    transitionScreen.mdToSm
                                                                        ? 'grey--text textBody'
                                                                        : 'd-flex justify-end grey--text textBody'
                                                                "
                                                                v-else
                                                            >
                                                                <div>
                                                                    {{ $t("loading.no_available") }}
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col
                                                        v-for="(tracker, tEIdx) in trackerEvents(
                                                            trackerInfo.node.tracker
                                                        )"
                                                        :key="tEIdx"
                                                        cols="12"
                                                        xs="12"
                                                        :sm="transitionScreen.smToXs ? 12 : 6"
                                                        :md="transitionScreen.mdToSm ? 4 : 3"
                                                        :lg="transitionScreen.lgToMd ? 3 : 2"
                                                        xl="2"
                                                    >
                                                        <v-card outlined height="75" width="100%" class="px-2 pt-3">
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    <div
                                                                        class="
                                                                                textBody mb-1
                                                                            "
                                                                    >
                                                                        {{ tracker.value }}
                                                                    </div>
                                                                    <div class="text-no-wrap textHeader mb-1 ">
                                                                        {{ $t(tracker.text) }}
                                                                    </div>
                                                                    <div class="textDetails text-no-wrap ">
                                                                        {{ tracker.formatDateTime }}
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="auto">
                                                                    <v-btn
                                                                        :style="`background-color: ${tracker.color}`"
                                                                        class="pa-1"
                                                                        elevation="0"
                                                                        icon
                                                                        small
                                                                    >
                                                                        <v-img
                                                                            :style="
                                                                                `
                                                                                    background-color:transparent;
                                                                                    filter: invert(100%); 
                                                                                    `
                                                                            "
                                                                            :src="tracker.icon"
                                                                            width="18"
                                                                        ></v-img>
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="mb-2 elevation-0 outlined rounded-lg">
                                            <v-expansion-panel-header>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        <v-icon color="primary">notifications</v-icon>
                                                        <span class="textBody">{{ $t("home.alert_rules") }}</span>
                                                        ({{ freight.node.tripFreightAlertRuleSet.edges.length }})
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content class="pb-3 px-5">
                                                <v-row
                                                    dense
                                                    class="mt-1 mx-0"
                                                    v-if="freight.node.tripFreightAlertRuleSet.edges.length > 0"
                                                >
                                                    <v-col
                                                        class="mr-1"
                                                        v-for="(item, i) in freight.node.tripFreightAlertRuleSet.edges"
                                                        :key="`tripFreightAlertRuleSet-${i}`"
                                                        cols="auto"
                                                    >
                                                        <v-chip
                                                            v-if="item.node.tenantAlertRule"
                                                            class="pr-3 "
                                                            @click="redirectToAlertRule(item.node.tenantAlertRule.id)"
                                                        >
                                                            {{
                                                                item.node.tenantAlertRule
                                                                    ? item.node.tenantAlertRule.name
                                                                    : ""
                                                            }}
                                                        </v-chip>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-else>
                                                    <v-col class="mr-1">
                                                        <div class="caption grey--text">
                                                            <i>{{ $t("loading.no_available") }}</i>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="mb-2 elevation-0 outlined rounded-lg" v-if="!me.isPartnerUser">
                                            <v-expansion-panel-header>
                                                <v-row no-gutters>
                                                    <v-col v-if="freight.node">
                                                        <v-icon :color="freight.node.notes ? 'primary' : ''">{{
                                                            iconNotes
                                                        }}</v-icon>
                                                        <span class="textBody">{{ $t("home.notes") }}</span>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content class="pb-3 px-5">
                                                <v-card flat class="mb-2">
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col v-if="freight.node && freight.node.notes">
                                                                <v-row>
                                                                    <v-col cols="11">
                                                                        <div v-html="freight.node.notes"></div>
                                                                    </v-col>
                                                                    <v-col cols="1">
                                                                        <v-btn
                                                                            icon
                                                                            v-if="freight.node && freight.node.notes"
                                                                            @click="openNoteDialog(freight.node)"
                                                                        >
                                                                            <v-icon>edit</v-icon>
                                                                        </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                            <v-col class="mr-1 d-flex justify-center" v-else>
                                                                <v-btn
                                                                    class="elevation-0"
                                                                    v-if="freight.node && !freight.node.notes"
                                                                    @click="openNoteDialog(freight.node)"
                                                                    color="primary"
                                                                >
                                                                    {{ $t("home.add_your_note") }}
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                        <v-dialog v-model="editNotes" width="600px" permanent>
                                                            <v-card>
                                                                <v-card-title class="primary">
                                                                    <v-icon color="white">{{ iconNotes }}</v-icon>
                                                                    <span class="white--text">{{
                                                                        $t("home.notes")
                                                                    }}</span>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn
                                                                        @click="cancelNoteDialog()"
                                                                        class="mr-1"
                                                                        color="white"
                                                                        text
                                                                    >
                                                                        {{ $t("general.cancel") }}
                                                                    </v-btn>
                                                                    <v-btn
                                                                        class="elevation-0"
                                                                        @click="updateNotes()"
                                                                        :loading="loadingNotes"
                                                                    >
                                                                        {{ $t("general.save") }}
                                                                    </v-btn>
                                                                </v-card-title>
                                                                <v-card-text class="pa-1" v-if="myNotes">
                                                                    <TipTap v-model="myNotes.notes"> </TipTap>
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-dialog>
                                                    </v-card-text>
                                                </v-card>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <!-- Create and Edit Dialog -->
            <v-dialog v-model="createDialog" max-width="800px" persistent>
                <v-card>
                    <v-card-title class="primary textTitle2">
                        <v-img
                            :style="
                                `
                                background-color:transparent;
                                filter: invert(100%);
                               `
                            "
                            :src="require('@/assets/container.png')"
                            max-width="30"
                            max-height="30"
                            class="mr-2"
                        ></v-img>
                        <span>{{ toggleTitle ? this.$t("trips.edit_freight") : this.$t("trips.add_freight") }}</span>
                        <v-spacer></v-spacer>
                        <v-btn icon rounded @click="closeFreightForm">
                            <v-icon color="white">
                                close
                            </v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="dialogCardContainer">
                        <PreviewDetailFreightForm
                            v-model="validateForm"
                            ref="freightForm"
                            :transport="trip.transportMode.toLowerCase()"
                            :trip="tripObject"
                            :editObj="item"
                            v-on:create-freight="addFreight"
                            v-on:update-freight="editFreight"
                            v-on:close-dialog="closeFreightForm"
                            :isOpen="createDialog"
                            :currentTrackers="currentTrackers"
                            class="overflow-visible"
                            @focusSelected="val => resizeFreightsFormCard(val)"
                        >
                        </PreviewDetailFreightForm>
                    </v-card-text>
                    <v-card-actions height="100">
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeFreightForm">{{ $t("general.cancel") }} </v-btn>
                        <v-btn
                            color="primary"
                            elevation="0"
                            :loading="loadingCreateEditBtn"
                            @click="toggleTitle ? $refs.freightForm.editFreight() : $refs.freightForm.newFreight()"
                            :disabled="!validateForm"
                            >{{ toggleTitle ? this.$t("general.save") : this.$t("general.add") }}</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Dialog -->
            <v-dialog v-model="deleteDialog" max-width="300px" height="500px" transition="dialog-bottom-transition">
                <v-card>
                    <v-card-title class="background2 py-3 mb-2 textTitle">
                        <div class="mr-2">
                            <v-icon>warning</v-icon>
                        </div>
                        <span>
                            <h5>{{ $t("trips.delete_freight") }}</h5>
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <div>
                            <span>
                                <h4>
                                    {{ $t("trips.confirm_freight") }}
                                </h4>
                            </span>
                        </div>
                        <div class="mt-2">
                            <span>{{ deletedItem.referenceNumber }}</span>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn width="80px" text @click="cancelDeleteDialog">{{ $t("general.cancel") }}</v-btn>
                        <v-btn
                            width="80px"
                            color="error"
                            elevation="0"
                            :loading="loadingDeleteBtn"
                            @click="deleteFreight(deletedItem.id)"
                            >{{ $t("general.delete") }}</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import moment from "moment";
import LoadingBar from "@/components/base/LoadingBar.vue";
import TipTap from "@/components/base/TipTap.vue";
import helper from "@/utils/helper.js";
import PreviewDetailFreightForm from "@/components/trips/forms/PreviewDetailFreightForm.vue";
import { TripPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import NoData from "@/components/base/NoData.vue";
import Config from "@/utils/config.json";
import { mdiNoteEdit } from "@mdi/js";

export default {
    props: {
        trip: {
            type: Object,
            required: true
        },
        tripIsLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        },
        freightProp: {
            type: Object,
            required: false,
            default: function() {
                return null;
            }
        }
    },
    components: {
        LoadingBar,
        PreviewDetailFreightForm,
        NoPermission,
        NoData,
        TipTap
    },
    apollo: {},
    data() {
        return {
            panel: [0],
            freightPanel: [],
            // tripFreights: {},
            portBaseInfo: [],
            //primaryColor
            createDialog: false,
            deleteDialog: false,
            item: {},
            editNotes: false,
            deletedItem: {},
            validateForm: false,
            toggleTitle: false,
            loadingDeleteBtn: false,
            loadingCreateEditBtn: false,
            carousel: 0,
            // tripFreight: { edges: [] },
            currentTrackers: [],
            FreightsFormCardHeight: "auto",
            portBaseEvents: [],
            iconNotes: mdiNoteEdit,
            myNotes: null,
            loadingNotes: false
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.isEmpty = helper.isEmpty;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
        this.isEmpty = helper.isEmpty;
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.relativeTime = helper.relativeTime;
        this.formatTemperature = helper.formatTemperature;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.unitPerBrand = helper.unitPerBrand;
        this.formatAirwaybillNumber = helper.formatAirwaybillNumber;
        this.removeHifenFromAirwaybill = helper.removeHifenFromAirwaybill;
    },
    watch: {
        primaryColor(newColor) {
            this.updateScrollbarColor(newColor);
        },
        $route: {
            immediate: true,
            handler(to) {
                if (to.query.freight) {
                    if (this.computedTripFreights?.edges.length === 0) return;
                    this.tripFreightFromQuery(this.computedTripFreights, to.query.freight);
                    // let freight = to.query.freight;
                    // let indexPosition = this.tripFreight.edges.findIndex(x => x.node.id === freight);

                    // let temp = this.tripFreight.edges[indexPosition];
                    // let newEdges = [...this.tripFreight.edges];
                    // newEdges.splice(indexPosition, 1);
                    // newEdges.unshift(temp);
                    // this.tripFreight = { edges: newEdges };
                    // this.freightPanel = [0];

                    this.cleanRouteQuery(this.$router, "freight");
                }
            }
        }
    },

    computed: {
        me() {
            return helper.me();
        },
        computedTripFreights: {
            get() {
                let local = { ...this.trip?.tripFreightSet };
                if (this.isEmpty(local)) return { edges: [] };
                if (this.$route.query.freight) {
                    local = this.tripFreightFromQuery(local, this.$route.query.freight);
                    this.cleanRouteQuery(this.$router, "freight");
                }
                return local;
            },
            set(value) {
                return value;
            }
        },
        // tripFreights() {
        //     let local = { ...this.trip?.tripFreightSet };
        //     if(this.isEmpty(local)) return { edges: [] };
        //     if(this.$route.query.freight){
        //         local = this.tripFreightFromQuery(local, this.$route.query.freight);
        //         this.cleanRouteQuery(this.$router, "freight");
        //     }
        //     return local;
        // },
        transitionLgToMd() {
            return this.$vuetify.breakpoint.width < 1575;
        },
        trackerRangeDate() {
            let _range = [];
            this.computedTripFreights?.edges.forEach(edge => {
                if (edge.node.startDate && edge.node.endDate) {
                    let _start = this.formatDateTime(edge.node.startDate)?.slice(0, 10);
                    let _end = this.formatDateTime(edge.node.endDate)?.slice(0, 10);
                    _range.push({
                        id: edge.node.id,
                        range: `${_start} - ${_end}`
                    });
                } else if (edge.node.startDate) {
                    let _start = this.formatDateTime(edge.node.startDate)?.slice(0, 10);
                    _range.push({
                        id: edge.node.id,
                        range: `${_start}`
                    });
                } else if (edge.node.endDate) {
                    let _end = this.formatDateTime(edge.node.endDate)?.slice(0, 10);
                    _range.push({
                        id: edge.node.id,
                        range: `${_end}`
                    });
                } else {
                    _range.push({
                        id: edge.node.id,
                        range: ``
                    });
                }
            });
            return _range || [];
        },

        primaryColor() {
            return this.$vuetify.theme.themes.light.primary;
        },
        transitionScreen() {
            let _trasition = {
                smToXs: this.$vuetify.breakpoint.width < 640,
                mdToSm: this.$vuetify.breakpoint.width < 990,
                lgToMd: this.$vuetify.breakpoint.width < 1365
            };
            return _trasition;
        },
        hasTripFreight() {
            return this.computedTripFreights?.edges?.length > 0;
        },
        canAccess() {
            //Partner who is not a tenannt
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (!this.hasOrganisationPermission(TripPermissions.VIEW_TRIP_FREIGHT, this.organisationPermissions))
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(TripPermissions.VIEW_TRIP_FREIGHT, this.organisationPermissions)
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_FREIGHT,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (!this.hasPermission(TripPermissions.VIEW_TRIP_FREIGHT) && this.me?.tenant) return false;
            }

            return true;
        },
        canEdit() {
            if (this.trip?.status === "COMPLETED") return false;

            if (this.isSharedTrip) return false;

            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (!this.hasOrganisationPermission(TripPermissions.EDIT_TRIP_FREIGHT, this.organisationPermissions))
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(TripPermissions.EDIT_TRIP_FREIGHT, this.organisationPermissions)
                )
                    return false;
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_FREIGHT,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                if (!this.hasPermission(TripPermissions.EDIT_TRIP_FREIGHT) && this.me?.tenant) return false;
            }

            return true;
        },
        tripObject() {
            let trip = {};
            trip = {
                id: this.trip?.id,
                referenceNumber: this.trip?.referenceNumber,
                status: this.trip?.status,
                transportMode: this.trip?.transportMode.toLowerCase(),
                plannedDepartureDate: this.getDate(this.trip.plannedDepartureDate)
            };
            return trip;
        },
        hasPortBasePermission() {
            let _permission = "portbase.view_info";
            return this.me?.tenant?.organisationPermissions.includes(_permission);
        }
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "trip_summary_tab_svw"
        });
        this.setUserProperties();
        this.updateScrollbarColor(this.primaryColor);
    },
    methods: {
        tripFreightFromQuery(local, to) {
            let freight = to;

            if (local?.edges.length === 0) return { edges: [] };
            let indexPosition = local.edges.findIndex(x => x.node.id === freight);

            let temp = local.edges[indexPosition];
            let newEdges = [...local.edges];
            newEdges.splice(indexPosition, 1);
            newEdges.unshift(temp);
            local = { edges: newEdges };

            this.freightPanel = [0];

            return local;
        },
        cleanRouteQuery(router, key) {
            //remove key freight from url
            let query = { ...router.currentRoute.query };
            delete query[key];
            router.replace({ query: query });
        },
        textTruncate(text, length) {
            if (text === null || text === undefined) {
                return;
            } else if (text.length > length) {
                return text.substring(0, length) + "...";
            } else {
                return text;
            }
        },
        updateScrollbarColor(color) {
            document.documentElement.style.setProperty("--scrollbar-color", color);
        },
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("trip_summary_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("trip_summary_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        copyToClipboard(text) {
            let dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            const payload = {
                color: "primary",
                message: this.$t("general.copied")
            };
            this.$store.dispatch("snackbar/showMessage", payload);
        },
        redirectToAlertRule(id) {
            //future implementation
            this.$router.push({
                name: "customrules",
                query: { id: id }
            });
        },
        alertTracker(value) {
            let relativeTime = moment(value)
                .locale("en")
                .startOf("hour")
                .fromNow();
            let x = relativeTime.split(" ");

            if (x[2] === "minutes" || x[1] === "minutes" || x[1] === "minute" || x[1] === "hour" || x[1] === "hours") {
                return true;
            } else {
                return false;
            }
        },
        freightHeader(freight) {
            let additionalInfo =
                freight?.node?.additionalInfo && freight?.node?.additionalInfo !== "{}"
                    ? JSON.parse(freight?.node?.additionalInfo)
                    : null;

            let freightHeader = [];
            let trackers = [];
            if (freight) {
                if (additionalInfo && !this.isEmpty(additionalInfo)) {
                    let trackerObj = null;
                    let additionalInfoObj = null;

                    if (additionalInfo.hasOwnProperty("externalTracker")) {
                        if (helper.isValidJson(additionalInfo))
                            additionalInfoObj = JSON.parse(additionalInfo?.externalTracker);
                        else additionalInfoObj = additionalInfo;
                    } else additionalInfoObj = JSON.parse(additionalInfo);

                    if (additionalInfoObj?.externalTracker) {
                        try {
                            trackerObj = JSON.parse(additionalInfoObj.externalTracker);
                            if (trackerObj.hasOwnProperty("externalTracker")) trackerObj = trackerObj?.externalTracker;
                        } catch (e) {
                            trackerObj = additionalInfoObj?.externalTracker;
                        }
                    }
                    trackers.push({
                        id: "0",
                        serial: trackerObj.serialNumber
                    });
                }

                freight.node.tripFreightTrackerSet.edges.forEach((t, i) => {
                    trackers.push({
                        id: i,
                        serial: t.node.tracker.serialNumber
                    });
                });
                freightHeader.push({
                    id: "2",
                    tag: "reference",
                    text: "headers.reference_number",
                    value: freight.node.referenceNumber,
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 2,
                        xl: 2
                    }
                });
                freightHeader.push({
                    id: "4",
                    tag: "order",
                    text: "headers.order_number",
                    value: freight.node.orderNumber,
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 2,
                        xl: 2
                    }
                });

                freightHeader.push({
                    id: "5",
                    tag: "tracker",
                    text: "headers.tracker_number",
                    value: trackers,
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 2,
                        xl: 2
                    }
                });

                freightHeader.push({
                    id: "4",
                    tag: "goods",
                    text: "headers.goods",
                    value: freight.node.goods,
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 2,
                        xl: 2
                    }
                });

                if (this.trip.transportMode === "SEA") {
                    freightHeader.push({
                        id: "1",
                        tag: "container",
                        text: "headers.container_number",
                        value: freight.node.containerNumber ? freight.node.containerNumber : "",
                        cols: {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 2,
                            xl: 2
                        }
                    });
                    freightHeader.push({
                        id: "3",
                        tag: "bill",
                        text: "headers.bill",
                        value: freight.node.billOfLadingNumber ? freight.node.billOfLadingNumber : "",
                        cols: {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 2,
                            xl: 2
                        }
                    });
                } else if (this.trip.transportMode === "AIR") {
                    freightHeader.push({
                        id: "1",
                        tag: "awb",
                        text: "headers.airwaybill_number",
                        value:
                            this.formatAirwaybillNumber(freight.node.airWaybillNumber) +
                            " | " +
                            freight.node.airWaybillNumber
                                ? freight.node.airWaybillNumber
                                : "",
                        cols: {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 2,
                            xl: 2
                        }
                    });

                    freightHeader.push({
                        id: "6",
                        text: "forms.contact_person",
                        value: freight.node.contact,
                        cols: {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 2,
                            xl: 2
                        }
                    });
                } else {
                    freightHeader.push({
                        id: "6",
                        text: "forms.contact_person",
                        value: freight.node.contact,
                        cols: {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 2,
                            xl: 2
                        }
                    });
                }
                //sort by id
                freightHeader.sort((a, b) => (a.id > b.id ? 1 : -1));
            }

            return freightHeader;
        },
        trackers(freight) {
            // let additionalInfo = freight?.node?.additionalInfo ? JSON.parse(freight?.node?.additionalInfo) : null;
            let additionalInfo =
                freight?.node?.additionalInfo && freight?.node?.additionalInfo !== "{}"
                    ? JSON.parse(freight?.node?.additionalInfo)
                    : null;

            let trackers = [];

            if (additionalInfo) {
                let trackerObj = null;
                let additionalInfoObj = null;

                if (additionalInfo.hasOwnProperty("externalTracker")) {
                    if (helper.isValidJson(additionalInfo))
                        additionalInfoObj = JSON.parse(additionalInfo?.externalTracker);
                    else additionalInfoObj = additionalInfo;
                } else additionalInfoObj = JSON.parse(additionalInfo);

                if (additionalInfoObj?.externalTracker) {
                    try {
                        trackerObj = JSON.parse(additionalInfoObj.externalTracker);
                        if (trackerObj.hasOwnProperty("externalTracker")) trackerObj = trackerObj?.externalTracker;
                    } catch (e) {
                        trackerObj = additionalInfoObj?.externalTracker;
                    }
                }
                trackers.push({
                    id: "0",
                    node: {
                        tracker: {
                            serialNumber: trackerObj.serialNumber,
                            type: trackerObj.type,
                            isExternal: true
                        }
                    }
                });
            }

            freight.node.tripFreightTrackerSet.edges.forEach((t, i) => {
                trackers.push({
                    id: i,
                    node: t.node
                });
            });

            return trackers;
        },
        freightBody(freight) {
            let freightHeader = [];
            let alertName = "";
            let terminalInfo = null;

            if (freight) {
                let dateRange = this.trackerRangeDate.find(item => item.id === freight.node.id).range;

                freightHeader.push({
                    id: "1",
                    text: "general.tracker_range",
                    value: dateRange,
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 2,
                        xl: 2
                    }
                });
                freightHeader.push({
                    id: "2",
                    text: "headers.last_location",
                    value: freight.node.trackerLastLocation,
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 2,
                        xl: 2
                    }
                });
                freightHeader.push({
                    id: "4",
                    text: "forms.load_point",
                    value: freight.node.loadPoint,
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 2,
                        xl: 2
                    }
                });

                freightHeader.push({
                    id: "5",
                    text: "forms.load_date",
                    value: this.formatDateTime(freight.node.loadDate),
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 2,
                        xl: 2
                    }
                });

                freightHeader.push({
                    id: "6",
                    text: "headers.terms",
                    value: freight.node.term,
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 2,
                        xl: 2
                    }
                });

                if (freight.node.contactParson) {
                    freightHeader.push({
                        id: "7",
                        text: "forms.contact_person",
                        value: freight.node.contactParson,
                        cols: {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 2,
                            xl: 2
                        }
                    });
                }
                /*
                freightHeader.push({
                    id: "9",
                    tag: "goods",
                    text: "headers.goods",
                    value: freight.node.goods,
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 2,
                        xl: 2
                    }
                });*/

                /* freightHeader.push({
                    id: "1",
                    text: "headers.airwaybill_number",
                    value: freight.node.airWaybillNumber
                }); */

                //sort by id
                freightHeader.sort((a, b) => (a.id > b.id ? 1 : -1));
            }

            return freightHeader;
        },
        getSerialNumber(tracker) {
            if (tracker) {
                return tracker.serialNumber ? tracker.serialNumber : "";
            }
            return "";
        },
        trackerOject(tracker) {
            let _obj = {
                icon: "settings_remote",
                text: this.$t("home.trackers"),
                amount: 0,
                timer: ""
            };

            if (tracker.edges.length > 0) {
                let lastUpdatedTracker = {
                    id: null,
                    serialNumber: null,
                    activatedDate: null,
                    lastConnectionTime: null
                };

                tracker.edges.forEach(({ node }) => {
                    //last connection time descending
                    if (
                        lastUpdatedTracker.lastConnectionTime === null ||
                        lastUpdatedTracker.lastConnectionTime < node.tracker.lastConnectionTime
                    ) {
                        lastUpdatedTracker = {
                            id: node.id,
                            serialNumber: node.tracker.serialNumber,
                            activatedDate: node.tracker.activatedDate,
                            lastConnectionTime: node.tracker.lastConnectionTime
                        };
                    }
                });

                _obj.amount = tracker ? tracker.edges.length : 0;
                _obj.timer = this.relativeTime(lastUpdatedTracker.lastConnectionTime);
            }

            return _obj;
        },
        trackerEvents(tracker) {
            let events = [];

            if (tracker.isExternal) {
                return events;
            }

            if (tracker) {
                let formatValue = Math.floor(tracker.lastBatteryValue ?? 0) + "%";
                let formatDate = this.formatDateTime(tracker.lastBatteryDeviceTime);
                events.push({
                    id: "4",
                    text: "general.battery",
                    formatDateTime: formatDate,
                    value: formatValue,
                    icon: require("@/assets/battery_charging_full.png"),
                    color: "#1DB32544"
                });

                if (tracker.lastTemperatureValue) {
                    let symbol = this.getTemperatureSymbol();
                    let formatValue = Math.floor(tracker.lastTemperatureValue) + symbol;
                    let formatDate = this.formatDateTime(tracker.lastTemperatureDeviceTime);
                    events.push({
                        id: "1",
                        text: "general.temperature",
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/device_thermostat.png"),
                        color: "#377EF2"
                    });
                }
                if (tracker.lastExternalTemperatureValue) {
                    let symbol = this.getTemperatureSymbol();
                    let formatValue = Math.floor(tracker.lastExternalTemperatureValue) + symbol;
                    let formatDate = this.formatDateTime(tracker.lastExternalTemperatureDeviceTime);
                    events.push({
                        id: "5",
                        text: "general.external_temperature",
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/device_thermostat.png"),
                        color: "#377EF2"
                    });
                }
                if (tracker.lastProbeTemperatureValue) {
                    let symbol = this.getTemperatureSymbol();
                    let formatValue = Math.floor(tracker.lastProbeTemperatureValue) + symbol;
                    let formatDate = this.formatDateTime(tracker.lastProbeTemperatureDeviceTime);
                    events.push({
                        id: "6",
                        text: "general.probe_temperature",
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/device_thermostat.png"),
                        color: "#377EF2"
                    });
                }
                if (tracker.lastHumidityValue) {
                    let formatValue = Math.floor(tracker.lastHumidityValue) + "%";
                    let formatDate = this.formatDateTime(tracker.lastHumidityDeviceTime);
                    events.push({
                        id: "3",
                        text: "general.humidity",
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/water_drop.png"),
                        color: "#FF9D84"
                    });
                }

                if (tracker.lastLightValue) {
                    let formatValue = tracker.lastLightValue.toFixed(2) + "Lux";
                    let formatDate = this.formatDateTime(tracker.lastLightDeviceTime);
                    events.push({
                        id: "2",
                        text: "general.light",
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/lightbulb.png"),
                        color: "#E3DE538F"
                    });
                }
                if (tracker.lastShockValue) {
                    let formatValue = Math.floor(tracker?.lastShockValue) + "G";
                    let formatDate = this.formatDateTime(tracker?.lastShockDeviceTime);
                    events.push({
                        id: "7",
                        text: "general.shock",
                        value: formatValue,
                        formatDateTime: formatDate,
                        icon: require("@/assets/bolt.png"),
                        color: "#B38EFF90"
                    });
                }

                //sort by id
            }
            if (events.length > 0) {
                events.sort((a, b) => (a.id > b.id ? 1 : -1));
            }

            return events;
        },
        isResolved(promise) {
            if (!promise) {
                return false;
            }

            let isPromise = promise instanceof Promise;
            if (isPromise) {
                return false;
            }

            return true;
        },

        convertTrip(obj) {
            let trip = {};
            trip = {
                id: obj.id,
                referenceNumber: obj.referenceNumber,
                status: obj.status,
                transportMode: obj.transportMode.toLowerCase(),
                plannedDepartureDate: this.getDate(obj.plannedDepartureDate)
            };
            return trip;
        },
        fixedNumber(x) {
            let fix = x.toFixed(2);
            return fix;
        },
        clickAdd() {
            this.toggleTitle = false;
            this.createDialog = true;
        },
        addFreight(value) {
            this.loadingCreateEditBtn = true;

            let payload = {
                tripId: this.trip.id,
                referenceNumber: value.referenceNumber,
                orderNumber: value.orderNumber,
                billOfLadingNumber: value.billOfLading,
                containerNumber: value.containerNumber,
                airWaybillNumber: this.removeHifenFromAirwaybill(value.awb),
                contact: value.contact,
                loadPoint: value.loadPoint,
                loadDate: helper.convertDateToUTC(value.loadDate),
                startDate: helper.convertDateTimeToUTC(value.startDate),
                endDate: helper.convertDateTimeToUTC(value.endDate),
                goods: value.goods,
                term: value.term,
                trackers: value.trackers,
                alerts: value.alerts ? value.alerts : null
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation createTripFreight($input: CreateTripFreightInput!) {
                            createTripFreight(input: $input) {
                                trip {
                                    id
                                    tripFreightSet {
                                        edges {
                                            node {
                                                id
                                                referenceNumber
                                                orderNumber
                                                containerNumber
                                                airWaybillNumber
                                                billOfLadingNumber
                                                contact
                                                loadPoint
                                                loadDate
                                                startDate
                                                endDate
                                                goods
                                                term
                                                tripFreightAlertRuleSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tenantAlertRule {
                                                                id
                                                                name
                                                            }
                                                        }
                                                    }
                                                }
                                                tripFreightTrackerSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tracker {
                                                                id
                                                                serialNumber
                                                                activatedDate
                                                                lastConnectionTime
                                                                lastBatteryDeviceTime
                                                                lastBatteryValue
                                                                lastTemperatureDeviceTime
                                                                lastTemperatureValue
                                                                lastExternalTemperatureDeviceTime
                                                                lastExternalTemperatureValue
                                                                lastProbeTemperatureDeviceTime
                                                                lastProbeTemperatureValue
                                                                lastHumidityDeviceTime
                                                                lastHumidityValue
                                                                lastLightDeviceTime
                                                                lastLightValue
                                                                lastShockDeviceTime
                                                                lastShockValue
                                                                lastLocationDeviceTime
                                                                lastLatitude
                                                                lastLongitude
                                                                lastLocationDescription
                                                            }
                                                            name
                                                            lowTemperature
                                                            highTemperature
                                                            lowHumidity
                                                            highHumidity
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    const payload = {
                        color: "blue",
                        message: this.$t("trips.freight_add"),
                        icon: "done"
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.loadingCreateEditBtn = false;
                    this.closeFreightForm();
                    this.$emit("refresh-trip");
                    // this.$apollo.queries.tripFreights.refetch();
                });
        },
        deleteItem(e) {
            this.deletedItem = {
                id: e.id,
                referenceNumber: e.referenceNumber
            };
            this.deleteDialog = true;
        },
        cancelDeleteDialog() {
            this.deleteDialog = false;
            this.deletedItem = {};
        },
        openNoteDialog(freight) {
            this.myNotes = {
                freightId: freight.id,
                notes: freight.notes ? freight.notes : ""
            };

            this.editNotes = true;
        },
        cancelNoteDialog() {
            this.editNotes = false;
            this.myNotes = null;
        },
        updateNotes() {
            this.loadingNotes = true;

            const validateNote = note => {
                let isEmptyHtml = note.replace(/<[^>]*>?/gm, "").trim();
                return isEmptyHtml.length > 0;
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateFreightNotes($input: UpdateFreightNotesInput!) {
                            updateFreightNotes(input: $input) {
                                trip {
                                    id
                                    tripFreightSet {
                                        edges {
                                            node {
                                                id
                                                referenceNumber
                                                orderNumber
                                                containerNumber
                                                airWaybillNumber
                                                billOfLadingNumber
                                                goods
                                                term
                                                notes
                                                contact
                                                loadPoint
                                                loadDate
                                                startDate
                                                endDate
                                                trackerLastConnectionTime
                                                trackerLastLocation
                                                tripFreightAlertRuleSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tenantAlertRule {
                                                                id
                                                                name
                                                            }
                                                        }
                                                    }
                                                }
                                                tripFreightTrackerSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tracker {
                                                                id
                                                                serialNumber
                                                                activatedDate
                                                                model
                                                                brand
                                                                lastConnectionTime
                                                                lastBatteryDeviceTime
                                                                lastBatteryValue
                                                                lastTemperatureDeviceTime
                                                                lastTemperatureValue
                                                                lastExternalTemperatureDeviceTime
                                                                lastExternalTemperatureValue
                                                                lastProbeTemperatureDeviceTime
                                                                lastProbeTemperatureValue
                                                                lastHumidityDeviceTime
                                                                lastHumidityValue
                                                                lastLightDeviceTime
                                                                lastLightValue
                                                                lastShockDeviceTime
                                                                lastShockValue
                                                                lastLocationDeviceTime
                                                                lastLatitude
                                                                lastLongitude
                                                                lastLocationDescription
                                                            }
                                                            name
                                                            lowTemperature
                                                            highTemperature
                                                            lowHumidity
                                                            highHumidity
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            id: this.myNotes.freightId,
                            notes: validateNote(this.myNotes.notes) ? this.myNotes.notes : null
                        }
                    }
                })
                .then(response => {
                    const payload = {
                        color: "blue",
                        message: this.$t("trips.freight_edit"),
                        icon: "done"
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.loadingNotes = false;
                    this.cancelNoteDialog();
                });
        },
        deleteFreight(id) {
            this.loadingDeleteBtn = true;
            let payload = {
                id: id
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation deleteTripFreight($input: DeleteTripFreightInput!) {
                            deleteTripFreight(input: $input) {
                                trip {
                                    id
                                    tripFreightSet {
                                        edges {
                                            node {
                                                id
                                                referenceNumber
                                                orderNumber
                                                containerNumber
                                                airWaybillNumber
                                                billOfLadingNumber
                                                goods
                                                term
                                                estimatedDepartureDate
                                                estimatedArrivalDate
                                                actualDepartureDate
                                                actualArrivalDate
                                                tripFreightAlertRuleSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tenantAlertRule {
                                                                id
                                                                name
                                                            }
                                                        }
                                                    }
                                                }
                                                tripFreightTrackerSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tracker {
                                                                id
                                                                serialNumber
                                                                activatedDate
                                                                lastConnectionTime
                                                                lastBatteryDeviceTime
                                                                lastBatteryValue
                                                                lastTemperatureDeviceTime
                                                                lastTemperatureValue
                                                                lastExternalTemperatureDeviceTime
                                                                lastExternalTemperatureValue
                                                                lastProbeTemperatureDeviceTime
                                                                lastProbeTemperatureValue
                                                                lastHumidityDeviceTime
                                                                lastHumidityValue
                                                                lastLightDeviceTime
                                                                lastLightValue
                                                                lastShockDeviceTime
                                                                lastShockValue
                                                                lastLocationDeviceTime
                                                                lastLatitude
                                                                lastLongitude
                                                                lastLocationDescription
                                                            }
                                                            name
                                                            lowTemperature
                                                            highTemperature
                                                            lowHumidity
                                                            highHumidity
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    const payload = {
                        color: "error",
                        message: this.$t("trips.freight_delete"),
                        icon: "warning"
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.loadingDeleteBtn = false;
                    this.cancelDeleteDialog();
                    this.closeFreightForm();
                    this.$emit("refresh-trip");
                    // this.$apollo.queries.tripFreights.refetch();
                });
        },
        editItem(item) {
            let trackers = item.tripFreightTrackerSet.edges.map(e => e.node.tracker.id);
            let alertRules = item.tripFreightAlertRuleSet.edges.map(e => e.node?.tenantAlertRule?.id);

            this.currentTrackers = item.tripFreightTrackerSet.edges.map(e => {
                return {
                    id: e.node.tracker.id,
                    serialNumber: e.node.tracker.serialNumber
                };
            });

            // awb number replace to xxx-xxxxxxx
            let awbNum = item.airWaybillNumber ? item.airWaybillNumber.replace(/(.{3})(.{0})/, "$1-$2") : null;
            this.item = {
                id: item.id,
                estimatedArrivalDate: this.getDate(item.estimatedArrivalDatel) || null,
                estimatedArrivalTime: item.estimatedArrivalDate ? this.getTime(item.estimatedArrivalDate) : null,
                estimatedDepartureDate: this.getDate(item.estimatedDepartureDate) || null,
                estimatedDepartureTime: item.estimatedDepartureDate ? this.getTime(item.estimatedDepartureDate) : null,
                referenceNumber: item.referenceNumber,
                orderNumber: item.orderNumber,
                awb: awbNum,
                containerNumber: item.containerNumber,
                goods: item.goods,
                term: item.term,
                contact: item.contact,
                loadPoint: item.loadPoint,
                startDate: this.formatDateTime(item.startDate) || null,
                endDate: this.formatDateTime(item.endDate) || null,
                /* startTime: this.getTime(item.startDate) || null,
                endTime: this.getTime(item.endDate) || null, */
                loadDate: this.formatDateTime(item.loadDate) || null,
                billOfLading: item.billOfLadingNumber,
                trackers: trackers,
                alerts: alertRules
            };
            this.toggleTitle = true;
            this.createDialog = true;
        },
        editFreight(value) {
            this.loadingCreateEditBtn = true;

            let payload = {
                id: value.id,
                referenceNumber: value.referenceNumber,
                billOfLadingNumber: value.billOfLading,
                orderNumber: value.orderNumber,
                containerNumber: value.containerNumber,
                airWaybillNumber: this.removeHifenFromAirwaybill(value.awb),
                contact: value.contact,
                loadPoint: value.loadPoint,
                loadDate: helper.convertDateToUTC(value.loadDate),
                startDate: helper.convertDateTimeToUTC(value.startDate),
                endDate: helper.convertDateTimeToUTC(value.endDate),
                goods: value.goods,
                term: value.term,
                estimatedDepartureDate: helper.concatDateAndTimeAndConvertToUTC(
                    value.estimatedDepartureDate,
                    value.estimatedDepartureTime
                ),
                estimatedArrivalDate: helper.concatDateAndTimeAndConvertToUTC(
                    value.estimatedArrivalDate,
                    value.estimatedArrivalTime
                ),
                trackers: value.trackers,
                alerts: value.alerts ? value.alerts : null
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation editTripFreight($input: EditTripFreightInput!) {
                            editTripFreight(input: $input) {
                                trip {
                                    id
                                    tripFreightSet {
                                        edges {
                                            node {
                                                id
                                                referenceNumber
                                                orderNumber
                                                containerNumber
                                                airWaybillNumber
                                                billOfLadingNumber
                                                goods
                                                term
                                                contact
                                                loadPoint
                                                loadDate
                                                startDate
                                                endDate
                                                trackerLastConnectionTime
                                                trackerLastLocation
                                                tripFreightAlertRuleSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tenantAlertRule {
                                                                id
                                                                name
                                                            }
                                                        }
                                                    }
                                                }
                                                tripFreightTrackerSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tracker {
                                                                id
                                                                serialNumber
                                                                activatedDate
                                                                model
                                                                brand
                                                                lastConnectionTime
                                                                lastBatteryDeviceTime
                                                                lastBatteryValue
                                                                lastTemperatureDeviceTime
                                                                lastTemperatureValue
                                                                lastExternalTemperatureDeviceTime
                                                                lastExternalTemperatureValue
                                                                lastProbeTemperatureDeviceTime
                                                                lastProbeTemperatureValue
                                                                lastHumidityDeviceTime
                                                                lastHumidityValue
                                                                lastLightDeviceTime
                                                                lastLightValue
                                                                lastShockDeviceTime
                                                                lastShockValue
                                                                lastLocationDeviceTime
                                                                lastLatitude
                                                                lastLongitude
                                                                lastLocationDescription
                                                            }
                                                            name
                                                            lowTemperature
                                                            highTemperature
                                                            lowHumidity
                                                            highHumidity
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(response => {
                    const payload = {
                        color: "blue",
                        message: this.$t("trips.freight_edit"),
                        icon: "done"
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                    //this.$apollo.queries.tripFreights.refetch();
                    //update the tripFreightSet
                    this.computedTripFreights = response.data.editTripFreight.trip.tripFreightSet;
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    this.error = error;
                })
                .finally(() => {
                    this.item = {};
                    this.loadingCreateEditBtn = false;
                    this.closeFreightForm();
                    this.$emit("refresh-trip");
                    // this.$apollo.queries.tripFreights.refetch();
                });
        },
        closeFreightForm() {
            this.createDialog = false;
            this.$refs.freightForm.resetForm();
        },
        getDate(date) {
            return date?.slice(0, 10);
        },
        getTime(date) {
            return this.formatDateTime(date)?.slice(11, 16);
        },
        redirectTrip() {
            this.$router.push({
                name: "trips",
                query: { id: this.trip.id }
            });
        },
        redirectFreight(id) {
            this.$router.push({
                name: "freights",
                query: { id: id }
            });
        },
        async getMoreData(freight) {
            if (this.trip.transportMode === "SEA" && this.hasPortBasePermission) {
                this.portBaseEvents = [
                    {
                        id: "9",
                        text: "headers.terminal",
                        value: "",
                        cols: {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        id: "10",
                        text: "headers.arrival_date",
                        value: "",
                        cols: {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        id: "11",
                        text: "headers.discharge",
                        value: "",
                        cols: {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        id: "12",
                        text: "headers.inspection",
                        value: "",
                        cols: {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 2,
                            xl: 2
                        }
                    }
                ];

                const queryFragment = gql`
                    query TerminalInformation($billOfLading: String, $containerNumber: String) {
                        terminalInformation(billOfLading: $billOfLading, containerNumber: $containerNumber) {
                            arrivalStatusDate
                            dischargedDate
                            inspection
                            terminalName
                        }
                    }
                `;

                try {
                    let result = await this.$apollo.query({
                        query: queryFragment,
                        variables: {
                            billOfLading: freight.billOfLadingNumber,
                            containerNumber: freight.containerNumber
                        },
                        fetchPolicy: "network-only"
                    });

                    if (result.data.terminalInformation) {
                        this.portBaseEvents = [];
                        let {
                            arrivalStatusDate,
                            dischargedDate,
                            inspection,
                            terminalName
                        } = result.data.terminalInformation;
                        this.portBaseEvents.push({
                            id: "9",
                            text: "headers.terminal",
                            value: terminalName ? terminalName : "",
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 6,
                                lg: 2,
                                xl: 2
                            }
                        });

                        this.portBaseEvents.push({
                            id: "10",
                            text: "headers.arrival_date",
                            value: arrivalStatusDate ? this.formatDateTime(arrivalStatusDate) : "",
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 6,
                                lg: 2,
                                xl: 2
                            }
                        });

                        this.portBaseEvents.push({
                            id: "11",
                            text: "headers.discharge",
                            value: dischargedDate ? this.formatDateTime(dischargedDate) : "",
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 6,
                                lg: 2,
                                xl: 2
                            }
                        });

                        this.portBaseEvents.push({
                            id: "12",
                            text: "headers.inspection",
                            value: inspection ? inspection : "",
                            cols: {
                                xs: 12,
                                sm: 6,
                                md: 6,
                                lg: 2,
                                xl: 2
                            }
                        });
                    }
                    // return result.data.terminalInformation;
                } catch (error) {
                    console.error(error);
                    return null;
                }
            }
        }
    }
};
</script>

<style scope>
.v-expansion-panel-content__wrap {
    padding: 0% !important;
}
.v-expansion-panel:not(:first-child)::after {
    border-top: none !important;
}
.outlined {
    outline: 1px solid #e6e9f5; /* Blue outline, 2px thick */
    outline-offset: 0px; /* Distance between the outline and the element */
}
.txtIndex {
    max-height: 19px;
    font-size: 12.4px;
    font-weight: 720;
    color: #a4a4a8;
}
.txtBody {
    max-height: 19px;
    font-size: 12.3px;
    font-weight: 650;
}
.maxHeight {
    max-height: 18px;
}
.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
}
.contentBg {
    background-color: #f9fafe;
}
.cardContainer {
    margin-bottom: 12px;
}
.cardContainer::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #cfd0d5ed;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color);
}
.textTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textTitle2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
.textHeader {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #555464;
    margin-bottom: 1px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textDetails {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.dialogCardContainer {
    max-width: 100%;
    max-height: 500px;
    overflow-y: auto;
}
.selectCursor {
    cursor: pointer;
}
</style>
