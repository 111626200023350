<template>
    <v-container>
        <h4 class="mb-2">
            <span>{{ $t("alert_rules.where") }}</span>
        </h4>

        <!-- <b class="mt-4 mb-3">When:</b> -->
        <v-expand-transition class="py-0">
            <v-row dense v-if="!hideAdvancedInfo">
                <v-col cols="12" sm="12" md="3" lg="3">
                    <v-card
                        :color="location.Departure ? 'primary' : 'white'"
                        class="my-2 py-5 d-flex align-center justify-center"
                        @click="
                            updateSelectedLocation(
                                location.Departure,
                                'departure'
                            )
                        "
                    >
                        <span
                            :class="
                                location.Departure
                                    ? 'white--text'
                                    : 'black-text'
                            "
                            >{{ $t("alert_rules.departure") }}</span
                        >
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" v-if="isSensorTrigger">
                    <v-card
                        :color="location.InTransit ? 'primary' : 'white'"
                        @click="
                            updateSelectedLocation(
                                location.InTransit,
                                'inTransit'
                            )
                        "
                        class="my-2 py-5 d-flex align-center justify-center"
                    >
                        <span
                            :class="
                                location.InTransit
                                    ? 'white--text'
                                    : 'black-text'
                            "
                        >
                            {{ $t("alert_rules.transit") }}
                        </span>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3">
                    <v-card
                        :color="location.Arrival ? 'primary' : 'white'"
                        @click="
                            updateSelectedLocation(location.Arrival, 'arrival')
                        "
                        class="my-2 py-5 d-flex align-center justify-center"
                    >
                        <span
                            :class="
                                location.Arrival ? 'white--text' : 'black-text'
                            "
                        >
                            {{ $t("alert_rules.arrival") }}
                        </span>
                    </v-card>
                </v-col>
            </v-row>
        </v-expand-transition>
        <v-row class="py-0 my-0">
            <v-col cols="12">
                <v-switch
                    v-model="hideAdvancedInfo"
                    class="text-no-wrap"
                    :label="$t('general.advanced')"
                >
                </v-switch>
            </v-col>
        </v-row>
        <v-expand-transition v-if="hideAdvancedInfo">
            <v-row dense class="mb-3 mt-1">
                <v-col cols="12"
                    ><h4>{{ $t("alert_rules.alert_when") }}</h4></v-col
                >
                <v-col
                    cols="12"
                    sm="12"
                    md="3"
                    lg="3"
                    class="mt-1 "
                    v-for="(item, i) in isSensorTrigger
                        ? locationSensorConditionChoices
                        : locationConditionChoices"
                    :key="i"
                >
                    <v-card
                        class="my-2 py-5 d-flex align-center justify-center"
                        :color="
                            item.value === location.Condition ? 'primary' : ''
                        "
                        @click="updateCondition(item.value)"
                    >
                        <span
                            :class="
                                item.value === location.Condition
                                    ? 'white--text'
                                    : 'black-text'
                            "
                        >
                            {{ item.text }}
                        </span>
                    </v-card>
                </v-col>
                <v-col cols="12" class="mt-1"
                    ><h4>{{ $t("home.locations") }}</h4></v-col
                >
                <v-col cols="12">
                    <v-row dense class="mt-1">
                        <v-col cols="12" sm="12" md="3" lg="3">
                            <v-card
                                :color="location.Origin ? 'primary' : 'white'"
                                class="my-2 py-5 d-flex align-center justify-center"
                                @click="
                                    updateSelectedLocation(
                                        location.Origin,
                                        'origin'
                                    )
                                "
                            >
                                <span
                                    :class="
                                        location.Origin
                                            ? 'white--text'
                                            : 'black-text'
                                    "
                                    >{{ $t("trips.origin") }}</span
                                >
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" lg="3">
                            <v-card
                                :color="
                                    location.Destination ? 'primary' : 'white'
                                "
                                @click="
                                    updateSelectedLocation(
                                        location.Destination,
                                        'destination'
                                    )
                                "
                                class="my-2 py-5 d-flex align-center justify-center"
                            >
                                <span
                                    :class="
                                        location.Destination
                                            ? 'white--text'
                                            : 'black-text'
                                    "
                                >
                                    {{ $t("trips.destination") }}
                                </span>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" lg="3">
                            <v-card
                                :color="location.Public ? 'primary' : 'white'"
                                @click="
                                    updateSelectedLocation(
                                        location.Public,
                                        'public'
                                    )
                                "
                                class="my-2 py-5 d-flex align-center justify-center"
                            >
                                <span
                                    :class="
                                        location.Public
                                            ? 'white--text'
                                            : 'black-text'
                                    "
                                >
                                    {{ $t("alert_rules.public") }}
                                </span>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="mt-1"
                    ><h4>{{ $t("alert_rules.partners_locations") }}</h4></v-col
                >
                <v-col cols="12">
                    <v-row dense class="mt-1">
                        <v-col
                            v-for="partnerTypeChoice in partnerTypeChoices"
                            :key="partnerTypeChoice.value"
                            cols="2"
                            sm="2"
                            md="2"
                            lg="2"
                        >
                            <v-card
                                :color="
                                    findPartner(partnerTypeChoice.value)
                                        ? 'primary'
                                        : ''
                                "
                                @click="
                                    updateSelectedPartner(
                                        partnerTypeChoice.value
                                    )
                                "
                                class="py-5 d-flex align-center justify-center"
                            >
                                <span
                                    :class="
                                        findPartner(partnerTypeChoice.value)
                                            ? 'white--text'
                                            : 'black-text'
                                    "
                                >
                                    {{ partnerTypeChoice.text }}
                                </span>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="mt-1"
                    ><h4>{{ $t("alert_rules.custom_locations") }}</h4></v-col
                >
                <v-col cols="9" v-if="hideAdvancedInfo">
                    <v-row dense class="mt-1">
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="location.Custom"
                                :loading="
                                    $apollo.queries.tenantLocations.loading
                                "
                                :items="
                                    tenantLocations ? tenantLocations.edges : []
                                "
                                :no-data-text="$t('loading.no_available')"
                                item-text="node.name"
                                item-value="node.rawId"
                                outlined
                                deletable-chips
                                multiple
                                chips
                                height="30"
                                hide-details
                                hide-selected
                                @change="updateLocation"
                            >
                                <template v-slot:item="data">
                                    <v-row class="ma-0 body-2" dense>
                                        <v-col
                                            cols="10"
                                            class="text-truncate pl-0 ml-0"
                                        >
                                            {{ data.item.node.name }}
                                        </v-col>
                                        <v-col cols="2">
                                            <span class="grey--text mr-1"
                                                ><i>{{
                                                    selectLabelByType(
                                                        data.item.node.locationType.toLowerCase()
                                                    )
                                                }}</i></span
                                            >
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:append-outer>
                                    <v-btn
                                        class="mt-0"
                                        icon
                                        small
                                        rounded
                                        outlined
                                        color="primary"
                                        @click="openAddLocation = true"
                                        ><v-icon>add</v-icon>
                                    </v-btn>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
                <AddLocation
                    :openAddLocationDialog="openAddLocation"
                    v-on:closeMap="openAddLocation = false"
                    v-on:updateLocationsTable="
                        $apollo.queries.tenantLocations.refetch()
                    "
                >
                </AddLocation>
            </v-row>
        </v-expand-transition>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import AddLocation from "@/components/locations/create/AddLocation.vue";
export default {
    props: {
        Location: {
            type: Object
        },
        isSensorTrigger: {
            type: Boolean
        },
        triggerCopy: {
            type: Object,
            default: null
        },
        editMode: {
            type: Boolean
        }
    },
    components: {
        AddLocation
    },
    data() {
        return {
            hideAdvancedInfo: null,
            enablePartnerLocations: false,
            partnerTypeChoices: [
                { text: this.$t("headers.consignor"), value: "consignor" },
                { text: this.$t("headers.consignee"), value: "consignee" },
                { text: this.$t("headers.forwarder"), value: "forwarder" },
                { text: this.$t("headers.carrier"), value: "carrier" },
                { text: this.$t("home.other"), value: "other" }
            ],
            locationConditionChoices: [
                { text: this.$t("general.enter"), value: "enter" },
                { text: this.$t("general.exit"), value: "exit" },
                { text: this.$t("general.inside"), value: "inside" },
                { text: this.$t("general.outside"), value: "outside" }
            ],
            locationSensorConditionChoices: [
                { text: this.$t("general.inside"), value: "inside" },
                { text: this.$t("general.outside"), value: "outside" }
            ],
            openAddLocation: false,
            buttonGroup: 0,
            partnerLocations: []
        };
    },
    apollo: {
        tenantLocations: {
            query: gql`
                query tenantLocations(
                    $search: String
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                ) {
                    tenantLocations(
                        search: $search
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        locationType_In: [
                            "tenant_location"
                            "partner_location"
                            "private_location"
                        ]
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                locationType
                                isTrackerStockLocation
                                fullAddress
                                city
                                state
                                country
                                rawId
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    orderBy: "name"
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            debounce: 800,
            update: data => {
                return data.tenantLocations;
            },
            skip: false
        }
    },
    computed: {
        location: {
            get() {
                return this.Location;
            },
            set(val) {
                this.$emit("updateLocation", val);
            }
        }
    },
    mounted() {
        if (
            this.location.Condition == "inside" ||
            this.location.Condition == "outside" ||
            this.location.Condition == "enter" ||
            this.location.Condition == "exit" ||
            this.location.Origin == true ||
            this.location.Destination == true ||
            this.location.Public == true ||
            this.location.Partners.length > 0 ||
            this.location.Custom.length > 0
        ) {
            this.hideAdvancedInfo = true;
        } else {
            this.hideAdvancedInfo = false;
        }
        this.partnerLocations = this.location.Partners;
    },
    watch: {
        hideAdvancedInfo(val) {
            if (val == false) {
                this.location.Origin = false;
                this.location.Destination = false;
                this.location.Public = false;
                this.location.Custom = [];
                this.location.Condition = null;
                this.partnerLocations = [];
                this.location.Partners = [];
                this.partnerLocations = [];

                if (this.editMode) {
                    this.location.Departure = this.triggerCopy?.Departure;
                    this.location.Arrival = this.triggerCopy?.Arrival;
                    this.location.InTransit = this.triggerCopy?.InTransit;
                }
                this.updateLocation();
            } else {
                this.location.Departure = false;
                this.location.Arrival = false;
                this.location.InTransit = false;

                if (this.editMode) {
                    this.location.Origin = this.triggerCopy?.Origin;
                    this.location.Destination = this.triggerCopy?.Destination;
                    this.location.Public = this.triggerCopy?.Public;
                    this.location.Custom = this.triggerCopy?.Custom;
                    this.location.Condition = this.triggerCopy?.Condition;
                    this.location.Partners = this.triggerCopy?.Partners;
                }
                this.updateLocation();
            }
        }
        /*  "location.Partners"(val) {
            console.log("val", val);
            this.partnerLocations = val;
        }, */
    },
    methods: {
        updateSelectedLocation(val, type) {
            if (type === "departure") {
                this.location.Departure = !val;
            } else if (type === "arrival") {
                this.location.Arrival = !val;
            } else if (type === "inTransit") {
                this.location.InTransit = !val;
            } else if (type === "destination") {
                this.location.Destination = !val;
            } else if (type === "origin") {
                this.location.Origin = !val;
            } else if (type === "public") {
                this.location.Public = !val;
            }
            this.updateLocation();
        },
        findPartner(val) {
            let _partner = this.partnerLocations.includes(val);
            return _partner;
        },
        updateSelectedPartner(val) {
            if (this.findPartner(val)) {
                this.partnerLocations = this.partnerLocations.filter(
                    partner => partner !== val
                );
            } else {
                this.partnerLocations.push(val);
            }
            this.updateLocation();
        },
        updateCondition(val) {
            this.location.Condition = val;
            this.$emit("updateLocation", this.location);
        },
        updatePartners(val) {
            this.partnerLocations = val;
            this.updateLocation();
        },
        updateLocation() {
            this.location.Partners = this.partnerLocations;
            this.$emit("updateLocation", this.location);
        },
        selectLabelByType(val) {
            if (val === "tenant_location") return "Public";
            if (val === "partner_location") return "Partner";
            if (val === "private_location") return "";
        },
        reset() {
            this.location = {
                Destination: false,
                Origin: false,
                Departure: false,
                Arrival: false,
                InTransit: false,
                Public: false,
                Partners: [],
                Custom: []
            };
        },
        changeEnablePartnerLocations() {
            this.enablePartnerLocations = !this.enablePartnerLocations;
            if (!this.enablePartnerLocations) {
                this.location.Partners = [];
            }
        }
    }
};
</script>
