<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>
import helper from "@/utils/helper.js";

export default {
    title: "Qx Bookings",
    components: {
    },

    apollo: {},

    data() {
        return {

        };
    },

    computed: {},
    watch: {
        me() {
            return helper.me();
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {

    },
    methods: {
    }
};
</script>
