<template>
    <v-sheet class="background mb-10">
        <div v-if="!canAccess">
            <NoPermission></NoPermission>
        </div>
        <v-expansion-panels v-else flat class="mt-5 mb-4">
            <v-expansion-panel class=" elevation-0 rounded-lg outlined">
                <v-expansion-panel-header>
                    <v-row dense>
                        <v-col cols="auto">
                            <v-img
                                :src="require('@/assets/rule_settings.png')"
                                max-width="25"
                                max-height="25"
                                class="mr-2"
                            ></v-img>
                        </v-col>
                        <v-col cols="auto" class="textTitle">
                            {{ $t("general.alerts_details") }}
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-4">
                    <LoadingBar v-if="loadingHeaders"> </LoadingBar>
                    <v-row v-else>
                        <v-col cols="12">
                            <v-card
                                v-for="(alert, i) in alertSetHeaders"
                                :key="i"
                                class="contentBg rounded-lg mb-2 pb-2"
                                outlined
                            >
                                <v-row class="px-3 mt-1" align="stretch">
                                    <v-col v-for="(el, j) in alertDetailsContent(alert)" :key="j">
                                        <div
                                            class="textHeader
                                            "
                                        >
                                            {{ el.tag }}
                                        </div>
                                        <div class="textBody text-capitalize">{{ el.value }}</div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-card class="mb-2 rounded-lg outlined" flat>
            <v-card-title>
                <v-row dense class="px-2">
                    <v-col cols="auto">
                        <v-img
                            :src="require('@/assets/warning.png')"
                            max-width="25"
                            max-height="25"
                            class="mr-2"
                        ></v-img>
                    </v-col>
                    <v-col cols="auto" class="textTitle">
                        {{ $t("headers.alert_trigger") }}
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="d-flex justify-end">
                        <v-row>
                            <v-col cols="auto">
                                <v-switch
                                    class="mt-2"
                                    v-model="toggle"
                                    inset
                                    :label="
                                        toggle ? $t('trips.view_unresolved_alerts') : $t('trips.view_alert_history')
                                    "
                                ></v-switch>
                            </v-col>

                            <v-col cols="auto">
                                <v-btn
                                    v-if="canEdit"
                                    :disabled="hasNoData"
                                    color="primary"
                                    @click.stop="unresolvedDialog = true"
                                    style="height: 47px;"
                                    class="elevation-0 outlined"
                                >
                                    {{ $t("trips.mark_all") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="pa-4">
                <v-row class="mt-2" v-if="$apollo.queries.tripAlerts.loading && hasNoData">
                    <LoadingBar></LoadingBar>
                </v-row>

                <div v-if="hasNoData && !$apollo.queries.tripAlerts.loading">
                    <NoData></NoData>
                </div>
                <v-card flat color="transparent" v-else>
                    <!--  <v-row v-if="$apollo.queries.tripAlerts.loading" justify="center" align="center">
                        <v-col cols="4">
                            <LoadingBar></LoadingBar>
                        </v-col>
                    </v-row> -->
                    <v-card-text>
                        <v-card
                            v-for="item in tripAlerts.edges"
                            :key="item.node.id"
                            class="contentBg rounded-lg mb-2 pb-3"
                            outlined
                        >
                            <v-row class="px-3 mt-1" align="stretch">
                                <!--  <v-col cols="auto">
                                    <v-icon size="22" :color="item.node.isResolved ? '' : 'red'" class="mt-1"
                                        >warning</v-icon
                                    >
                                </v-col> -->
                                <v-col
                                    v-for="(body, i) in getAlertContentBody(item.node)"
                                    :key="i"
                                    :cols="body.cols.xs"
                                    :xs="body.cols.xs"
                                    :sm="body.cols.sm"
                                    :md="body.cols.md"
                                    :lg="body.cols.lg"
                                    :xl="body.cols.xl"
                                >
                                    <div v-if="body.text !== 'alertTrigger'" class="textTruncate">
                                        <div class="textHeader">
                                            {{ body.tag }}
                                        </div>
                                        <v-btn
                                            v-if="body.text === 'freight' && body.value"
                                            small
                                            text
                                            @click="goToFreight(body.value.id, trip.id)"
                                            class="px-0"
                                        >
                                            {{ textTruncate(body.value.referenceNumber, 25) }}
                                        </v-btn>
                                        <!-- <div
                                            v-if="body.text === 'freight'"
                                            @click="goToFreight(body.value.id, trip.id)"
                                            class="cursorLink"
                                        >
                                            {{ textTruncate(body.value.referenceNumber, 25) }}
                                        </div> -->
                                        <div class="textBody text-capitalize" v-else>
                                            {{ textTruncate(body.value, 25) }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="textHeader">
                                            {{ body.tag }}
                                        </div>
                                        <div v-for="(alert, i) in body.value" :key="i" class="d-inline-block">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-img
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :src="alert.img"
                                                        max-width="18"
                                                        max-height="18"
                                                        class="mr-2"
                                                    ></v-img>
                                                </template>
                                                <span>{{ alert.tag }}</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto">
                                    <v-menu
                                        offset-y
                                        transition="slide-x-reverse-transition"
                                        width="250px"
                                        attach
                                        left
                                        bottom
                                        close-on-click
                                        nudge-top="-3"
                                    >
                                        <template
                                            v-slot:activator="{
                                                on,
                                                attrs
                                            }"
                                        >
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list class="white">
                                            <v-list-item>
                                                <AlertRuleDetailDialog
                                                    :tripAlertId="item.node.id"
                                                    :alertTime="item.node.alertTime"
                                                    :deviceTime="item.node.deviceTime"
                                                    :tripId="trip.id"
                                                    :plain="true"
                                                    :icon="false"
                                                ></AlertRuleDetailDialog>
                                            </v-list-item>
                                            <v-list-item v-if="!item.node.isResolved && canEdit">
                                                <v-btn plain @click="editedItem(item.node.id)">
                                                    <v-icon class="mr-2">
                                                        done
                                                    </v-icon>
                                                    <span>
                                                        {{ $t("general.resolve") }}
                                                    </span>
                                                </v-btn>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-btn plain @click="redirectToAlertRule(item.node)">
                                                    <v-icon class="mr-2">
                                                        link
                                                    </v-icon>
                                                    <span>
                                                        {{ $t("general.go_to_alert") }}
                                                    </span>
                                                </v-btn>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn
                            v-if="hasMoreData"
                            v-intersect="onLoadMoreTriggerIntersect"
                            :disabled="!hasMoreData"
                            :loading="$apollo.queries.tripAlerts.loading"
                            plain
                            class="mb-5"
                            @click="loadMore"
                        >
                            Load more
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-card-text>
        </v-card>
        <v-dialog v-model="editAlertsDialog" width="500">
            <v-card>
                <v-card-title class="primary">
                    <v-icon color="white">notifications</v-icon>
                    <div class="white--text">
                        {{ $t("trips.edit_alerts") }}
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-form v-model="editAlertsValidate">
                        <h3 class="mt-2">
                            {{ $t("trips.sure_alerts") }}
                        </h3>
                        <v-textarea
                            v-model="editItem.note"
                            counter="500"
                            outlined
                            :placeholder="$t('trips.add_notes')"
                            :rules="[rules.maxLength(500)]"
                        >
                        </v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editAlertsDialog = false">{{ $t("general.cancel") }}</v-btn>
                    <v-btn
                        :loading="loading"
                        :disabled="!editAlertsValidate"
                        elevation="0"
                        color="primary"
                        @click="save(editItem)"
                        >{{ $t("general.save") }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="unresolvedDialog" width="500">
            <v-card>
                <v-card-title class="primary">
                    <v-icon color="white">notifications</v-icon>
                    <div class="white--text">
                        {{ $t("trips.edit_alerts") }}
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-form v-model="editAlertsValidate">
                        <h3 class="mt-2">
                            {{ $t("trips.sure_all_alerts") }}
                        </h3>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="unresolvedDialog = false">{{ $t("general.cancel") }}</v-btn>
                    <v-btn
                        :loading="loading"
                        :disabled="!editAlertsValidate"
                        elevation="0"
                        color="primary"
                        @click="saveAll()"
                        >{{ $t("general.save") }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-sheet>
</template>

<script>
import gql from "graphql-tag";
import helper from "../../../utils/helper";
import AlertRuleDetailDialog from "@/components/alertrules/other/AlertRuleDetailDialog.vue";
import { TripPermissions } from "@/core/enum";
import NoPermission from "@/components/base/NoPermission.vue";
import LoadingBar from "@/components/base/LoadingBar.vue";
import Config from "@/utils/config.json";
import NoData from "@/components/base/NoData.vue";

export default {
    props: {
        trip: {
            type: Object,
            required: true
        },
        tripIsLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        isSharedTrip: {
            type: Boolean,
            required: false,
            default: false
        },
        organisationPermissions: {
            type: Array,
            required: true,
            default: function() {
                return [];
            }
        },
        freight: {
            type: Object,
            required: false
        }
    },
    components: {
        NoPermission,
        LoadingBar,
        AlertRuleDetailDialog,
        NoData
    },
    apollo: {
        tripAlerts: {
            query: gql`
                query TripAlerts(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $trip: ID
                    $allAlerts: Boolean
                    $isResolved: Boolean
                ) {
                    tripAlerts(
                        trip: $trip
                        allAlerts: $allAlerts
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        isResolved: $isResolved
                    ) {
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                deviceTime
                                alertTime
                                resolvedTime
                                isResolved
                                note
                                resolvedBy {
                                    id
                                    fullName
                                }
                                alertJson
                                tripFreight {
                                    id
                                    referenceNumber
                                }
                                tenantAlertRule {
                                    id
                                    name
                                    isStandardAlert
                                    standardAlertCode
                                    shouldNotifyPartner
                                    shouldNotifyForEveryRecord
                                    tenantAlertRuleActionGroupSet {
                                        edges {
                                            node {
                                                id
                                                tenantActionGroup {
                                                    id
                                                    name
                                                }
                                            }
                                        }
                                    }
                                }
                                trackerMergedSensorData {
                                    id
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    first: 10,
                    trip: this.trip?.id,
                    isResolved: this.toggle ? false : null,
                    allAlerts: true
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tripAlerts,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            // debounce: 800,
            skip() {
                return !this.canAccess;
            }
        },
        // tripFreights: {
        //     query: gql`
        //         query trip($tripId: ID!, $globalIdIn: [ID]) {
        //             trip(id: $tripId) {
        //                 id
        //                 tripFreightSet(globalIdIn: $globalIdIn) {
        //                     edges {
        //                         node {
        //                             id
        //                             tripFreightAlertRuleSet {
        //                                 edges {
        //                                     node {
        //                                         id
        //                                         tenantAlertRule {
        //                                             id
        //                                             name
        //                                             alertSeverity
        //                                             isStandardAlert
        //                                             tenantAlertRuleTriggerSet {
        //                                                 edges {
        //                                                     node {
        //                                                         id
        //                                                         triggerJson
        //                                                     }
        //                                                 }
        //                                             }
        //                                         }
        //                                     }
        //                                 }
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     `,
        //     variables() {
        //         // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
        //         return {
        //             tripId: this.trip?.id
        //         };
        //     },
        //     // Additional options here
        //     fetchPolicy: "cache-and-network",
        //     nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
        //     update: data => data.trip?.tripFreightSet,
        //     watchLoading(isLoading) {
        //         this.$emit("loading", isLoading);
        //     },
        //     // result({ data, loading }) {
        //     //     if (!loading && data.trip?.tripFreightSet?.edges.length > 0) {
        //     //         data.trip.tripFreightSet.edges.forEach(item => {
        //     //             item.node.tripFreightAlertRuleSet.edges.forEach(alert => {
        //     //                 if (alert?.node?.tenantAlertRule) {
        //     //                     let _triggers = this.checkAlert(
        //     //                         alert?.node?.tenantAlertRule?.tenantAlertRuleTriggerSet?.edges[0]?.node?.triggerJson
        //     //                     );
        //     //                     if (!this.alertSetHeaders.find(e => e.name === alert.node?.tenantAlertRule?.name)) {
        //     //                         this.alertSetHeaders.push({
        //     //                             name: alert.node?.tenantAlertRule?.name,
        //     //                             type: alert?.node?.tenantAlertRule?.isStandardAlert
        //     //                                 ? "Standard Alert"
        //     //                                 : "Custom Alert",
        //     //                             severity: alert.node?.tenantAlertRule?.alertSeverity,
        //     //                             numberOfTriggers: _triggers.length
        //     //                         });
        //     //                     }
        //     //                 }
        //     //             });
        //     //         });
        //     //     }
        //     // },
        //     // debounce: 800,
        //     skip() {
        //         return !this.canAccess;
        //     }
        // },
        tenantAlertRules: {
            query: gql`
                query TenantAlertRules($tenantId: ID, $isStandardAlert: Boolean, $isEnabled: Boolean) {
                    tenantAlertRules(tenantId: $tenantId, isStandardAlert: $isStandardAlert, isEnabled: $isEnabled) {
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantId: this.me?.tenant?.id,
                    isStandardAlert: true,
                    isEnabled: true
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenantAlertRules,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            // result({ data, loading }) {
            //     if (!loading && data.tenantAlertRules.edges.length > 0) {
            //         data.tenantAlertRules.edges.forEach(item => {
            //             this.alertSetHeaders.push({
            //                 name: item.node.name,
            //                 type: "Standard Alert",
            //                 severity: "Warning",
            //                 numberOfTriggers: 1
            //             });
            //         });
            //     }
            // },
            // debounce: 800,
            skip() {
                return !this.canAccess;
            }
        }
    },
    data() {
        return {
            // alertSetHeaders: [],
            toggle: true,
            panel: [0],
            // tripFreights: {},
            tripAlerts: { edges: [] },
            tenantAlertRules: {},
            /* hasTripAlertSet: false, */
            editAlertsValidate: false,
            editAlertsDialog: false,
            unresolvedDialog: false,
            alertSensor: null,
            loading: false,
            singleExpand: true,
            expanded: [],
            alertRule: {},
            editItem: {
                id: "",
                note: ""
            },
            detailDialog: false,
            headers: [
                {
                    text: this.$t("headers.alert"),
                    align: "left",
                    value: "node.tenantAlertRule.name",
                    sortable: true
                },
                {
                    text: this.$t("headers.device_time"),
                    align: "left",
                    value: "node.deviceTime",
                    sortable: true
                },
                {
                    text: this.$t("headers.trigger_time"),
                    align: "left",
                    value: "node.alertTime",
                    sortable: true
                },
                {
                    text: this.$t("headers.action_group"),
                    align: "left",
                    value: "actionGroup",
                    sortable: false
                },
                {
                    text: this.$t("headers.alert_trigger"),
                    align: "left",
                    value: "alertDescription",
                    sortable: false
                },
                {
                    text: this.$t("headers.resolved_time"),
                    align: "left",
                    value: "node.resolvedTime",
                    sortable: true
                },
                {
                    text: this.$t("headers.resolved_by"),
                    align: "left",
                    value: "resolvedBy",
                    sortable: false
                },
                {
                    text: this.$t("headers.actions"),
                    align: "left",
                    value: "actions",
                    sortable: false
                }
            ],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                listRequired: v => (v || "").length >= 1 || this.$t("rules.required"),
                minLength: len => v => (v || "").length >= len || this.$t("rules.require_at_least") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long")
            }
        };
    },
    computed: {
        alertSetHeaders() {
            let local = [];
            this.trip?.tripFreightSet?.edges.forEach(item => {
                item.node.tripFreightAlertRuleSet.edges.forEach(alert => {
                    if (alert?.node?.tenantAlertRule) {
                        let _triggers = this.checkAlert(
                            alert?.node?.tenantAlertRule?.tenantAlertRuleTriggerSet?.edges[0]?.node?.triggerJson
                        );
                        if (!local.find(e => e.name === alert.node?.tenantAlertRule?.name)) {
                            local.push({
                                name: alert.node?.tenantAlertRule?.name,
                                type: alert?.node?.tenantAlertRule?.isStandardAlert ? "Standard Alert" : "Custom Alert",
                                severity: alert.node?.tenantAlertRule?.alertSeverity,
                                numberOfTriggers: _triggers.length
                            });
                        }
                    }
                });
            });
            return local;
        },
        loadingHeaders() {
            return this.$apollo.queries.tenantAlertRules.loading || this.tripIsLoading;
        },
        me() {
            return helper.me();
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 1600;
        },
        hasNoData() {
            return this.tripAlerts?.edges?.length === 0;
        },
        hasTripAlertSet() {
            return this.tripAlerts?.edges?.length > 0;
        },
        canAccess() {
            //check partner has permission
            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_FREIGHT_ALERT,
                        this.organisationPermissions
                    )
                )
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        TripPermissions.VIEW_TRIP_FREIGHT_ALERT,
                        this.organisationPermissions
                    )
                )
                    return false;
                //check tenant has permission
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_FREIGHT_ALERT,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                // check is tenant admin
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                //check is tenant staff has permission
                if (!this.hasPermission(TripPermissions.VIEW_TRIP_FREIGHT_ALERT) && this.me?.tenant) return false;
            }
            return true;
        },
        canEdit() {
            if (this.trip?.status === "COMPLETED") return false;

            // if (this.isSharedTrip) return false;

            if (this.me.isPartnerUser && !this.me?.tenant) {
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.EDIT_TRIP_FREIGHT_ALERT,
                        this.organisationPermissions
                    )
                )
                    return false;
            } else {
                if (
                    this.organisationPermissions.length > 0 &&
                    !this.hasOrganisationPermission(
                        TripPermissions.EDIT_TRIP_FREIGHT_ALERT,
                        this.organisationPermissions
                    )
                )
                    return false;
                //check tenant has permission
                if (
                    !this.hasOrganisationPermission(
                        TripPermissions.MANAGE_TRIP_FREIGHT_ALERT,
                        this.me?.tenant?.organisationPermissions
                    )
                )
                    return false;
                // check is tenant admin
                if (this.me?.tenant && this.me?.isTenantAdmin) return true;
                //check is tenant staff has permission
                if (!this.hasPermission(TripPermissions.EDIT_TRIP_FREIGHT_ALERT) && this.me?.tenant) return false;
            }
            return true;
        },
        alerts() {
            if (this.toggle) {
                return this.tripAlerts?.edges?.filter(e => e.node);
            } else {
                return this.tripAlerts?.edges?.filter(e => e.node.isResolved === false);
            }
        },
        fullList() {
            let all = this.tripAlerts?.edges?.filter(e => e.node);

            return all;
        },
        unresolved() {
            let unresolve = this.tripAlerts?.edges?.filter(e => e.node.isResolved === false);
            return this.tripAlerts?.edges;
        },
        hasMoreData() {
            return this.tripAlerts?.pageInfo?.hasNextPage;
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.TripPermissions = TripPermissions;
        this.isEmpty = helper.isEmpty;
    },
    created() {
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.getTemperatureUnit = helper.getTemperatureSymbol;
        this.celsiusToFahrenheit = helper.celsiusToFahrenheit;
    },
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "trip_alert_tab_svw"
        });
        this.setUserProperties();
    },
    watch: {
        toggle: {
            handler: function() {
                this.tripAlerts = { edges: [] };
            },
            deep: true
        }
    },
    methods: {
        goToFreight(freight, trip) {
            this.$router.push({
                path: "/previewtrips",
                query: {
                    id: trip,
                    tab: "freights",
                    freight: freight
                }
            });
        },
        redirectToAlertRule(item) {
            if (!item.tenantAlertRule) return;

            //Alert Rule
            const { id, isStandardAlert } = item.tenantAlertRule;

            if (isStandardAlert) {
                this.$router.push({
                    name: "standardrules",
                    query: { type: this.trip.transportMode }
                });
            } else {
                this.$router.push({
                    name: "customrules",
                    query: { id: id }
                });
            }
        },
        filterAlerts(value) {
            switch (value) {
                case "0":
                    this.toggle = false;
                    break;
                case "1":
                    this.toggle = true;
                    break;
                case "2":
                    this.unresolvedDialog = true;
                    break;
            }
        },
        textTruncate(text, length) {
            if (text === null || text === undefined) {
                return;
            } else if (text.length > length) {
                return text.substring(0, length) + "...";
            } else {
                return text;
            }
        },
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("trip_alert_tab_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("trip_alert_tab_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        checkAlert(item) {
            if (!item) return [];
            let alert = [];
            let newObj = JSON.parse(item);

            if (newObj?.Location?.Condition) {
                alert.push({
                    type: "location",
                    tag: this.$t("general.location"),
                    img: require("@/assets/location_on.png")
                });
            }
            if (newObj?.Sensor?.length > 0) {
                newObj.Sensor.forEach(item => {
                    if (item.Type === "temperature") {
                        alert.push({
                            type: "temperature",
                            tag: this.$t("general.temperature"),
                            img: require("@/assets/thermostat.png")
                        });
                    }
                    if (item.Type === "humidity") {
                        alert.push({
                            type: "humidity",
                            tag: this.$t("general.humidity"),
                            img: require("@/assets/water_drop.png")
                        });
                    }
                    if (item.Type === "light") {
                        alert.push({
                            type: "light",
                            tag: this.$t("general.light"),
                            img: require("@/assets/lightbulb.png")
                        });
                    }
                    if (item.Type === "shock") {
                        alert.push({
                            type: "shock",
                            tag: this.$t("general.shock"),
                            img: require("@/assets/bolt.png")
                        });
                    }
                });
            }

            return alert;
        },
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        alertRuleDetail(item) {
            this.alertRule = { ...item };
            this.detailDialog = true;
        },
        onLoadMoreTriggerIntersect(entries, observer) {
            if (entries[0].isIntersecting && this.hasMoreData) {
                this.loadMore();
            }
        },
        loadMore() {
            if (this.hasMoreData) {
                this.$apollo.queries.tripAlerts.fetchMore({
                    variables: {
                        after: this.tripAlerts.pageInfo.endCursor
                    }
                });
            }
        },
        editedItem(id) {
            this.editItem = {
                id: id,
                note: ""
            };
            this.editAlertsDialog = true;
        },
        expandRow(item) {
            if (item.node && this.expanded.filter(val => val["node"]["id"] === item.node.id).length > 0) {
                this.expanded = [];
            } else {
                this.expanded = [];
                this.expanded.push(item);
            }
        },
        getAlertContentBody(item) {
            let _trigger = [];
            if (item.alertJson) {
                _trigger = this.checkAlert(item.alertJson);
            }

            let content = [
                {
                    tag: this.$t("headers.alert"),
                    text: "Alert",
                    value: item.tenantAlertRule.name,
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 3,
                        lg: 2,
                        xl: 2
                    }
                },
                {
                    tag: this.$t("headers.device_time"),
                    text: "triggerTime",
                    value: item.deviceTime ? this.formatDateTime(item.deviceTime) : " ",
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 3,
                        lg: 2,
                        xl: 2
                    }
                },
                {
                    tag: this.$t("headers.trigger_time"),
                    text: "alertTime",
                    value: item.alertTime ? this.formatDateTime(item.alertTime) : " ",
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 3,
                        lg: 2,
                        xl: 2
                    }
                },
                // {
                //     tag: this.$t("headers.action_group"),
                //     text: "actionGroup",
                //     value: item.actionGroup,
                //     cols: {
                //         xs: 12,
                //         sm: 6,
                //         md: 3,
                //         lg: 2,
                //         xl: 2
                //     }
                // },
                // {
                //     tag: this.$t("headers.alert_trigger"),
                //     text: "alertTrigger",
                //     value: _trigger,
                //     cols: {
                //         xs: 12,
                //         sm: 6,
                //         md: 3,
                //         lg: 1,
                //         xl: 1
                //     }
                // },
                {
                    tag: this.$t("headers.resolved_time"),
                    text: "resolvedTime",
                    value: item.resolvedTime ? this.formatDateTime(item.resolvedTime) : " ",
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 3,
                        lg: 2,
                        xl: 2
                    }
                },
                {
                    tag: this.$t("headers.resolved_by"),
                    text: "resolvedBy",
                    value: item.resolvedBy ? item.resolvedBy.fullName : " ",
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 3,
                        lg: 2,
                        xl: 2
                    }
                },
                {
                    tag: this.$t("headers.freight"),
                    text: "freight",
                    value: item.tripFreight ? item.tripFreight : null,
                    cols: {
                        xs: 12,
                        sm: 6,
                        md: 3,
                        lg: 1,
                        xl: 1
                    }
                }
            ];
            return content;
        },
        alertDetailsContent(item) {
            const { name, type, severity, numberOfTriggers } = item;
            let _content = [];

            _content.push({
                tag: this.$t("headers.alert"),
                text: "Alert",
                value: name,
                cols: {
                    xs: 12,
                    sm: 6,
                    md: 3,
                    lg: 3,
                    xl: 3
                }
            });

            _content.push({
                tag: this.$t("headers.type"),
                text: "Type",
                value: type,
                cols: {
                    xs: 12,
                    sm: 6,
                    md: 3,
                    lg: 3,
                    xl: 3
                }
            });

            _content.push({
                tag: this.$t("headers.alert_severity"),
                text: "Severity",
                value: severity,
                cols: {
                    xs: 12,
                    sm: 6,
                    md: 3,
                    lg: 3,
                    xl: 3
                }
            });

            _content.push({
                tag: this.$t("headers.number_of_triggers"),
                text: "Number of Triggers",
                value: numberOfTriggers,
                cols: {
                    xs: 12,
                    sm: 6,
                    md: 3,
                    lg: 3,
                    xl: 3
                }
            });

            return _content;
        },
        save(item) {
            this.loading = true;

            let payload = {
                alertId: item.id,
                note: item ? item.note : null
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateTripAlerts($input: UpdateTripAlertsInput!) {
                            updateTripAlerts(input: $input) {
                                trip {
                                    id
                                    status
                                    tripAlertSet {
                                        edges {
                                            node {
                                                id
                                                deviceTime
                                                alertTime
                                                resolvedTime
                                                isResolved
                                                alertJson
                                                note
                                                resolvedBy {
                                                    id
                                                    fullName
                                                }
                                                tenantAlertRule {
                                                    id
                                                    name
                                                    isStandardAlert
                                                }
                                                trackerMergedSensorData {
                                                    id
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    const payload = {
                        color: "primary",
                        message: this.$t("general.saved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);

                    this.$apollo.queries.tripAlerts.refetch();
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.editAlertsDialog = false;
                });
        },
        saveAll() {
            this.loading = true;

            let payload = {
                tripId: this.trip?.id
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateUnresolvedTripAlerts($input: UpdateUnresolvedTripAlertsInput!) {
                            updateUnresolvedTripAlerts(input: $input) {
                                trip {
                                    id
                                    status
                                    tripAlertSet {
                                        edges {
                                            node {
                                                id
                                                deviceTime
                                                alertTime
                                                resolvedTime
                                                isResolved
                                                alertJson
                                                note
                                                resolvedBy {
                                                    id
                                                    fullName
                                                }
                                                tenantAlertRule {
                                                    id
                                                    name
                                                    isStandardAlert
                                                }
                                                trackerMergedSensorData {
                                                    id
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    const payload = {
                        color: "primary",
                        message: this.$t("general.saved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.unresolvedDialog = false;
                });
        },
        validateList(list) {
            let find = list.filter(item => item.node.isResolved === true);
            if (find.length > 0) return true;
            if (find.length === 0) return false;
        }
    }
};
</script>
<style scoped>
/* hide the scrollbar of the v-dialog */
.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
    color: #e6e9f5;
}
.outlined {
    outline: 1px solid #e6e9f5; /* Blue outline, 2px thick */
    outline-offset: 0px; /* Distance between the outline and the element */
}
.contentBg {
    background-color: #f9fafe;
}

.textTruncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.cardContainer {
    height: 225px;
    overflow-y: auto !important;
    margin-bottom: 12px;
}

.cardContainer::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #cfd0d5ed;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color);
}
.iconColor {
    color: #0e0d35;
}
.textTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textTitle2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
.textHeader {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #555464;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
    height: 15px;
}
.textDetails {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.dialogCardContainer {
    max-width: 100%;
    max-height: 500px;
    overflow-y: auto;
}
.cursorLink {
    cursor: pointer;
}
</style>
